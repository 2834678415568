import React, {useCallback, useEffect} from 'react';
import socketIOClient, { Socket } from "socket.io-client";

interface SocketContextProps {
    socket?: Socket;
    authenticate: (token: string) => Promise<boolean>;
    onConnected: (cb: () => void) => () => void;
    isConnected: boolean
}

export const globalSocket = socketIOClient(process.env.REACT_APP_SERVER_URL as string);

export const SocketContext = React.createContext({} as SocketContextProps);

export const SocketProvider = ({ children }: any) => {
    const [socket, setSocket] = React.useState<Socket | undefined>(globalSocket);
    const [isConnected, setIsConnected] = React.useState<boolean>(false);

    const onConnected = useCallback((cb: () => void) => {
        socket?.on("connect",() => {cb(); setIsConnected(true)});
        socket?.on("disconnect", () => {
            setIsConnected(false);
        })
        return () => {
            socket?.off("connect", cb);
            socket?.off("disconnect")
    };
    }, [socket]);

    const authenticate = useCallback((token: string): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (socket) {
                socket.emit("authenticate", token, (success: any, error: any) => {
                    if (success) {
                        resolve(true);
                    } else {
                        reject(false);
                    }
                });
            } else {
                reject(false);
            }
        });
    }, [socket]);

    return <SocketContext.Provider value={{ socket: socket, authenticate, onConnected, isConnected }}>{children}</SocketContext.Provider>;
};
