import React from "react";
import "./BrushPicker.scss";

export interface BrushPickerProps {
    onChange: (size: number) => void;
    value: number;
}

export const BrushPicker = ({ onChange, value }: BrushPickerProps) => {
    return (
        <>
            <div
                className="Canvas__ButtonContainer"
                onClick={() => onChange(2)}
                style={{boxShadow: value === 2 ? "4px 4px 0px rgba(0, 0, 0, 0.25)" : "" }}
            >
                <div className="Brush__SmallCircle" />
            </div>
            <div
                className="Canvas__ButtonContainer"
                onClick={() => onChange(9)}
                style={{boxShadow: value === 9 ? "4px 4px 0px rgba(0, 0, 0, 0.25)" : "" }}
            >
                <div className="Brush__MediumCircle"/>
            </div>

            <div
                className="Canvas__ButtonContainer"
                onClick={() => onChange(16)}
                style={{boxShadow: value === 16 ? "4px 4px 0px rgba(0, 0, 0, 0.25)" : "" }}
            >
                <div className="Brush__BiggestCircle"/>
            </div>
            
        </>
                );
};
