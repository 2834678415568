import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
  AnyAction,
} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk, {ThunkDispatch} from 'redux-thunk';
import {LanguageReducer} from './languageReducer';
import {ModeReducer} from './modeReducer';
import {userReducer} from './userReducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const persistConfiguration = {
  key: 'maravilla',
  storage,
};

// para que se le pueda mandar un middleware al store
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  user: userReducer,
  language: LanguageReducer,
  mode: ModeReducer,
});

const persistedReducer = persistReducer(persistConfiguration, reducers);

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

export const Persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = ThunkDispatch<RootState, void, AnyAction>;
