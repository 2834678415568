import React from 'react';
import {Button, Typography, Modal} from '@mui/material';

import './ErrorModal.scss';

interface Props {
  visible: boolean;
  onClose: () => void;
  title: string;
  message: string;
}

export const ErrorModal = ({visible, onClose, title, message}: Props) => {
  return (
    <Modal
      open={visible}
      onClose={onClose}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}>
      <div className="ErrorModalContainer">
        <Typography
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: 'red',
            fontSize: '1.3rem',
          }}>
          {title}
        </Typography>
        <Typography style={{textAlign: 'center'}}>{message}</Typography>
        <Button
          variant="contained"
          className="Button"
          color="error"
          onClick={onClose}>
          Ok
        </Button>
      </div>
    </Modal>
  );
};
