import {LANGUAGES_MAP} from '../interfaces/Language';

type LanguageAction = {
  type: 'changeLanguage';
  payload: {value: string};
};

export interface LanguageState {
  Language: string;
}

const getBrowserLanguage = () => {
  const browserLanguage = navigator.language.slice(0, 2).toUpperCase();
  if (!Object.keys(LANGUAGES_MAP).includes(browserLanguage)) return 'EN';
  return browserLanguage;
};

const LanguageInitialState = (): LanguageState => {
  return {Language: getBrowserLanguage()};
};

export const LanguageReducer = (
  state: LanguageState | undefined = LanguageInitialState(),
  action: LanguageAction,
): LanguageState => {
  switch (action.type) {
    case 'changeLanguage':
      return {
        Language: action.payload.value,
      };

    default:
      return state;
  }
};
