import {
  Box,
  Button,
  MenuItem,
  Modal,
  NativeSelect,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, {useContext, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {AuthContext} from '../../context/AuthContext';
import {SocketContext} from '../../context/SocketContext';
import {Language} from '../../interfaces/Language';
import {WordList} from '../../interfaces/WordList';
import {getLanguages} from '../../services/LanguageService';
import {getAllWordListForLanguage} from '../../services/WordList';
import {LanguageNames, languageTexts} from '../../utils/languageTexts';
import './CreateBrokenPhoneRoom.scss';
import penMascot from '../../assets/drawbotic_pen.png';
import {
  gameLengths,
  maxNumbersOfPlayersOptions,
  secondsForDrawingOpcions,
  secondsForGuessingOpcions,
  secondsForInicialPhraseOpcions,
} from './RoomOptions';

interface CreateRoomModalProps {
  visible: boolean;
  onClose: () => void;
}

export const CreateBrokenPhoneRoomModal = ({
  visible,
  onClose,
}: CreateRoomModalProps) => {
  const contextLanguage = useSelector((state: any) => state.language);
  const texts =
    languageTexts[contextLanguage.Language as LanguageNames].createRoomTexts;

  const [name, setName] = React.useState(texts.newRoom);
  const [language, setLanguage] = React.useState<string>('ES');
  const [secondsInitialPhrase, setSecondsInitialPhrase] = React.useState(
    secondsForInicialPhraseOpcions[1],
  );
  const [secondsDrawing, setSecondsDrawing] = React.useState(
    secondsForDrawingOpcions[1],
  );
  const [secondsGuessing, setSecondsGuessing] = React.useState(
    secondsForGuessingOpcions[1],
  );
  const [maxPlayers, setMaxPlayers] = React.useState(
    maxNumbersOfPlayersOptions[1],
  );

  const [gameLength, setGameLenght] = React.useState(gameLengths[1]);

  const [languages, setLanguages] = React.useState<Language[]>([]);
  const context = useContext(SocketContext);

  const authState = useSelector((state: any) => state.user);

  const getUser = () => {
    const user = authState.user;
    if (user) {
      const userStr = JSON.stringify(user);
      return JSON.parse(userStr);
    }
    return undefined;
  };

  useEffect(() => {
    setName(
      getUser() == undefined
        ? texts.newRoom
        : contextLanguage.Language == 'ES'
        ? texts.room + getUser()!.name
        : getUser()!.name + texts.room,
    );
    setLanguage(contextLanguage.Language);
  }, [visible]);

  const createRoom = () => {
    if (context.socket) {
      context.socket.emit('create-room', {
        roomType: 'BrokenPhone',
        secondsInitialPhrase: Number(secondsInitialPhrase.value),
        secondsGuessing: Number(secondsGuessing.value),
        secondsDrawing: Number(secondsDrawing.value),
        maxPlayers: Number(maxPlayers.value),
        gameLength: gameLength.value,
        name: name,
        language: language,
      });
    }
  };

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 5,
    p: 0,
    overflow: 'visible',
    borderColor: 'transparent',
    outline: 'none',
  };

  useEffect(() => {
    getLanguages().then(languages => {
      setLanguages(languages);
    });
  }, []);

  return (
    <Modal open={visible} onClose={onClose}>
      <Box sx={modalStyle}>
        <div className="Intermission__CreateRoomContainer">
          <div className="CreateRoom__Title">{texts.title}</div>
          <div className="CreateRoom__FormRow">
            <TextField
              variant="outlined"
              fullWidth
              label={texts.roomName}
              margin="dense"
              onChange={event => setName(event.target.value)}
              value={name}
              InputProps={{
                style: {borderRadius: '20px', height: '50px'},
              }}
              style={{marginBottom: '25px'}}
            />
            <img
              draggable={false}
              src={penMascot}
              className="CreateRoom__Logo"
            />
          </div>
          <div className="CreateRoom__FormRow">
            <TextField
              value={secondsInitialPhrase.label}
              variant="outlined"
              label={texts.secondsForInicialPhrase}
              fullWidth
              margin="dense"
              select
              onChange={event =>
                setSecondsInitialPhrase(
                  secondsForInicialPhraseOpcions.find(
                    item => item.value == event.target.value,
                  ) || secondsForInicialPhraseOpcions[0],
                )
              }
              InputProps={{
                style: {
                  borderRadius: '20px',
                  height: '50px',
                  textAlign: 'left',
                },
              }}>
              {secondsForInicialPhraseOpcions.map(each => (
                <MenuItem key={each.value} value={each.value}>
                  {each.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              value={secondsDrawing.label}
              variant="outlined"
              label={texts.secondsForDrawing}
              fullWidth
              margin="dense"
              select
              onChange={event =>
                setSecondsDrawing(
                  secondsForDrawingOpcions.find(
                    item => item.value == event.target.value,
                  ) || secondsForDrawingOpcions[0],
                )
              }
              InputProps={{
                style: {
                  borderRadius: '20px',
                  height: '50px',
                  textAlign: 'left',
                },
              }}>
              {secondsForDrawingOpcions.map(each => (
                <MenuItem key={each.value} value={each.value}>
                  {each.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="CreateRoom__FormRow">
            <TextField
              value={secondsGuessing.label}
              variant="outlined"
              label={texts.secondsForGuessing}
              fullWidth
              margin="dense"
              select
              onChange={event =>
                setSecondsGuessing(
                  secondsForGuessingOpcions.find(
                    item => item.value == event.target.value,
                  ) || secondsForGuessingOpcions[0],
                )
              }
              InputProps={{
                style: {
                  borderRadius: '20px',
                  height: '50px',
                  textAlign: 'left',
                },
              }}>
              {secondsForGuessingOpcions.map(each => (
                <MenuItem key={each.value} value={each.value}>
                  {each.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              value={maxPlayers.label}
              variant="outlined"
              label={texts.maxPlayers}
              fullWidth
              margin="dense"
              select
              onChange={event =>
                setMaxPlayers(
                  maxNumbersOfPlayersOptions.find(
                    item => item.value == event.target.value,
                  ) || maxNumbersOfPlayersOptions[0],
                )
              }
              InputProps={{
                style: {
                  borderRadius: '20px',
                  height: '50px',
                  textAlign: 'left',
                },
              }}>
              {maxNumbersOfPlayersOptions.map(each => (
                <MenuItem key={each.value} value={each.value}>
                  {each.label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <TextField
            value={gameLength.label}
            variant="outlined"
            label={texts.gameLength}
            fullWidth
            margin="dense"
            select
            onChange={event =>
              setGameLenght(
                gameLengths.find(item => item.value == event.target.value) ||
                  gameLengths[0],
              )
            }
            InputProps={{
              style: {
                borderRadius: '20px',
                height: '50px',
                textAlign: 'left',
              },
            }}>
            {gameLengths.map(each => (
              <MenuItem key={each.value} value={each.value}>
                {each.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            value={language}
            variant="outlined"
            label={texts.language}
            fullWidth
            select
            margin="dense"
            onChange={event => {
              setLanguage(event.target.value);
            }}
            InputProps={{
              style: {
                borderRadius: '20px',
                height: '50px',
                textAlign: 'left',
              },
            }}>
            {languages.map(each => (
              <MenuItem key={each.code} value={each.code}>
                {each.name}
              </MenuItem>
            ))}
          </TextField>

          <div className="CreateRoom__ButtonContainerModal">
            <div
              className="CreateRoom__Cancel"
              onClick={() => {
                onClose();
              }}>
              {texts.cancelButtonText}
            </div>
            <div className="CreateRoom__Buttons" onClick={createRoom}>
              {texts.createButtonText}
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
