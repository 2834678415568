import React from 'react';

import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import {Typography} from '@mui/material';

interface Props {
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

export const SeeProfileButton = ({text, onClick, disabled}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        padding: 10,
        paddingBottom: 0,
        cursor: disabled ? 'default' : 'pointer',
      }}
      onClick={disabled ? () => {} : onClick}>
      <div style={{marginRight: 10}}>
        <AccountCircleRoundedIcon style={{fontSize: 25, color: disabled ? '#858387' : '#0755CB'}}  />
      </div>
      <Typography>{text}</Typography>
    </div>
  );
};
