import { useState, useEffect, useMemo } from "react";

export const useAudio = (url: string, volume: number = 1) => {
    const audio = useMemo(() => new Audio(url), []);
    const [playing, setPlaying] = useState<boolean>(false);

    const toggle = () => setPlaying(!playing);
    const play = () => setPlaying(true);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    }, [playing]);

    useEffect(() => {
        audio.volume = volume;
    }, [audio]);

    useEffect(() => {
        audio.addEventListener("ended", () => {
            setPlaying(false);
        });
        return () => {
            audio.removeEventListener("ended", () => {
                setPlaying(false);
            });
        };
    }, []);

    return { playing, toggle, play };
};
