import {Box, Modal, TextField, MenuItem} from '@mui/material';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {AuthContext} from '../../context/AuthContext';
import './ReportModal.scss';
import {LanguageNames, languageTexts} from '../../utils/languageTexts';
import {SocketContext} from '../../context/SocketContext';
import {Player} from '../../interfaces/Player';
import {SettingsPowerRounded} from '@mui/icons-material';
import {useSelector} from 'react-redux';

interface ReportModalProps {
  player: Player;
  open: boolean;
  setOpen: (p: boolean) => void;
  setReported: (p: boolean) => void;
}

export const ReportModal = ({
  player,
  open,
  setOpen,
  setReported,
}: ReportModalProps) => {
  const contextLanguage = useSelector((state: any) => state.language);
  const texts =
    languageTexts[contextLanguage.Language as LanguageNames].reportModalTexts;
  const context = useContext(SocketContext);
  const [reportReason, setReportReason] = React.useState<string>(
    texts.reportReasons[0],
  );
  const [otherReportReason, setOtherReportReason] = React.useState<string>('');

  const modalStyle = {
    position: 'absolute' as 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 5,
    p: 0,
    overflow: 'hidden',
    borderColor: 'border.paper',
    padding: '1rem',
    zIndex: 10,
  };

  const onReport = () => {
    if (context.socket) {
      context.socket.emit('report', {
        playerName: player.Name,
        reason: texts.reportReasons.at(-1),
        text: otherReportReason,
      });
      setReported(true);
      setOpen(false);
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={modalStyle}>
        <div className="Report__ModalContainer">
          <div className="CreateRoom__Title">{texts.reportPlayer}</div>

          <TextField
            value={reportReason}
            variant="outlined"
            label={texts.selectReason}
            fullWidth
            margin="dense"
            select
            onChange={event => {
              setReportReason(event.target.value);
            }}
            InputProps={{
              style: {
                borderRadius: '20px',
                height: '50px',
                textAlign: 'left',
              },
            }}>
            {texts.reportReasons.map(report => {
              return (
                <MenuItem key={report} value={report}>
                  {report}
                </MenuItem>
              );
            })}
          </TextField>

          {reportReason === texts.reportReasons.at(-1) && (
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              autoComplete="off"
              label={texts.otherReason}
              margin="dense"
              onChange={event => {
                setOtherReportReason(event.target.value);
              }}
              value={otherReportReason}
              InputProps={{
                style: {borderRadius: '20px'},
              }}
              style={{marginBottom: '10px'}}
              inputProps={{maxLength: 200}}
            />
          )}
          <div className="Report__ModalButtonsContainer">
            <div
              className="Report__CancelButton"
              onClick={() => setOpen(false)}>
              {texts.cancel}
            </div>
            <div className="Report__ReportButton" onClick={onReport}>
              {texts.report}
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
