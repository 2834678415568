import {Box, Modal, TextField} from '@mui/material';
import React, {useContext} from 'react';
import {AuthContext} from '../../context/AuthContext';
import {User} from '../../interfaces/User';
import {LanguageNames, languageTexts} from '../../utils/languageTexts';
import './ProfileModal.scss';
import {LANGUAGES_MAP} from '../../interfaces/Language';
import './ProfileModal.scss';
import {Player} from '../../interfaces/Player';
import {useSelector} from 'react-redux';
import {getAvatarUri} from "../../services/Avatars";

interface ProfileModalProps {
  visible: boolean;
  onClose: () => void;
  friend: Player;
}

export const FriendProfileModal = ({
  visible,
  onClose,
  friend,
}: ProfileModalProps) => {
  const baseURL = process.env.REACT_APP_SERVER_URL + 'v1/api/resource/';

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 470,
    height: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 5,
    p: 0,
    overflow: 'hidden',
  };

  const contextLanguage = useSelector((state: any) => state.language);
  const texts =
    languageTexts[contextLanguage.Language as LanguageNames].profileTexts;

  return (
    <Modal open={visible} onClose={onClose}>
      <Box sx={modalStyle}>
        <div className="ProfileModal__Container">
          <div className="ProfileModal__Title"> {texts.titleFriend} </div>
          <div className="ProfileModal__Content">
            <div className={'ProfileModal__AvatarContainer'}>
              <img
                draggable={false}
                src={
                  friend.Avatar
                    ? baseURL + friend.Avatar
                    : getAvatarUri(friend.Name)
                }
                alt="Avatar"
                className={'Players__Avatar'}
              />
            </div>
            <div className="ProfileModal__Item">
              <h3>{texts.gamesWon}</h3>
              <p>{friend.gamesWon ? friend.gamesWon : '-'}</p>
            </div>
            <div className="ProfileModal__Item">
              <h3>{texts.playedGames}</h3>
              <p>{friend.gamesPlayed ? friend.gamesPlayed : '-'}</p>
            </div>
            <div className="ProfileModal__Item">
              <h3>{texts.guessedWords}</h3>
              <p>{friend.wordsGuessed ? friend.wordsGuessed : '-'}</p>
            </div>
            <div className="ProfileModal__Item">
              <h3>{texts.brokenPhoneGamesPlayed}</h3>
              <p>{friend.brokenPhoneGamesPlayed ? friend.brokenPhoneGamesPlayed : '-'}</p>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
