export type ModeAction = {
  type: 'changeMode';
  payload: {value: string};
};

export interface ModeState {
  mode: string;
}

export const modeInitialState: ModeState = {
  mode: 'Draw',
};

export const ModeReducer = (
  state: ModeState = modeInitialState,
  action: ModeAction,
): ModeState => {
  switch (action.type) {
    case 'changeMode':
      return {
        mode: action.payload.value,
      };

    default:
      return state;
  }
};
