import {Button} from '@mui/material';
import {useContext, useEffect, useState} from 'react';
import {SocketContext} from '../../context/SocketContext';
import {Room} from '../../interfaces/Room';
import './Rooms.scss';
import ar from '../../assets/ar.png';
import en from '../../assets/en.png';
import {AuthContext} from '../../context/AuthContext';
import {User} from '../../interfaces/User';
import {LanguageNames, languageTexts} from '../../utils/languageTexts';
import {VapingRooms} from '@mui/icons-material';
import {useSelector} from 'react-redux';

interface RoomsProps {
  user: User | undefined;
  rooms: Room[];
  onClick: (roomId: String) => void;
  onCreateRoom: () => void;
  changeLanguage: string;
}

const sortRooms = (rooms: Room[]) => {
  return rooms.sort((a, b) =>
    a.MaxPlayers === a.Players
      ? 1
      : a.MaxPlayers / a.Players - b.MaxPlayers / b.Players,
  );
};

export function Rooms({
  user,
  rooms,
  onClick,
  onCreateRoom,
  changeLanguage,
}: RoomsProps) {
  const [sortedRooms, setSortedRooms] = useState<Room[]>([]);
  const currentUser = useSelector((state: any) => state.user);

  const contextLanguage = useSelector((state: any) => state.language);
  const texts =
    languageTexts[contextLanguage.Language as LanguageNames].homeTexts;

  useEffect(() => {
    const toSort = [...rooms];
    setSortedRooms(toSort);
  }, [rooms]);

  useEffect(() => {
    setSortedRooms(rooms);
  }, [changeLanguage]);

  return (
    <div className="Room__Rooms">
      <div className="Room_RoomHeader">
        <p>{texts.roomsTitle}</p>
      </div>
      <div className="Room__RoomsItems">
        {sortedRooms.map((each, i) => (
          <RoomItem Room={each} onClick={onClick} key={i} />
        ))}
      </div>
      <div className="Room__RoomFooter">
        {currentUser.user !== undefined && (
          <div
            className="Canvas__ButtonContainerWarning"
            onClick={() => onCreateRoom()}
            style={{width: '40%'}}>
            {texts.createTitle}
          </div>
        )}
      </div>
    </div>
  );
}

interface RoomItem {
  Room: Room;
  onClick: (roomId: String) => void;
}

function RoomItem({Room, onClick}: RoomItem) {
  const getFlag = (language: string) => {
    if (language === 'ES') {
      return ar;
    } else {
      return en;
    }
  };

  return (
    <div className="Room__RoomItemContainer">
      <div
        className="Room__RoomItem"
        onClick={() => {
          onClick(Room.Id);
        }}
        style={{
          cursor: Room.Players === Room.MaxPlayers ? 'default' : 'pointer',
        }}>
        <div
          className="Room__Overlay"
          style={{
            display: (!Room.canJoin) ? 'block' : 'none',
          }}></div>
        <div className="Room__RoomInfoContainer">
          <p className="Room__Text">{Room.Name.trim()}</p>
          <div className="Room__FlagContainer">
            <img
              draggable={false}
              src={getFlag(Room.Language)}
              alt="Language"
              className="Room__Flag"
            />
            <p className="Room__WordListText">
              {Room.GameType === 'Draw' ? Room.WordList : 'Full Length'}
            </p>
            {!Room.canJoin &&
              <p className="Room__WordListText">
              {Room.GameType === 'Draw' ? "" : ' | Game in progress'}
            </p>
            }
          </div>
        </div>
        <p className="Room__QuantityText">
          <strong>{Room.Players}</strong> / {Room.MaxPlayers}
        </p>
      </div>
    </div>
  );
}
