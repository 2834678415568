import {
  Box,
  Button,
  MenuItem,
  Modal,
  NativeSelect,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, {useContext, useEffect} from 'react';
import {AuthContext} from '../../context/AuthContext';
import {SocketContext} from '../../context/SocketContext';
import {Language} from '../../interfaces/Language';
import {WordList} from '../../interfaces/WordList';
import {getLanguages} from '../../services/LanguageService';
import {getAllWordListForLanguage} from '../../services/WordList';
import penMascot from '../../assets/drawbotic_pen.png';
import './CreateRoomModal.scss';
import {
  numberOfRoundsOptions,
  secondsBetweenRoundsOptions,
  secondsPerRoundOptions,
  secondsAfterFirstGuessOptions,
  maxNumbersOfPlayersOptions,
  maxNumbersOfPlayersOptionsAdmin,
} from './RoomOptions';
import {LanguageNames, languageTexts} from '../../utils/languageTexts';
import {useSelector} from 'react-redux';

interface CreateRoomModalProps {
  visible: boolean;
  onClose: () => void;
}

export const CreateRoomModal = ({visible, onClose}: CreateRoomModalProps) => {
  const contextLanguage = useSelector((state: any) => state.language);
  const texts =
    languageTexts[contextLanguage.Language as LanguageNames].createRoomTexts;

  const authState = useSelector((state: any) => state.user);

  const getUser = () => {
    const user = authState.user;
    if (user) {
      const userStr = JSON.stringify(user);
      return JSON.parse(userStr);
    }
    return undefined;
  };

  const [name, setName] = React.useState(
    getUser() == undefined
      ? texts.newRoom
      : contextLanguage.Language == 'ES'
      ? texts.room + getUser()!.name
      : getUser()!.name + texts.room,
  );

  const [rounds, setRounds] = React.useState(numberOfRoundsOptions[1]);
  const [secondsPerRounds, setSecondsPerRounds] = React.useState(
    secondsPerRoundOptions[1],
  );
  const [intermissionTime, setIntermissionTime] = React.useState(
    secondsBetweenRoundsOptions[0],
  );
  const [timeAfterFirstGuess, setTimeAfterFirstGuess] = React.useState(
    secondsAfterFirstGuessOptions[2],
  );
  const [maxPlayers, setMaxPlayers] = React.useState(
    maxNumbersOfPlayersOptions[1],
  );
  const [language, setLanguage] = React.useState(contextLanguage.Language);
  const [languages, setLanguages] = React.useState<Language[]>([]);
  const [wordLists, setWordLists] = React.useState<WordList[]>([]);
  const [wordList, setWordList] = React.useState('');
  const context = useContext(SocketContext);

  const [roomError, setRoomError] = React.useState('');

  const user = useSelector((state: any) => state.user);

  // useEffect(() => {
  //   if (user && user.user && user.user.role) {
  //     console.log('user', user.user.role);
  //   }
  // }, [user, visible]);

  useEffect(() => {
    setName(
      getUser() == undefined
        ? texts.newRoom
        : contextLanguage.Language == 'ES'
        ? texts.room + getUser()!.name
        : getUser()!.name + texts.room,
    );
    setLanguage(contextLanguage.Language);
  }, [visible]);

  const createRoom = () => {
    if (context.socket) {
      context.socket.emit('create-room', {
        Name: name,
        Rounds: Number(rounds.value),
        SecondsPerRound: Number(secondsPerRounds.value),
        IntermissionTime: Number(intermissionTime.value),
        MaxPlayers: Number(maxPlayers.value),
        TimeAfterGuess: Number(timeAfterFirstGuess.value),
        WordListID: wordList,
      });
    }
  };

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 5,
    p: 0,
    overflow: 'visible',
    borderColor: 'transparent',
    outline: 'none',
  };

  useEffect(() => {
    getLanguages().then(languages => {
      setLanguages(languages);

      const language = languages.find(l => l.code === 'ES');
      if (language) {
        getAllWordListForLanguage(language.id).then(wordLists => {
          setWordLists(wordLists);
          if (wordLists) {
            const firstWordList = wordLists.at(0);
            firstWordList && setWordList(firstWordList.id);
          }
        });
      }
    });
  }, []);

  const onLanguageChange = (abbv: string) => {
    const language = languages.find(l => l.code === abbv);
    if (language) {
      getAllWordListForLanguage(language.id).then(wordLists => {
        setWordLists(wordLists);
        if (wordLists) {
          const firstWordList = wordLists.at(0);
          firstWordList && setWordList(firstWordList.id);
        }
      });
    }
  };

  useEffect(() => {
    if (context.socket) {
      context.socket.on('room-creation-failed', (message: string) => {
        if (message == 'ROOM NAME NOT UNIQUE') {
          setRoomError(texts.roomNameError);
        }
      });
    }

    return () => {
      context.socket?.off('room-creation-failed');
    };
  }, [context]);

  useEffect(() => {
    setRoomError('');
  }, [visible]);

  return (
    <Modal open={visible} onClose={onClose}>
      <Box sx={modalStyle}>
        <div className="Intermission__CreateRoomContainer">
          <div className="CreateRoom__Title">{texts.title}</div>
          <div className="CreateRoom__FormRow">
            <TextField
              variant="outlined"
              fullWidth
              label={texts.roomName}
              margin="dense"
              onChange={event => setName(event.target.value)}
              value={name}
              style={{width: '202px'}}
              InputProps={{
                style: {borderRadius: '20px', height: '50px'},
              }}
              error={roomError !== ''}
              helperText={roomError}
            />
            <img
              draggable={false}
              src={penMascot}
              className="CreateRoom__Logo"
            />
          </div>
          <div className="CreateRoom__FormRow">
            <TextField
              value={rounds.label}
              variant="outlined"
              label={texts.numberOfRounds}
              fullWidth
              margin="dense"
              select
              onChange={event =>
                setRounds(
                  numberOfRoundsOptions.find(
                    item => item.value == event.target.value,
                  ) || numberOfRoundsOptions[0],
                )
              }
              InputProps={{
                style: {
                  borderRadius: '20px',
                  height: '50px',
                  textAlign: 'left',
                },
              }}>
              {numberOfRoundsOptions.map(each => (
                <MenuItem key={each.value} value={each.value}>
                  {each.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              value={secondsPerRounds.label}
              variant="outlined"
              label={texts.secondsPerRound}
              fullWidth
              margin="dense"
              select
              onChange={event =>
                setSecondsPerRounds(
                  secondsPerRoundOptions.find(
                    item => item.value == event.target.value,
                  ) || secondsPerRoundOptions[0],
                )
              }
              InputProps={{
                style: {
                  borderRadius: '20px',
                  height: '50px',
                  textAlign: 'left',
                },
              }}>
              {secondsPerRoundOptions.map(each => (
                <MenuItem key={each.value} value={each.value}>
                  {each.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="CreateRoom__FormRow">
            <TextField
              value={intermissionTime.label}
              variant="outlined"
              label={texts.secondsBetweenRounds}
              fullWidth
              margin="dense"
              select
              onChange={event =>
                setIntermissionTime(
                  secondsBetweenRoundsOptions.find(
                    item => item.value == event.target.value,
                  ) || secondsBetweenRoundsOptions[0],
                )
              }
              InputProps={{
                style: {
                  borderRadius: '20px',
                  height: '50px',
                  textAlign: 'left',
                },
              }}>
              {secondsBetweenRoundsOptions.map(each => (
                <MenuItem key={each.value} value={each.value}>
                  {each.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              value={maxPlayers.label}
              variant="outlined"
              label={texts.maxPlayers}
              fullWidth
              margin="dense"
              select
              onChange={event =>
                setMaxPlayers(
                  maxNumbersOfPlayersOptionsAdmin.find(
                    item => item.value == event.target.value,
                  ) || maxNumbersOfPlayersOptions[0],
                )
              }
              InputProps={{
                style: {
                  borderRadius: '20px',
                  height: '50px',
                  textAlign: 'left',
                },
              }}>
              {user && user.user && user.user.role === 'CLIENT'
                ? maxNumbersOfPlayersOptions.map(each => (
                    <MenuItem key={each.value} value={each.value}>
                      {each.label}
                    </MenuItem>
                  ))
                : maxNumbersOfPlayersOptionsAdmin.map(each => (
                    <MenuItem key={each.value} value={each.value}>
                      {each.label}
                    </MenuItem>
                  ))}
            </TextField>
          </div>

          <TextField
            value={timeAfterFirstGuess.label}
            variant="outlined"
            label={texts.secondsAfterFirstGuess}
            fullWidth
            margin="dense"
            select
            onChange={event =>
              setTimeAfterFirstGuess(
                secondsAfterFirstGuessOptions.find(
                  item => item.value == event.target.value,
                ) || secondsAfterFirstGuessOptions[0],
              )
            }
            InputProps={{
              style: {
                borderRadius: '20px',
                height: '50px',
                textAlign: 'left',
              },
            }}>
            {secondsAfterFirstGuessOptions.map(each => (
              <MenuItem key={each.value} value={each.value}>
                {each.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            value={language}
            variant="outlined"
            label={texts.language}
            fullWidth
            select
            margin="dense"
            onChange={event => {
              setLanguage(event.target.value);
              onLanguageChange(event.target.value);
            }}
            InputProps={{
              style: {
                borderRadius: '20px',
                height: '50px',
                textAlign: 'left',
              },
            }}>
            {languages.map(each => (
              <MenuItem key={each.code} value={each.code}>
                {each.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            value={wordList}
            variant="outlined"
            label={texts.wordList}
            fullWidth
            margin="dense"
            select
            onChange={event => {
              setWordList(event.target.value);
            }}
            InputProps={{
              style: {
                borderRadius: '20px',
                height: '50px',
                textAlign: 'left',
              },
            }}>
            {wordLists.map(each => (
              <MenuItem key={each.id} value={each.id}>
                {each.name}
              </MenuItem>
            ))}
          </TextField>
          <div className="CreateRoom__ButtonContainerModal">
            <div
              className="CreateRoom__Cancel"
              onClick={() => {
                onClose();
              }}>
              {texts.cancelButtonText}
            </div>
            <div className="CreateRoom__Buttons" onClick={createRoom}>
              {texts.createButtonText}
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
