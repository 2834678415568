import drawApi from "../api/drawApi";
import { Buffer } from "buffer";

export const login = async (email: string, password: string) => {
    const resp = await drawApi.post("v1/api/user/login", undefined, {
        headers: {
            Authorization: `Basic ${Buffer.from(`${email}:${password}`).toString("base64")}`,
        },
    });

    return resp;
};

export const signup = async (email: string, username: string, password: string, language:string, avatar?: File) => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("name", username);
    formData.append("password", password);
    formData.append("language", language);
    if (avatar) {
        formData.append("file", avatar);
    }
    const resp = await drawApi.post("v1/api/user/signup", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

    return resp;
};

export const logout = async (token: string) => {
    const resp = await drawApi.post("v1/api/user/logout", undefined, {
        headers: {
            token: token,
        },
    });

    return resp;
};
