import { TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { SocketContext } from '../../context/SocketContext';
import './EnterPhrase.scss';
import DoneIcon from '@mui/icons-material/Done';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import drawbotixPen from '../../assets/drawbotixPen2.png'
import { userState } from '../../store/userReducer';
import { useSelector } from 'react-redux';
import { LanguageNames, languageTexts } from '../../utils/languageTexts';
import drawApi from "../../api/drawApi";
import { Phrase } from '../../interfaces/Phrase';
import { DrawElement } from '../../hooks/useCreateGif';

interface EnterPhraseProps {
  timeRemaining: number;
  roundDuration: number;
  playersTotal: number;
  placeHolder: string;
  roundNumber: number;
}

export const EnterPhrase = ({
  timeRemaining,
  roundDuration,
  playersTotal,
  placeHolder,
  roundNumber,
}: EnterPhraseProps) => {
  const [text, setText] = React.useState<string>('');
  const [sent, setSent] = React.useState(false);
  const context = React.useContext(SocketContext);
  const [time, setTime] = useState<number>(roundDuration);
  const interval = useRef<any>(0);
  const [playersReady, setPlayersReady] = React.useState(0);
  const [touched, setTouched] = useState(false);
  const contextLanguage = useSelector((state: any) => state.language);
  const texts = languageTexts[contextLanguage.Language as LanguageNames].enterPhraseTexts;
  const [randomPhrase, setRandomPhrase] = useState<string>(placeHolder);
  const [fetchingPhrase, setFetchingPhrase] = useState<boolean>(false);

  const getRandomPhrase = async () => {
    try {
      setFetchingPhrase(true);
      const response = await drawApi.get(`v1/api/phrase/random`);
      if (response.data.status === "success") {
        const phrase = response.data.data.phrase as Phrase;
        setRandomPhrase(phrase.text);
      }
    } catch (error) {
      console.log(error);
    }
    setFetchingPhrase(false);
  };

  useEffect(() => {
    if (context.socket) {

      // if (placeHolder) {
      //   context.socket?.emit('add-phrase', { phrase: placeHolder, placeHolder: true });
      //   console.log("Added placeholder: ", placeHolder)
      // }

      context.socket.on('round-status', status => {
        console.log("Status: ", status);
        setPlayersReady(status.playersReady);
        // setMaxPlayers(status.playersTotal);
      });

      return () => {
        context.socket?.off('round-status');
      };
    }
  }, [context]);

  useEffect(() => {
    if (randomPhrase && text === '' && context) {
      context.socket?.emit('add-phrase', { phrase: randomPhrase, placeHolder: true });
    }
  }, [randomPhrase, text])

  const onSend = () => {
    if (!context.socket) return;
    if (!sent) {
      context.socket.emit('add-phrase', { phrase: text ? text : randomPhrase });
      context.socket.emit("player-ready");
      setSent(true);
    } else {
      context.socket.emit("player-not-ready");
      setSent(false);
    }
  };

  const inputValidation = () => {
    // if (!text.trim().length) {
    //   return "Empty field"
    // }
    if (text.length > 60) {
      return "Text can't be longer than 60 characters"
    }
    return ""
  }

  const handleTouch = () => {
    setTouched(true);
  };

  useEffect(() => {
    clearInterval(interval.current);
    setTime(roundDuration);
    interval.current = setInterval(() => {
      setTime(time => time - 1);
    }, 1000);
  }, [roundDuration]);

  return (
    <div className="EnterPhrase__Container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          width: '100%',
          height: '100%',
          maxWidth: '500px',
          justifyContent: 'center'
        }}>
        <img
          draggable={false}
          src={drawbotixPen}
          alt="Lapiz"
          className="EnterPhrase__Image"
        />
        <div className='EnterPhrase__RoundContainer'>
          <p>
            {texts.round}: {roundNumber}/{playersTotal}
          </p>
        </div>
        <h1 className="EnterPhrase__Title">{texts.writeYourPhrase}</h1>
        <TextField
          variant="outlined"
          error={touched && Boolean(inputValidation())}
          helperText={touched && inputValidation()}
          placeholder={randomPhrase}
          margin="dense"
          onChange={event => setText(event.target.value)}
          value={text.replace(/(\r\n|\n|\r)/gm, '')}
          onFocus={handleTouch}
          style={{ marginBottom: "10px", position: "relative" }}
          FormHelperTextProps={{ style: { position: "absolute", bottom: "-25px" } }}
          InputProps={{
            style: { borderRadius: '20px', backgroundColor: 'white', fontSize: '1.2em' },
          }}
          inputProps={{ maxLength: 60 }}
          disabled={sent}
        />
        <div className="EnterPhrase__ButtonContainer">
          <div className={Boolean(inputValidation()) ? "BrokenPhone__ButtonDisabled" : "BrokenPhone__Button"}
            onClick={Boolean(inputValidation()) ? undefined : onSend}>
            {sent ? (
              <>
                <EditIcon fontSize='small' style={{ marginRight: '4px' }} />
                {texts.edit}
              </>
            ) : (
              <>
                <DoneIcon fontSize='small' style={{ marginRight: '4px' }} />
                {texts.done}
              </>
            )}
          </div>
          <div className={(fetchingPhrase || text || sent) ? "BrokenPhone__ButtonDisabled" : "BrokenPhone__Button"}
            onClick={(fetchingPhrase || text || sent) ? undefined : getRandomPhrase}>
            <RefreshIcon fontSize='small' style={{ marginRight: '4px' }} />
            {texts.random}
          </div>
          {sent && <p>{texts.waitingForPlayers}</p>}
        </div>
        <div className="EnterPhrase__PlayerCount">
          <h3>
            {playersReady} / {playersTotal}
          </h3>
          <DoneIcon
            sx={{
              fontSize: '35px',
              color: 'rgb(4, 208, 1)',
              marginLeft: '4px'
            }}
          />
        </div>
      </div>
      <div className="EnterPhrase__Clock">
        <CircularProgressbar
          value={Math.max(0, time)}
          text={`${Math.max(time, 0)}`}
          maxValue={roundDuration}
          minValue={0}
          styles={buildStyles({
            pathColor: time < 10 ? 'red' : '#096bfd',
            textColor: time < 10 ? 'red' : 'black',
            textSize: '30px',
          })}
        />
      </div>
    </div>
  );
};
