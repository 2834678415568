/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import './Canvas.scss';
import mute from '../../assets/mute.png';
import unmute from '../../assets/unmute.png';
import sandClock from '../../assets/sandClock.png';
import { CirclePicker, ColorResult } from 'react-color';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { BrushPicker, BrushPickerProps } from '../BrushPicker/BrushPicker';
import { User } from '../../interfaces/User';
import { Box, Typography, Zoom } from '@mui/material';
import {
  CustomCanvas,
  CustomCanvasRef,
  MyCanvasPath,
} from './CustomCanvas/MyCanvas';
import SearchIcon from '@mui/icons-material/Search';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import trashBin from '../../assets/trash.png';
import eraser from '../../assets/eraser.png';
import { LANGUAGES_MAP } from '../../interfaces/Language';
import { LanguageNames, languageTexts } from '../../utils/languageTexts';
import { AuthContext } from '../../context/AuthContext';
import { useSelector } from 'react-redux';
import { ColorPicker } from '../ColorPicker/ColorPicker';
import Confetti from 'react-confetti';
import { PlayerDrawingModal } from '../PlayerDrawingModal/PlayerDrawingModal';
import { YourDrawingTurnModal } from '../PlayerDrawingModal/YourDrawingTurnModal';
import { Room } from '../../interfaces/Room';
import '../Rooms/Rooms.scss';
import ar from '../../assets/ar.png';
import en from '../../assets/en.png';

interface CanvasProps {
  onDrawChange: (drawData: string) => void;
  drawData: string;
  isDrawing: boolean;
  wordToGuess: string | undefined;
  roundNumber: number;
  timeRemaining: number;
  totalRounds: number;
  onGiveTurn: () => void;
  onGiveClue: () => void;
  maxTime: number;
  newTime: number;
  clueAmount: number;
  gameEnd: boolean;
  gameEndModalComponent: any;
  onClear: () => void;
  someoneGuessed: boolean;
  showExtraButtons?: boolean;
  children?: any;
  intermission: boolean;
  gameAudio: boolean;
  setGameAudio: any;
  onReport: () => void;
  players?: number;
  user?: User;
  playerGuessed: boolean;
  playerDrawingName?: string;
  room?: Room;
  brokenPhone?: boolean;
  playersReady?: number;
  drawingReady?: boolean;
  onSubmit?: () => void;
}

export interface MyCanvasRef {
  getPaths: () => MyCanvasPath[];
  setEraser: (eraser: boolean) => void;
  loadPaths: (paths: MyCanvasPath[]) => void;
  getCanvasSize: () => { height: number; width: number };
  getCanvasTime: () => number;
  resetRound: () => void;
  clearDrawing: () => void;
}

export const Canvas = React.forwardRef<MyCanvasRef, CanvasProps>(
  (props, ref) => {
    const {
      drawData,
      onDrawChange,
      isDrawing,
      wordToGuess,
      roundNumber,
      timeRemaining,
      totalRounds,
      onGiveTurn,
      onGiveClue,
      maxTime,
      newTime,
      clueAmount,
      gameEnd,
      gameEndModalComponent,
      onClear,
      someoneGuessed = false,
      showExtraButtons = true,
      children,
      intermission,
      gameAudio,
      setGameAudio,
      onReport,
      players = 1,
      user,
      playerGuessed,
      playerDrawingName = '',
      room,
      brokenPhone = false,
      playersReady,
      drawingReady,
      onSubmit
    } = props;
    const containerRef = useRef<HTMLDivElement | null>();
    const [canvasSize, setCanvasSize] = useState({ x: 400, y: 400 });
    const canvasRef = useRef<CustomCanvasRef | null>();
    // const [data, setData] = useState(drawData);
    const [erase, setErase] = useState(false);
    const [color, setColor] = useState('black');
    const [brushSize, setBrushSize] = useState(2);
    const [fadeToolbar, setfadeToolbar] = React.useState<boolean>(true);
    const [fadeButtonBar, setfadeButtonBar] = React.useState<boolean>(true);
    const [time, setTime] = useState<number>(maxTime);
    const interval = useRef<any>(0);
    const { updateUser } = useContext(AuthContext);
    const [drawingReported, setDrawingReported] =
      React.useState<boolean>(false);
    const [showYourTurnDrawingModal, setShowYourTurnDrawingModal] =
      useState(true);
    const brushColors = [
      '#B80000',
      '#DB3E00',
      '#FCCB00',
      '#008B02',
      '#1273DE',
      '#5300EB',
      '#000000',
    ];

    useEffect(() => {
      if (isDrawing) {
        setShowYourTurnDrawingModal(true);
        setTimeout(() => {
          setShowYourTurnDrawingModal(false);
        }, 2000);
      }
    }, [isDrawing]);

    useEffect(() => {
      if (roundNumber) {
        setDrawingReported(false);
      }
    }, [roundNumber]);

    useEffect(() => {
      clearInterval(interval.current);
      setTime(maxTime);
      interval.current = setInterval(() => {
        setTime(time => time - 1);
      }, 1000);
    }, [maxTime]);

    useEffect(() => {
      if (newTime) {
        if (newTime < time || time === 0) {
          clearInterval(interval.current);
          setTime(newTime);
          interval.current = setInterval(() => {
            setTime(time => time - 1);
          }, 1000);
        }
      }
    }, [newTime]);

    React.useImperativeHandle(ref, () => ({
      getPaths: () => {
        return canvasRef.current?.exportPaths() || [];
      },
      setEraser: (eraser: boolean) => {
        setErase(eraser);
      },
      loadPaths: (paths: MyCanvasPath[]) => {
        canvasRef.current?.loadPaths(paths);
      },
      getCanvasSize: () => {
        return canvasRef.current?.exportCanvasSize()!;
      },
      getCanvasTime: () => {
        return time;
      },
      resetRound() {
        canvasRef.current?.resetTurn();
      },
      clearDrawing() {
        canvasRef.current?.resetCanvas();
      },
    }));

    useEffect(() => {
      if (containerRef.current) {
        setCanvasSize({
          y: containerRef.current.clientHeight,
          x: containerRef.current.clientWidth,
        });
      }
    }, [containerRef.current]);

    useEffect(() => {
      if (roundNumber === 0) {
        canvasRef.current?.resetCanvas();
      }
    }, [roundNumber]);

    useEffect(() => {
      if (gameEnd) {
        canvasRef.current?.resetCanvas();
      }
    }, [gameEnd]);

    const clearData = () => {
      if (isDrawing) {
        onClear();
        canvasRef.current?.resetCanvas();
      }
    };

    const setErased = () => {
      if (isDrawing) {
        setErase(!erase);
      }
    };

    const changeColor = (color: string) => {
      setColor(color);
      setErase(false);
    };

    const handleGameAudio = () => {
      setGameAudio(!gameAudio);
      if (user) {
        updateUser(undefined, undefined, !gameAudio);
      }
    };
    const contextLanguage = useSelector((state: any) => state.language);
    const texts =
      languageTexts[contextLanguage.Language as LanguageNames]
        .drawGuessRoomTexts;
    const textsBroken =
      languageTexts[contextLanguage.Language as LanguageNames]
        .brokenPhoneRoomTexts;

    const getFlag = (language: string) => {
      if (language === 'ES') {
        return ar;
      } else {
        return en;
      }
    };
    return (
      <div
        className="Canvas__CanvasContainer"
        ref={ref => (containerRef.current = ref)}
      >
        <div className="Canvas__Header">
          <div className="Canvas__RoundsCounter">
            <div className="Canvas__OptionsContainer" style={{ marginRight: 5 }}>
              {room !== undefined && showExtraButtons && (
                <>
                  <img
                    draggable={false}
                    src={getFlag(room.Language)}
                    alt="Language"
                    className="Room__Flag"
                  />
                  <p className="Room__WordListText">
                    {room.GameType === 'Draw' ? room.WordList : 'Full Length'}
                  </p>
                </>
              )}
              {showExtraButtons && (
                <p>
                  {texts.currentRound}: {roundNumber}/{totalRounds}
                </p>
              )}
            </div>
            {showExtraButtons && !brokenPhone && (
              <div
                className={
                  'Canvas__CanvasHeaderButton ' +
                  (clueAmount >= 3
                    ? 'Canvas__CanvasHeaderButtonDisabled '
                    : '') +
                  (!isDrawing ? ' Canvas__CanvasHeaderButtonHidden' : '')
                }
                onClick={() => onGiveClue()}>
                <SearchIcon style={{ fontSize: '2rem' }} />
                <p className="Canvas__SkipText">{texts.clue}</p>
              </div>
            )}
          </div>
          <div className="Canvas__WordPanelContainer">
            {wordToGuess ? (
              <div className="Canvas__WordContainer">
                <ZoomAnim
                  value={Boolean(wordToGuess)}
                  styles={{ flexDirection: 'column' }}>
                  <p
                    className="Canvas__Word"
                    style={{
                      textDecoration:
                        clueAmount > 0 && isDrawing ? 'underline' : 'none',
                    }}>
                    <span
                      style={{
                        color:
                          clueAmount > 1 && isDrawing ? '#FF3131' : 'black',
                      }}>
                      {wordToGuess.at(0)!.toUpperCase()}
                    </span>
                    <span
                      style={{
                        color:
                          clueAmount > 2 && isDrawing ? '#FF3131' : 'black',
                      }}>
                      {wordToGuess.at(1)!.toUpperCase()}
                    </span>
                    {wordToGuess.slice(2).toUpperCase()}
                  </p>
                </ZoomAnim>
              </div>
            ) : (
              <div className="Canvas__WordContainer">
                <p>?</p>
              </div>
            )}
            {showExtraButtons && !brokenPhone && (
              <div
                className={
                  'Canvas__CanvasHeaderButton ' +
                  (!isDrawing ? 'Canvas__CanvasHeaderButtonHidden ' : '') +
                  (someoneGuessed ? 'Canvas__CanvasHeaderButtonDisabled ' : '')
                }
                onClick={() => onGiveTurn()}>
                <SkipNextIcon style={{ fontSize: '2rem' }} />
                <p className="Canvas__SkipText">{texts.skip}</p>
              </div>
            )}
          </div>
        </div>

        <PlayerDrawingModal
          visible={
            !isDrawing &&
            canvasRef.current !== null &&
            canvasRef.current !== undefined &&
            canvasRef.current.exportPaths().length === 0 &&
            !gameEnd &&
            !intermission &&
            !(roundNumber === 0 && players === 1) &&
            !(roundNumber !== 0 && totalRounds === 0) &&
            roundNumber !== 0 &&
            !canvasRef.current.drewThisTurn()
          }
          playerName={playerDrawingName}
        />

        <YourDrawingTurnModal visible={isDrawing && showYourTurnDrawingModal && !brokenPhone} />

        {roundNumber === 0 && players === 1 && (
          <Box sx={waitingForPlayersModalStyle} style={{ padding: '0' }}>
            <div className="Intermission__WaitingContainer">
              <div className="Canvas__SandClock">
                <img draggable={false} src={sandClock} alt="sandClock" />
              </div>
              <Typography variant="h4" component="h2">
                {texts.waitingForPlayers}
              </Typography>
              <Typography variant="subtitle2" component="p">
                {texts.gameStartingDescription}
              </Typography>
              <p></p>
            </div>
          </Box>
        )}
        {roundNumber !== 0 && totalRounds === 0 && (
          <Box sx={waitingForPlayersModalStyle} style={{ padding: '0' }}>
            <div className="Intermission__WaitingContainer">
              <div className="Canvas__SandClock">
                <img draggable={false} src={sandClock} alt="sandClock" />
              </div>
              <Typography variant="h4" component="h2">
                {texts.gameStarting}
              </Typography>
            </div>
          </Box>
        )}
        {gameEnd && gameEndModalComponent}
        <CustomCanvas
          disabled={!isDrawing}
          isDrawing={isDrawing}
          strokeColor={erase ? 'white' : color}
          strokeWidth={brushSize}
          ref={ref => (canvasRef.current = ref)}
          canvasColor={
            roundNumber === 0 || totalRounds === 0 || gameEnd
              ? 'white'
              : 'white'
          }
          gameEnd={gameEnd}
          intermission={intermission}
          started={roundNumber !== 0}
          fadeToolbar={fadeToolbar}
          setFadeToolbar={setfadeToolbar}
          setFadeButtonBar={setfadeButtonBar}>
          {isDrawing && (
            <div
              className={`Canvas__DrawingOptions ${fadeToolbar ? 'Canvas__VisibleToolbar' : 'Canvas__HiddenToolbar'
                }`}>
              <ColorPicker
                setColor={changeColor}
                brushColors={brushColors}
                color={color}
              />
              <div className="Canvas__DrawingToolsContainer">
                <ZoomAnim value={isDrawing} styles={{ width: '100%' }}>
                  <div className="Canvas__DrawingTools">
                    <div className="Canvas__ToolButtonsContainer">
                      <div
                        className="Canvas__ButtonContainer Canvas__TrashButton"
                        onClick={() => clearData()}>
                        <img draggable={false} alt="trash" src={trashBin} />
                      </div>
                      <div
                        className="Canvas__ButtonContainer"
                        onClick={() => setErased()}
                        style={{
                          boxShadow: erase
                            ? '4px 4px 0px rgba(0, 0, 0, 0.25)'
                            : '',
                        }}>
                        <img draggable={false} alt="eraser" src={eraser} />
                      </div>

                      <BrushPicker onChange={setBrushSize} value={brushSize} />
                    </div>
                  </div>
                </ZoomAnim>
              </div>
            </div>
          )}
          {playerGuessed ? (
            <Confetti
              className={'Canvas__Confetti'}
              recycle={false}
              numberOfPieces={800}
              initialVelocityY={30}
              initialVelocityX={14}
              tweenDuration={4000}
            />
          ) : (
            <></>
          )}
          {props.children}
        </CustomCanvas>
        {roundNumber !== 0 && (
          <div
            className={`Canvas__CanvasButtons`}
            style={{ visibility: 'hidden' }}>
            <div
              className={`Canvas__TimeRemaining ${fadeButtonBar
                ? 'Canvas__VisibleToolbar'
                : 'Canvas__HiddenToolbar'
                }`}
              style={{ display: roundNumber === 0 ? 'none' : 'block' }}>
              {!intermission && !gameEnd ? (
                <CircularProgressbar
                  value={Math.max(0, time)}
                  text={`${Math.max(time, 0)}`}
                  maxValue={maxTime}
                  minValue={0}
                  styles={buildStyles({
                    pathColor: someoneGuessed ? 'red' : '#096bfd',
                    textColor: someoneGuessed ? 'red' : 'black',
                    textSize: '30px',
                  })}
                />
              ) : (
                <CircularProgressbar
                  value={Math.max(0, time)}
                  text={`${Math.max(time, 0)}`}
                  maxValue={maxTime}
                  minValue={0}
                  styles={buildStyles({
                    pathColor: '#096bfd',
                    textColor: 'black',
                    textSize: '30px',
                  })}
                />
              )}
            </div>
            <div
              style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
              className={`${fadeButtonBar
                ? 'Canvas__VisibleToolbar'
                : 'Canvas__HiddenToolbar'
                }`}>
              {!isDrawing && !intermission && !brokenPhone && (
                <div
                  className={
                    'Canvas__ReportButton' +
                    (drawingReported ? ' Canvas__ReportButtonDisabled' : '')
                  }
                  onClick={() => {
                    onReport();
                    setDrawingReported(true);
                  }}>
                  {drawingReported ? texts.reported : texts.report}
                </div>
              )}
              {brokenPhone && (
                <div className='Canvas__BrokenPhoneReadyButton' onClick={() => onSubmit!()}>
                  <span>
                    {drawingReady ? textsBroken.edit : textsBroken.ready}
                  </span>
                  <span>
                    {playersReady}/{players}
                  </span>
                </div>
              )}
              <div className="Header__AudioButton" onClick={handleGameAudio}>
                {gameAudio ? (
                  <img src={unmute} alt="mute" className="Canvas__MuteButton" />
                ) : (
                  <img src={mute} alt="unmute" className="Canvas__MuteButton" />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
);

const ZoomAnim = ({ children, value, styles = {} }: any) => {
  return (
    <Zoom in={value}>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          ...styles,
        }}>
        {children}
      </Box>
    </Zoom>
  );
};

// const modalStyle = {
//   position: 'absolute' as 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   boxShadow: 10,
//   borderRadius: 5,
//   border: '1px solid black',
//   p: 4,
//   zIndex: 999,
// };

const waitingForPlayersModalStyle = {
  display: 'flex',
  position: 'absolute' as 'absolute',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  p: 4,
  zIndex: 10,
  backgroundColor: '#272727cc',
};
