import {ThemeContext} from '@emotion/react';
import {TheaterComedy} from '@mui/icons-material';
import {
  Box,
  Button,
  createTheme,
  Fade,
  Modal,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {LanguageNames, languageTexts} from '../../utils/languageTexts';
import './PlayerDrawingModal.scss';

interface PlaterDrawingProps {
  visible: boolean;
  playerName: string;
}

export const PlayerDrawingModal = ({
  visible,
  playerName,
}: PlaterDrawingProps) => {
  const contextLanguage = useSelector((state: any) => state.language);
  const texts =
    languageTexts[contextLanguage.Language as LanguageNames]
      .playerDrawingModalTexts;

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    bgcolor: 'background.paper',
    boxShadow: 5,
    borderRadius: 5,
    p: 0,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <Box sx={{display: 'flex'}}>
      <Fade easing="ease-out" in={visible}>
        {
          <Box sx={modalStyle}>
            <p
              className='"PlayerDrawing__Description"'
              style={{
                textDecoration: 'underline',
                fontSize: 20,
                textAlign: 'center',
                marginBottom: 5,
                marginTop: -5,
              }}>
              {playerName}
            </p>
            <p
              className="PlayerDrawing__Description"
              style={{fontSize: 20, margin: 0}}>
              {' '}
              {texts.description}
            </p>
          </Box>
        }
      </Fade>
    </Box>
  );
};
