import drawApi from "../api/drawApi";
import { WordList } from "../interfaces/WordList";

export const getAllWordListForLanguage = async (languageId: string) => {
    const response = await drawApi.get(`v1/api/word-list/all/${languageId}`);
    if (response.data.status !== "success") {
        return [];
    }
    return response.data.data.wordLists as WordList[];
};
