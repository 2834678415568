import React, { useContext, useEffect, useRef, useState } from 'react';
import { Canvas, Point, CanvasPath, CanvasRef, ExportImageType } from 'react-sketch-canvas';
import { SocketContext } from '../../../context/SocketContext';
import { useResize } from '../../../hooks/useResize';
import { CustomCanvasRef } from '../CustomCanvas/MyCanvas';
import { rejects } from 'assert';
import { CustomCanvasPreviewRef } from '../../GameEndRound/GameEndRound';


interface CanvasPreviewProps {
  disabled: boolean;
  canvasColor: string;
  paths: MyCanvasPath[];
  startPreview: boolean;
  containerRef: HTMLDivElement | null;
  instantPreview?: boolean;
  onFinishedDrawing?: (drawingBase64: string) => void;
}

interface MyCanvasPath extends CanvasPath {
  id: number;
}

export const CanvasPreview = React.forwardRef<CustomCanvasPreviewRef, CanvasPreviewProps>((props, ref) => {
  const { disabled, canvasColor, paths, startPreview, containerRef, instantPreview } = props;
  const index = useRef<number>(0);
  const [currentPaths, setCurrentPaths] = useState<MyCanvasPath[]>([]);
  const [oldCanvasSize, setOldCanvasSize] = useState({
    width: 0,
    height: 0,
  });
  const [finishedDrawing, setFinishedDrawing] = useState<boolean>(false);
  const currentPoint = useRef<number>(0);
  const lastAddedPath = useRef<number>(-1);
  const { resize } = useResize();
  const canvasRef = useRef<CanvasRef>(null);
  // (canvasRef.current as any).exportImage("png")

  const addPath = () => {
    if (index.current >= paths.length) {
      setFinishedDrawing(true);
      // wait 1 second to export image
      setTimeout(() => {
        canvasRef.current?.exportImage('png').then((base64: string) => {
          if (props.onFinishedDrawing) {
            props.onFinishedDrawing(base64);
          }
        });
      }, 500);
      return;
    }
    if (
      currentPaths[index.current] === undefined &&
      lastAddedPath.current < index.current
    ) {
      lastAddedPath.current = index.current;
      const blankPath = { ...paths[index.current], paths: [] };
      // console.log('AGREGANDO NUEVO PATH EN EL INDICE: ', index.current);
      setCurrentPaths(oldCurrentPath => [...oldCurrentPath, blankPath]);
    }
    setTimeout(() => {
      if (currentPoint.current < paths[index.current].paths.length) {
        if (paths[index.current].paths[currentPoint.current] !== undefined) {
          if (paths[index.current].paths.length == 1) {
            // console.log("Valor del punto en el trazo de 1 : ", paths[index.current].paths[currentPoint.current]);
            // console.log("currentpoint; ", currentPoint.current)
            setCurrentPaths(oldCurrentPath => {
              oldCurrentPath[index.current].paths.push(
                paths[index.current].paths[0],
              );
              return oldCurrentPath;
            });
          } else {
            setCurrentPaths(oldCurrentPath => {
              oldCurrentPath[index.current].paths.push(
                paths[index.current].paths[currentPoint.current],
              );
              return oldCurrentPath;
            });
          }
        }
        // console.log(
        //   ' POINTindex: ',
        //   currentPoint.current,
        //   'Index: ',
        //   index.current,
        //   ' Currentpath: ',
        //   currentPaths,
        //   ' valorpuntoactual: ',
        //   paths[index.current].paths[currentPoint.current],
        //   ' numero de puntos en trazo: ',
        //   paths[index.current].paths.length,
        // );
        // currentPoint.current += 2;
        currentPoint.current += 1;
        addPath();
      } else {
        currentPoint.current = 0;
        index.current += 1;
        addPath();
      }
    }, 5);
  };


  React.useImperativeHandle(ref, () => ({
    exportImage: (type: ExportImageType) => {
      if (!canvasRef.current) {
        return new Promise((resolve: any) => { resolve("") })
      }
      const exportCanvas =  (canvasRef.current).exportImage(type);
      return exportCanvas;
    },
    isFinishedDrawing: () => {
      return finishedDrawing
    }
  }));

  const startAddPath = () => {
    addPath();
  };

  useEffect(() => {
    if (containerRef) {
      const size = {
        width: containerRef!.offsetWidth,
        height: containerRef!.offsetHeight,
      };
      setOldCanvasSize(size);
    }
  }, []);

  useEffect(() => {
    if (startPreview && paths.length > 0 && !instantPreview) {
      startAddPath();
    } else {
      setCurrentPaths(paths);
      index.current = 0;
      currentPoint.current = 0;
    }
  }, [startPreview, paths]);

  useEffect(() => {
    if (containerRef) {
      // console.log("OLD CANVAS SIZE: ", oldCanvasSize)
      // console.log("CURRENT SIZE: ", containerRef.clientWidth, containerRef.clientHeight);
      // console.log("operation value width: ", ((oldCanvasSize.width && oldCanvasSize.width !== 0 ? containerRef!.clientWidth / oldCanvasSize.width : 1)));
      const scaledPaths: MyCanvasPath[] = currentPaths.map(
        (each: MyCanvasPath) => ({
          ...each,
          paths: each.paths.map(path => ({
            x: path.x * ((oldCanvasSize.width && oldCanvasSize.width !== 0 ? containerRef!.clientWidth / oldCanvasSize.width : 1)),
            y: path.y * ((oldCanvasSize.height && oldCanvasSize.height !== 0 ? containerRef!.clientHeight / oldCanvasSize.height : 1)),
          })),
        }),
      );
      setCurrentPaths(scaledPaths);
      const newSize = {
        width: containerRef!.clientWidth,
        height: containerRef!.clientHeight,
      };
      setOldCanvasSize(newSize);
    }
  }, [resize]);



  return (
    <>
      {paths && <Canvas
        paths={currentPaths}
        ref={canvasRef}
        onPointerDown={() => null}
        onPointerMove={() => null}
        onPointerUp={() => null}
        isDrawing={false}
        width="100%"
        height="100%"
        canvasColor={canvasColor}
        backgroundImage=""
        exportWithBackgroundImage={false}
        preserveBackgroundImageAspectRatio="none"
        allowOnlyPointerType="all"
        style={{ width: '100%', maxHeight: '100%' }}
        svgStyle={{ height: '100%' }} />}
    </>
  );

});

