import React, {useEffect, useRef} from 'react';
import './App.scss';
import {BrowserRouter, HashRouter, Route, Routes} from 'react-router-dom';
import {WelcomePage} from './pages/WelcomePage/WelcomePage';
import {RoomPage} from './pages/RoomPage/RoomPage';
import {Header} from './components/Header/Header';
import {ProfilePage} from './pages/ProfilePage/ProfilePage';
import {BrokenPhoneLobby} from './pages/BrokenPhoneLobby/BrokenPhoneLobby';
import {useMediaQuery} from '@mui/material';
import {DownloadAppModal} from './components/DownloadAppModal/DownloadAppModal';
import useIsMainWindow from './hooks/useIsMainWindow';
import {SecondTabAlert} from './components/SecondTabAlert/SecondTabAlert';

function App() {
  const mediaQuery = useMediaQuery('(max-width:1200px)');
  const mediaQuery2 = useMediaQuery('(max-height:650px)');
  // Comment this line to allow multiple tabs
  // const {tabsOpened, onClose} = useIsMainWindow();
  // Uncomment this line to allow multiple tabs
  const tabsOpened = true;

  // useEffect(() => {
  //   console.log(tabsOpened);
  // }, [tabsOpened]);

  document.addEventListener('contextmenu', event => {
    event.preventDefault();
  });

  return tabsOpened ? (
    <div className="App">
      <DownloadAppModal show={mediaQuery || mediaQuery2} />
      <HashRouter>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/room/draw/:roomId" element={<RoomPage />} />
          <Route
            path="/room/brokenphone/:roomId"
            element={<BrokenPhoneLobby />}
          />
          <Route path="/profile" element={<ProfilePage />} />
        </Routes>
      </HashRouter>
    </div>
  ) : (
    <div className="App">
      <SecondTabAlert />
    </div>
  );
}

export default App;
