import React, {useContext, useEffect, useMemo, useRef} from 'react';
import {Chat} from '../../components/Chat/Chat';
import socketIOClient, {Socket} from 'socket.io-client';
import './WelcomePage.scss';
import {SocketContext} from '../../context/SocketContext';
import {Rooms} from '../../components/Rooms/Rooms';
import {Room} from '../../interfaces/Room';
import {useNavigate} from 'react-router';
import {Message} from '../../interfaces/Message';
import {CreateRoomModal} from '../../components/CreateRoomModal/CreateRoomModal';
import {ChangeNameModal} from '../../components/ChangeNameModal/ChangeNameModal';
import {Header} from '../../components/Header/Header';
import {AuthModal} from '../../components/AuthModal/AuthModal';
import {AuthContext} from '../../context/AuthContext';
import {CreateBrokenPhoneRoomModal} from '../../components/CreateBrokenPhoneRoom/CreateBrokenPhoneRoom';
import {DownloadAppModal} from '../../components/DownloadAppModal/DownloadAppModal';
import {SocialMedia} from '../../components/SocialMedia/SocialMedia';
import {useLocalStorage, useWindowScroll} from 'react-use';
import {User} from '../../interfaces/User';
import {isNamedImports} from 'typescript';
import {ErrorModal} from '../../components/ErrorModal/ErrorModal';
import {LanguageNames, languageTexts} from '../../utils/languageTexts';
import {Console} from 'console';
import {getLanguages} from '../../services/LanguageService';
import {useDispatch, useSelector} from 'react-redux';

export function WelcomePage() {
  const [messages, setMessages] = React.useState<Message[]>([]);
  const [rooms, setRooms] = React.useState<Room[]>([]);
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [changeNameVisible, setChangeNameVisible] =
    React.useState<boolean>(false);
  const [enterNameVisible, setEnterNameVisible] =
    React.useState<boolean>(false);

  const [user, setUser] = React.useState<User>();
  const context = useContext(SocketContext);
  const {
    getUser,
    loggedIn,
    showLoginModal,
    setShowLoginModal,
    sendNewName,
    changeId,
  } = useContext(AuthContext);
  const [showRoomError, setShowRoomError] = React.useState(false);
  const [changeLanguage, setChangeLanguage] = React.useState('');
  const navigate = useNavigate();

  const contextLanguage = useSelector((state: any) => state.language);
  const contextMode = useSelector((state: any) => state.mode);
  const stateUser = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const modeRef = useRef(contextMode.mode);

  const texts =
    languageTexts[contextLanguage.Language as LanguageNames].homeTexts;

  const filteredRooms = useMemo(
    () => rooms.filter(room => room.GameType === contextMode.mode),
    [rooms, contextMode],
  );

  useEffect(() => {
    modeRef.current = contextMode.mode;
  }, [contextMode.mode]);

  useEffect(() => {
    if (stateUser.user || stateUser.id) {
      setEnterNameVisible(false);
    }
  }, [stateUser.user, stateUser.id]);

  useEffect(() => {
    if (context.socket) {
      context.socket?.emit('leave-room');
      context.socket?.off('room-message');
      context.socket?.off('server-message');
    }
  }, [document.onfocus]);

  useEffect(() => {
    if (context.socket) {
      context.socket?.emit('request-old-messages');
      context.socket.on('new-name', newName => {
        changeId(newName);
      });
      context.socket.on('global-message', message => {
        setMessages(messages => [...messages, message]);
      });

      context.socket.on('old-global-messages', messages => {
        setMessages(messages);
      });

      context.socket.on('server-message', message => {
        setMessages(messages => [
          ...messages,
          {...message, ServerMessage: true},
        ]);
      });

      context.socket.on('room-info', (rooms: Room[]) => {
        setRooms(rooms);
      });

      context.socket.on('join-room', (roomId: string) => {
        // console.log('join-room', roomId);
        const room = rooms.find(each => each.Id === roomId);
        localStorage.setItem('roomId', roomId);
        if (!room) {
          modeRef.current === 'Draw'
            ? navigate(`/room/draw/${roomId}`)
            : navigate(`/room/brokenphone/${roomId}`);
          return;
        }

        if (room.GameType === 'Draw') {
          navigate(`/room/draw/${roomId}`);
        } else {
          navigate(`/room/brokenphone/${roomId}`);
        }
      });

      context.socket.on('room-creation-failed', (message: string) => {
        console.log('room creation failed', message);
        if (message !== 'ROOM NAME NOT UNIQUE') {
          showError();
        }
      });

      context.socket.emit('request-available-games');
    }

    return () => {
      context.socket?.off('new-name');
      context.socket?.off('global-message');
      context.socket?.off('room-info');
      context.socket?.off('join-room');
      context.socket?.off('old-global-messages');
    };
  }, [context]);

  useEffect(() => {
    if (!stateUser.id && !stateUser.user) {
      setEnterNameVisible(true);
    }
    const userLocalStorage = getUser();
    if (userLocalStorage) {
      setUser(userLocalStorage);
    }
  }, []);

  const sendMessage = (message: String) => {
    if (context.socket && message.trim().length > 0) {
      context.socket.emit('global-message', message);
    }
  };

  const changeName = (name: string) => {
    sendNewName(name);
    setChangeNameVisible(false);
    setEnterNameVisible(false);
  };

  const joinRoom = (roomId: String) => {
    if (context.socket) {
      // context.socket.emit('leave-game');
      // console.log('trying-to-join-room', roomId);
      context.socket.emit('join-room', roomId);
    }
  };

  const showError = () => {
    setChangeNameVisible(false);
    setShowLoginModal(false);
    setModalVisible(false);
    setShowRoomError(true);
  };

  const createRoom = () => {
    setModalVisible(true);
  };

  const onDrawSelected = () => {
    dispatch({type: 'changeMode', payload: {value: 'Draw'}});
  };

  const onBrokenPhoneSelected = () => {
    console.log('CLICK EN onBrokenPhoneSelected');
    dispatch({type: 'changeMode', payload: {value: 'BrokenPhone'}});
  };

  useEffect(() => {
    if (!contextMode.mode) {
      dispatch({type: 'changeMode', payload: {value: 'Draw'}});
    }
  }, [contextMode.mode]);

  return (
    <>
      <Header
        onChangeName={() => setChangeNameVisible(true)}
        onLogin={() => setShowLoginModal(true)}
        setChangeLanguage={(language: string) => setChangeLanguage(language)}
        isOnLobby={true}
      />
      <div className="App_WelcomeContainer" style={{userSelect: 'none'}}>
        <ChangeNameModal
          isChangeName={false}
          visible={enterNameVisible}
          onClose={() => {
            setEnterNameVisible(false);
          }}
          onSubmit={name => {
            changeName(name);
            localStorage.setItem('enterNameModalShown', 'true');
          }}
          obligatory={true}
        />
        <ChangeNameModal
          isChangeName={true}
          visible={changeNameVisible}
          onClose={() => {
            setChangeNameVisible(false);
          }}
          onSubmit={changeName}
          obligatory={false}
        />
        <AuthModal
          visible={showLoginModal}
          onClose={() => {
            setShowLoginModal(false);
          }}
          obligatory={true}
        />
        <div className="App__LobbyContainer">
          {/*Ocultado hasta refactor a brokenPhone */}
          <div className="App_ModeSelectorContainer">
            <ModeItem
              title={texts.game1Title}
              onClick={onDrawSelected}
              selected={contextMode.mode === 'Draw'}
            />
            <ModeItem
              title={texts.game2Title}
              onClick={onBrokenPhoneSelected}
              selected={contextMode.mode === 'BrokenPhone'}
            />
          </div>
          <div className="App__ChatRoomContainer">
            <Rooms
              rooms={filteredRooms}
              onClick={joinRoom}
              onCreateRoom={createRoom}
              user={user}
              changeLanguage={changeLanguage}
            />
            <Chat
              messages={messages}
              sendMessage={sendMessage}
              disabled={false}
              style={'Lobby'}
            />
          </div>
          {contextMode.mode === 'Draw' ? (
            <CreateRoomModal
              visible={modalVisible}
              onClose={() => setModalVisible(false)}
            />
          ) : (
            <CreateBrokenPhoneRoomModal
              visible={modalVisible}
              onClose={() => setModalVisible(false)}
            />
          )}
        </div>
      </div>
      <SocialMedia />
      <ErrorModal
        visible={showRoomError}
        onClose={() => setShowRoomError(false)}
        message={texts.createRoomError}
        title={texts.errorTitle}
      />
    </>
  );
}

interface ModeItemProps {
  title: string;
  onClick: () => void;
  selected: boolean;
}

const ModeItem = ({selected, onClick, title}: ModeItemProps) => {
  return (
    <div
      className="App_ModeContainer"
      style={{
        background: selected
          ? 'linear-gradient(180deg, #096BFD 0%, #043784 100%)'
          : undefined,
        color: selected ? 'white' : undefined,
      }}
      onClick={onClick}>
      <p>{title}</p>
    </div>
  );
};
