import {Message} from '../../interfaces/Message';
import {Player} from '../../interfaces/Player';

const TIMEOUT_MS = 500;

interface UpdateFunctions {
  updateMessages: (messages: Message[]) => void;
  updatePlayers: (players: Player[]) => void;
}

export class MessageReceiver {
  updateMessages: (messages: Message[]) => void;
  updatePlayers: (players: Player[]) => void;

  messagesData: {
    cachedMessages: Message[];
    timer: NodeJS.Timeout | null;
    lastSentTimeMs: number;
  }

  playersData: {
    players: Player[];
    playersTimer: NodeJS.Timeout | null;
    lastSentTimeMs: number;
  }

  constructor() {
    this.updateMessages = () => {};
    this.updatePlayers = () => {};

    this.messagesData = {
      cachedMessages: [],
      timer: null,
      lastSentTimeMs: Date.now(),
    }

    this.playersData = {
      players: [],
      playersTimer: null,
      lastSentTimeMs: Date.now(),
    }
  }

  onPlayersUpdated(updatePlayers: (players: Player[]) => void) {
    this.updatePlayers = updatePlayers;
    return () => {
      this.updatePlayers = () => {};
    }
  }

  onMessagesUpdated(updateMessages: (messages: Message[]) => void) {
    this.updateMessages = updateMessages;
    return () => {
      this.updateMessages = () => {};
    }
  }

  sendMessages() {
    this.updateMessages(this.messagesData.cachedMessages);
    this.messagesData.cachedMessages = [];
    this.messagesData.lastSentTimeMs = Date.now();
  }

  sendPlayers() {
    this.updatePlayers(this.playersData.players);
    this.playersData.players = [];

  }

  receiveMessage(message: Message) {
    this.messagesData.cachedMessages.push(message);

    if (this.messagesData.timer) {
      clearTimeout(this.messagesData.timer);
      this.messagesData.timer = null;
    }
    const now = Date.now();
    if (now - this.messagesData.lastSentTimeMs > TIMEOUT_MS) {
      this.sendMessages();
    } else {
      this.messagesData.timer = setTimeout(() => {
        this.sendMessages();
      }, TIMEOUT_MS);
    }
  }

  receivePlayersUpdate(players: Player[]) {
    // cache update if too many messages are recived in a short time
    this.playersData.players = players;
    if (Date.now() - this.playersData.lastSentTimeMs < TIMEOUT_MS) {
      if (this.playersData.playersTimer) {
        clearTimeout(this.playersData.playersTimer);
        this.playersData.playersTimer = null;
      }
      this.playersData.playersTimer = setTimeout(() => {
        this.sendPlayers();
      }, TIMEOUT_MS);
      return;
    } else {
      if (this.playersData.playersTimer) {
        clearTimeout(this.playersData.playersTimer);
        this.playersData.playersTimer = null;
      }
      this.sendPlayers();
    }
  }
}
