import drawApi from "../api/drawApi";
import {User} from '../interfaces/User';

export const updateUser = async (token: string, username?: string, language?: string, audio?:boolean) => {
    const body: any = {}
    if (username) {
        body.name = username;
    }
    if (language) {
        body.language = language;
    }
    if (audio !== undefined) {
        body.gameAudio = audio;
    }
    const resp = await drawApi.put("v1/api/user/update", body, {
        headers: {
            'token': token
        }
    });

    return resp;
};

export const getUserById = async (token: string, userId: string): Promise<User> => {
    const resp = await drawApi.get(`v1/api/user/${userId}/data`, {
        headers: {
            'token': token
        }
    });
    return resp.data.data.user;
}
