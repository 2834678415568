import { Button, TextField } from '@mui/material';
import React, { useRef } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useSelector } from 'react-redux';
import { SocketContext } from '../../context/SocketContext';
import { LanguageNames, languageTexts } from '../../utils/languageTexts';
import { CanvasPreview } from '../Canvas/CanvasPreview/CanvasPreview';
import {
  MyCanvasPath,
} from '../Canvas/CustomCanvas/MyCanvas';
import './GuessDrawing.scss';

export interface GuessDrawingProps {
  paths: MyCanvasPath[];
  historyId: number;
  lineId: number;
  roundDuration: number;
  roundNumber: number;
}

export const GuessDrawing = ({
  paths,
  historyId,
  lineId,
  roundDuration,
  roundNumber
}: GuessDrawingProps) => {
  const [scaledPaths, setScaledPaths] = React.useState<MyCanvasPath[]>([]);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [text, setText] = React.useState<string>('');
  const [sent, setSent] = React.useState(false);
  const context = React.useContext(SocketContext);
  const [time, setTime] = React.useState<number>(roundDuration);
  const [playersReady, setPlayersReady] = React.useState<number>(0);
  const [playersTotal, setPlayersTotal] = React.useState<number>(0);
  const interval = useRef<any>(0);
  const contextLanguage = useSelector((state: any) => state.language);
  const textsBroken = languageTexts[contextLanguage.Language as LanguageNames].brokenPhoneRoomTexts;
  const [touched, setTouched] = React.useState(false);


  React.useEffect(() => {
    const newPaths = paths.map(each => ({
      ...each,
      paths: each.paths.map(path => ({
        x: path.x * (containerRef.current?.clientWidth || 1),
        y: path.y * (containerRef.current?.clientHeight || 1),
      })),
    }));
    setScaledPaths(newPaths);
  }, [paths]);

  React.useEffect(() => {
    clearInterval(interval.current);
    setTime(roundDuration);
    interval.current = setInterval(() => {
      setTime(time => time - 1);
    }, 1000);
  }, [roundDuration]);

  React.useEffect(() => {
    if (context.socket) {

        context.socket.on('round-status', status => {
            console.log("Status: ", status);
            setPlayersReady(status.playersReady);
            setPlayersTotal(status.playersTotal);
        });

        return () => {
            context.socket?.off('round-status');
        };
    }
}, [context]);

  const inputValidation = () => {
    if (!text.trim().length){
      return "Empty field"
    }
    else if (text.length > 60){
      return "Text can't be longer than 60 characters"
    }
    else if (text.length < 2){
      return "Text can't be a single character"
    }
    return ""
  }

  const handleTouch = () => {
    setTouched(true);
   };

  const onGuess = () => {
    if (context.socket) {
      if (sent){
        context.socket.emit("player-not-ready");
      }else{
        context.socket.emit("player-ready");
        context.socket.emit('add-guess', {
          guess: text,
          lineId: lineId,
          historyId: historyId,
        });
      }
      setSent(!sent);
    }
  };
  return (
    <div
      className="GuessDrawing__Container"
      ref={ref => (containerRef.current = ref)}>
        <div className='GuessDrawing__RoundContainer'>
          <p>
            {textsBroken.round}: {roundNumber}/{playersTotal}
          </p>
        </div>
      <CanvasPreview
        canvasColor='white'
        containerRef={containerRef.current}
        disabled={false}
        startPreview={true}
        paths={scaledPaths}
        instantPreview={true}
      />
      <div className="GuessDrawing__InputContainer">
        <TextField
          variant="outlined"
          error={touched && Boolean(inputValidation())}
          helperText={touched && inputValidation()}
          fullWidth
          placeholder="Take your guess..."
          margin="dense"
          onFocus={handleTouch}
          onChange={event => setText(event.target.value)}
          value={text.replace(/(\r\n|\n|\r)/gm, '')}
          style={{position:"relative"}}
          FormHelperTextProps={{style:{position: "absolute", top: "-25px"}}}
          InputProps={{
            style: { borderRadius: '20px', backgroundColor: 'white' },
          }}
          disabled={sent}
        />
        <div
          className={Boolean(inputValidation()) ? "BrokenPhone__ButtonDisabled" : "BrokenPhone__Button"}
          onClick={Boolean(inputValidation()) ? undefined : onGuess}
          style={{width: "120px"}}>
          <p>{sent ? textsBroken.edit : textsBroken.send} {playersReady} / {playersTotal}</p>
        </div>
      </div>
      <div className="GuessRound__ClockContainer">
        <CircularProgressbar
          value={Math.max(0, time)}
          text={`${Math.max(time, 0)}`}
          maxValue={roundDuration}
          minValue={0}
          styles={buildStyles({
            pathColor: time < 10 ? 'red' : undefined,
            textColor: time < 10 ? 'red' : undefined,
            textSize: '30px',
          })}
        />
      </div>
    </div>
  );
};
