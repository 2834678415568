export type Option = {
  label: string;
  value: string;
};

export const numberOfRoundsOptions: Option[] = [
  {label: '3', value: '3'},
  {label: '5', value: '5'},
  {label: '8', value: '8'},
  {label: '16', value: '16'},
];

export const secondsPerRoundOptions: Option[] = [
  {label: '60', value: '60'},
  {label: '80', value: '80'},
  {label: '100', value: '100'},
];

export const secondsBetweenRoundsOptions: Option[] = [
  {label: '5', value: '5'},
  {label: '10', value: '10'},
  {label: '15', value: '15'},
];

export const maxNumbersOfPlayersOptions: Option[] = [
  {label: '5', value: '5'},
  {label: '10', value: '10'},
  {label: '15', value: '15'},
];

export const maxNumbersOfPlayersOptionsAdmin: Option[] = [
  {label: '5', value: '5'},
  {label: '10', value: '10'},
  {label: '15', value: '15'},
  {label: '50', value: '50'},
];

export const secondsAfterFirstGuessOptions: Option[] = [
  {label: '10', value: '10'},
  {label: '15', value: '15'},
  {label: '20', value: '20'},
];
