import {SvgIcon, Typography} from '@mui/material';
import React from 'react';

import {ReactComponent as ReportIcon} from '../../assets/Report.svg';
import {ReactComponent as DisabledReportIcon} from '../../assets/DisabledReport.svg';

interface Props {
  disabled: boolean;
  text: string;
  onClick: () => void;
  paddingBotton?: number;
}

export const ReportButton = ({
  text,
  disabled,
  onClick,
  paddingBotton = 10,
}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        padding: 10,
        paddingBottom: paddingBotton,
        cursor: disabled ? 'default' : 'pointer',
      }}
      onClick={disabled ? () => {} : onClick}>
      <div style={{marginRight: 10}}>
        <SvgIcon
          component={disabled ? DisabledReportIcon : ReportIcon}
          inheritViewBox
        />
      </div>
      <Typography>{text}</Typography>
    </div>
  );
};
