import {AnyAction} from 'redux';
import {User} from '../interfaces/User';

export interface userState {
  user: User | undefined;
  id: string;
}

export const initialState: userState = {
  user: undefined,
  id: '',
};

type UserAction =
  | {
      type: 'changeUser';
      payload: {user: User};
    }
  | {
      type: 'changeId';
      payload: {id: string};
    }
  | {
      type: 'deleteUser';
    }
  | {
      type: 'removeUser';
    }
  | {
      type: 'removeId';
    };

export const userReducer = (state = initialState, action: UserAction) => {
  switch (action.type) {
    case 'changeUser':
      return {
        ...state,
        user: action.payload.user,
      };
    case 'changeId':
      return {
        ...state,
        id: action.payload.id,
      };
    case 'deleteUser':
      return {
        ...state,
        user: undefined,
      };
    case 'removeId':
      return {
        ...state,
        id: '',
      };

    default:
      return state;
  }
};
