import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Modal,
} from '@mui/material';
import { Player } from '../../interfaces/Player';
import './Players.scss';
import { useContext, useEffect, useState, useRef } from 'react';
import pen from '../../assets/pen.png';
import React from 'react';
import { SocketContext } from '../../context/SocketContext';
import { AuthContext } from '../../context/AuthContext';
import { LanguageNames, languageTexts } from '../../utils/languageTexts';
import { ReportModal } from '../ReportModal/ReportModal';
import { KickButton } from './KickButton';
import { ReportButton } from './ReportButton';
import { SeeProfileButton } from './SeeProfileButton';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { FriendProfileModal } from '../ProfileModal/FriendProfile';
import { useSelector } from 'react-redux';
import crownIcon from "../../assets/crownIcon.png";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {getAvatarUri} from "../../services/Avatars";

interface PlayersProps {
  players: Player[];
  onPlayerLeave: () => void;
  gameState?: string;
  lobby?: boolean;
  maxPlayers?: number;
  isBrokenPhoneGame?: boolean;
  owner?: boolean;
  setGameState?: (p: string) => void;
  gameEnd?: boolean;
  setGameEnd?: (p:boolean) => void;
}

export function Players({
  players,
  onPlayerLeave,
  lobby = false,
  gameState,
  maxPlayers,
  isBrokenPhoneGame = false,
  owner = false,
  setGameState,
  gameEnd,
  setGameEnd

}: PlayersProps) {
  const [sortedPlayers, setSortedPlayers] = useState<Player[]>([]);
  const [ownerPlayer, setOwnerPlayer] = useState<Player | undefined>(undefined);
  const [adminPlayers, setAdminPlayers] = useState<Player[]>([]);
  const [playerReady, setPlayerReady] = useState<boolean>(false);
  const [playersReady, setPlayersReady] = useState<number>(0);

  const pointsChanged = useRef<boolean>(false);

  const contextLanguage = useSelector((state: any) => state.language);
  const texts = languageTexts[contextLanguage.Language as LanguageNames].drawGuessRoomTexts;
  const textsBroken = languageTexts[contextLanguage.Language as LanguageNames].brokenPhoneRoomTexts;
  const context = useContext(SocketContext);

  const [contextMenuName, setContextMenuName] = useState<string | undefined>(
    undefined,
  );

  const playersRef = React.useRef(new Map()).current;

  const updatingPlayers = React.useRef<boolean>(false);

  const onReturnToLobby = () => {
    if (context.socket && setGameState && setGameEnd) {
      context.socket.emit('restart-game');
      context.socket.emit('get-draw-elements');
      setGameEnd(false);
      setGameState("pre-game")
    }
  }


  useEffect(() => {
    if (!updatingPlayers.current) {
      setContextMenuName(undefined);
      updatingPlayers.current = true;

      if (sortedPlayers.length == 0) {
        setSortedPlayers(players);
      } else {
        const newSorted: Player[] = [
          ...sortedPlayers.filter(player =>
            players.find(p => p.Name === player.Name),
          ),
        ];

        players.forEach((player, i) => {
          let updated = false;
          newSorted.forEach((sortedPlayer, j) => {
            if (player.Name === sortedPlayer.Name) {
              if (player.Points !== sortedPlayer.Points) {
                pointsChanged.current = true;
              }

              newSorted[j] = player;
              updated = true;
            }
          });
          if (!updated) {
            newSorted.push(player);
          }
        });
        setSortedPlayers(newSorted.sort((a, b) => b.Points - a.Points));
      }
      setOwnerPlayer(
        players.find(player => {
          return player.owner;
        }),
      );
      setAdminPlayers(
        players.filter(player => {
          return player.admin;
        }),
      );
    }
    updatingPlayers.current = false;
    setPlayersReady(players.filter((p: Player) => p.isReady).length);
  }, [players]);

  React.useEffect(() => {
    const playersBoxes = document.querySelectorAll('.player');
    const playersList = document.querySelector('.Players__ItemsContainer');

    //Disable apple bounce on scroll
    playersList?.addEventListener('touchmove', e => {
      e.stopPropagation();
    });

    // Cache position and size once on initial render
    playersBoxes.forEach(playerBox => {
      playersRef.set(playerBox.id, playerBox.getBoundingClientRect());
    });
  }, []);

  React.useEffect(() => {
    const playersBoxes = document.querySelectorAll('.player');

    // Cache position and size once on initial render

    playersBoxes.forEach(playerBox => {
      playersRef.set(playerBox.id, playerBox.getBoundingClientRect());
    });
  }, [contextMenuName]);

  React.useLayoutEffect(() => {
    const playersBoxes = document.querySelectorAll('.player');

    if (pointsChanged.current) {
      playersBoxes.forEach(playerBox => {
        const cachedRect = playersRef.get(playerBox.id); //y: 500

        if (cachedRect) {
          const nextRect = playerBox.getBoundingClientRect(); //y: 300

          // Invert
          const translateY = cachedRect.y - nextRect.y;

          // Cache the next size and position
          playersRef.set(playerBox.id, nextRect);

          // Play
          playerBox.animate(
            [
              { transform: `translateY(${translateY}px)` },
              { transform: `translateY(0px)` },
            ],
            500,
          );
        } else {
          playersRef.set(playerBox.id, playerBox.getBoundingClientRect());
        }
      });
      pointsChanged.current = false;
    }
  }, [sortedPlayers]);

  let listRef = React.createRef<HTMLDivElement>();

  useOutsideClick(listRef, () => {
    setContextMenuName(undefined);
  });

  const onReady = () => {
    if (context.socket) {
      if (playerReady) {
        context.socket.emit("player-not-ready");
      } else {
        context.socket.emit("player-ready");
      }

      setPlayerReady(!playerReady);
    }
  }

  const onEmit = () => {
    if (context.socket && playersReady === players.length) {
      context.socket.emit('admin-start-game');
    }
  };

  return (
    <div className={isBrokenPhoneGame ? "Players__PlayerContainerBrokenPhone" : "Players__PlayerContainer"} ref={listRef}>
      <div className="Players__PlayerHeader">
        <p>
          {texts.playersTitle}
          <span style={{ fontSize: '16px', marginLeft: '1rem' }}>
            {players.length} / {maxPlayers}
          </span>
        </p>
      </div>
      <div className="Players__ItemsContainer">
        {sortedPlayers.map((each, i) => (
          <div className="player" id={each.Name} key={each.Name}>
            <PlayerItem
              isBrokenPhoneGame={isBrokenPhoneGame}
              Player={each}
              lobby={lobby}
              openContextualMenuName={contextMenuName}
              setContextualMenuName={setContextMenuName}
              someoneGuessed={
                players.filter(item => {
                  return item.Guessed || false;
                }).length > 0
              }
              adminPlayers={adminPlayers}
              ownerPlayer={ownerPlayer}
              setPlayerReady={setPlayerReady}
            />
          </div>
        ))}
      </div>
      <div className="Players__Footer">
        {gameState === 'pre-game' && owner && <div
          className="BrokenPhone__Button"
          onClick={onEmit}
          style={playersReady < players.length ? { filter: "grayscale(1)" } : {}}
        >
          {textsBroken.startGame}
        </div>
        }
        {/* {owner && gameState === "GameEndRound" && (
          <div
            className="GameEnd__NextButton"
            onClick={onShowNext}>
            {textsBroken.next}
          </div>
        )} */}
        {gameState === 'pre-game' &&
          <div className='BrokenPhone__Button' onClick={onReady} style={{ width: "90px" }}>
            {playerReady ? textsBroken.cancel : textsBroken.ready}
          </div>
        }
        {gameState !== 'pre-game' &&
          <div
            className="Canvas__ButtonContainerWarning"
            onClick={onPlayerLeave}
          >
            <ExitToAppIcon fontSize='small'/>
            {textsBroken.leaveRoom}
          </div>
        }
        {isBrokenPhoneGame && gameState === "GameEndRound" && gameEnd &&
          <div className='GameEnd__NewGame'
            onClick={onReturnToLobby}>
            {textsBroken.newGame}
          </div>
        }
      </div>
    </div>
  );
}

interface PlayerItemProps {
  Player: Player;
  lobby?: boolean;
  openContextualMenuName?: string;
  setContextualMenuName: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  someoneGuessed?: boolean;
  isBrokenPhoneGame?: boolean;
  adminPlayers?: Player[];
  ownerPlayer?: Player;
  setPlayerReady?: (p: boolean) => void
}

function PlayerItem({
  Player,
  lobby,
  openContextualMenuName,
  setContextualMenuName,
  someoneGuessed = false,
  isBrokenPhoneGame = false,
  adminPlayers = [],
  ownerPlayer = undefined,
  setPlayerReady,
}: PlayerItemProps) {
  const baseURL = process.env.REACT_APP_SERVER_URL + 'v1/api/resource/';
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const context = useContext(SocketContext);
  const [isMyUser, setIsMyUser] = useState<boolean>(false);
  const [isMyUserAdmin, setIsMyUserAdmin] = useState<boolean>(false);
  const [isMyUserOwner, setIsMyUserOwner] = useState<boolean>(false);
  // const {getUser, state} = useContext(AuthContext);
  const { user, id } = useSelector((state: any) => state.user);
  const [reported, setReported] = React.useState<boolean>(false);
  const [votedForKick, setVotedForKick] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const contextLanguage = useSelector((state: any) => state.language);
  const texts =
    languageTexts[contextLanguage.Language as LanguageNames].drawGuessRoomTexts;

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
    setShowOptions(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onKick = () => {
    if (context.socket) {
      context.socket.emit('kick', Player.Name);
      setVotedForKick(true);
    }

    handleClose();
  };

  const onKickAsAdmin = () => {
    if (context.socket) {
      context.socket.emit('admin-kick', Player.Name);
    }

    handleClose();
  };

  const onReport = () => {
    if (context.socket) {
      setOpen(true);
    }

    handleClose();
  };

  useEffect(() => {
    if (context.socket) {

      context.socket.on('game-end', () => {
        if (setPlayerReady) {
          setPlayerReady(false);
        }
      })

      context.socket.on('restart-game', () => {
        setReported(false);
        setVotedForKick(false);
      });

      context.socket.on('game-starting', () => {
        setReported(false);
        setVotedForKick(false);

      });

      return () => {
        context.socket?.off('restart-game');
        context.socket?.off('game-starting');
        context.socket?.off('game-end');
      };
    }
  }, [context]);

  useEffect(() => {
    console.log("Player: ", Player);
  }, [Player]);

  useEffect(() => {
    if (user) {
      const parsedUser = JSON.parse(JSON.stringify(user));
      if (parsedUser.name === Player.Name) {
        setIsMyUser(true);
      }
      if (
        adminPlayers.find(player => {
          return player.Name === parsedUser.name;
        }) !== undefined
      ) {
        setIsMyUserAdmin(true);
      }
      if (ownerPlayer && parsedUser.name === ownerPlayer?.Name) {
        setIsMyUserOwner(true);
      }
    } else {
      const guest = id;

      if (guest) {
        if (guest === Player.Name) {
          setIsMyUser(true);
        }
      }
    }
  }, [Player]);

  useEffect(() => {
    if (openContextualMenuName === Player.Name) {
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  }, [openContextualMenuName]);

  return (
    <div
      className={Player.Drawing ? 'Players__PlayerDrawing' : ''}
      style={{
        borderRadius: 21,
        marginBottom: 1,
        backgroundColor: '#ffffff',
      }}>
      <ReportModal
        player={Player}
        open={open}
        setOpen={setOpen}
        setReported={setReported}
      />
      <Accordion
        expanded={showOptions}
        disableGutters
        square
        elevation={0}
        sx={{
          '&:before': {
            display: 'none',
          },
          borderColor: 'transparent',
          borderRadius: showOptions ? 5 : 30,
          backgroundColor: 'transparent',
          // margin: '0px ',
          '& .MuiAccordionSummary-root': {
            padding: '0px 10px',
            cursor: 'default',
          },
          '& .MuiAccordionSummary-root:hover': {
            cursor: 'default',
          },
          '& .MuiPaper-root-MuiAccordion-root': {
            cursor: 'default',
          },

          '& .MuiPaper-root-MuiAccordion-root:hover': {
            cursor: 'default',
          },
          '& .MuiButtonBase-root-MuiAccordionSummary-root': {
            cursor: 'default',
          },
          '& .MuiAccordionSummary-root:hover:not(.Mui-disabled)': {
            cursor: 'default',
          },
        }}>
        <AccordionSummary>
          <div
            className={
              isMyUser && !user
                ? 'Players__PlayerItemContainer_MyUser'
                : 'Players__PlayerItemContainer'
            }
            onClick={() => {
              if (!(isMyUser && !user)) {
                if (openContextualMenuName === Player.Name) {
                  setContextualMenuName(undefined);
                } else {
                  setContextualMenuName(Player.Name);
                }
              }
            }}>
            <div className="Players__PlayerItem">
              <div className="Players__AvatarContainer">
                <div className={'Players__AvatarSubcontainer'}>
                  <div
                    style={{
                      height: '80%',
                      width: '80%',
                    }}>
                    <img
                      draggable={false}
                      src={
                        Player.Avatar
                          ? baseURL + Player.Avatar
                          // : `https://avatars.dicebear.com/api/adventurer/${Player.Name}.svg?flip=true`
                          : getAvatarUri(Player.Name)
                      }
                      alt="Avatar"
                      className={'Players__Avatar'}
                    />
                  </div>
                </div>
                <div className="Players__NameContainer">
                  <p className="Players__Name">
                    {isMyUser ? <b>{Player.Name}</b> : Player.Name}
                  </p>
                  {Player.id === undefined && (
                    <p className="Players__Guess">{texts.guest}</p>
                  )}
                  {Player.owner && <img draggable={false} src={crownIcon} alt="sandClock" style={{ height: "22px", width: "22px" }} />}
                  {Player.isReady && <CheckCircleOutlineIcon color='success' />}
                </div>
              </div>
              <div className="Player__Icons">
                {!Player.Drawing && Player.Guessed && Player.guessedOrder && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#0755cb',
                      width: 21,
                      height: 21,
                      borderRadius: 13,
                      position: 'absolute',
                      zIndex: 1,
                      left: '-6%',
                    }}>
                    <p
                      style={{
                        color: '#fee124',
                        fontSize: 14,
                        fontWeight: 'bold',
                      }}>
                      {Player.guessedOrder + 'º'}
                    </p>
                  </div>
                )}
                {!isBrokenPhoneGame && (
                  <p
                    className="Players__Points"
                    style={
                      !Player.Drawing
                        ? !lobby && Player.Guessed
                          ? { backgroundColor: 'red' }
                          : !lobby
                            ? { backgroundColor: '#666666' }
                            : {}
                        : someoneGuessed
                          ? { backgroundColor: 'red' }
                          : { backgroundColor: '#666666' }
                    }>
                    {Player.Drawing && (
                      <img
                        draggable={false}
                        src={pen}
                        alt="pen"
                        className="Players__PenIcon"
                      />
                    )}

                    {Player.Points || 0}
                  </p>
                )}
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {isMyUser && user ? (
            <>
              <Divider />
              <SeeProfileButton
                text={texts.seeProfile}
                onClick={() => {
                  setShowProfile(true);
                }}
                disabled={Player.id === undefined}
              />
            </>
          ) : (
            <>
              <Divider />
              <KickButton
                disabled={Player.owner || isMyUser || votedForKick}
                text={
                  isMyUserAdmin || isMyUserOwner
                    ? texts.kick
                    : texts.voteForKick
                }
                onClick={() =>
                  isMyUserAdmin || isMyUserOwner ? onKickAsAdmin() : onKick()
                }
              />
              <Divider />
              <ReportButton
                disabled={isMyUser || reported}
                text={reported ? texts.reported : texts.report}
                onClick={onReport}
                paddingBotton={Player.id !== undefined ? 10 : 0}
              />
              {Player.id !== undefined && (
                <>
                  <Divider />
                  <SeeProfileButton
                    text={texts.seeProfile}
                    onClick={() => {
                      setShowProfile(true);
                    }}
                  />
                </>
              )}
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Modal open={showProfile} onClose={() => setShowProfile(false)}>
        <>
          <FriendProfileModal
            friend={Player}
            onClose={() => setShowProfile(false)}
            visible={showProfile}
          />
        </>
      </Modal>
    </div>
  );
}
