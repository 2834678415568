import {
  Button,
  Menu,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import Select from 'react-select';
import React, {useContext, useEffect, useState} from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './Header.scss';
import {AuthContext} from '../../context/AuthContext';
import {useNavigate} from 'react-router';
import logo from '../../assets/drawbotic.png';
import mute from '../../assets/mute.png';
import unmute from '../../assets/unmute.png';
import gameLogo from '../../assets/drawAndGuess.png';
import gameLogoBrokenPhone from '../../assets/brokenPhone.png';
import {getLanguages} from '../../services/LanguageService';
import ar from '../../assets/ar.png';
import en from '../../assets/en.png';
import {User} from '../../interfaces/User';
import {SocketContext} from '../../context/SocketContext';
import {ProfileModal} from '../ProfileModal/ProfileModal';
import {LanguageNames, languageTexts} from '../../utils/languageTexts';
import {LANGUAGES_MAP} from '../../interfaces/Language';
import {useDispatch, useSelector} from 'react-redux';
import PortableWifiOffIcon from '@mui/icons-material/PortableWifiOff';
import {userState} from '../../store/userReducer';
import {getAvatarUri} from "../../services/Avatars";

interface HeaderProps {
  onChangeName?: () => void;
  onLogoutF?: () => void | undefined;
  onLogin?: () => void;
  game?: 'drawAndGuess' | 'brokenPhone';
  onPlayerLeave?: () => void;
  setChangeLanguage?: (language: string) => void;
  isOnLobby?: boolean;
}

interface LanguageOption {
  label: JSX.Element;
  value: string;
}

export function Header({
  onChangeName,
  onLogin,
  game,
  onPlayerLeave,
  onLogoutF = undefined,
  setChangeLanguage = (language: string) => {},
  isOnLobby = false,
}: HeaderProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [languageOptions, setLanguageOptions] = React.useState<
    LanguageOption[]
  >([]);
  const {Logout, updateUser, refreshUser, state} = useContext(AuthContext);
  const [profileModal, setProfileModal] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);
  const contextLanguage = useSelector((state: any) => state.language);
  const [language, setLanguage] = React.useState<string>(
    contextLanguage.Language,
  );
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_SERVER_URL + 'v1/api/resource/';
  const context = React.useContext(SocketContext);

  const stateUser = useSelector((state: any) => state.user);

  const [avatarURL, setAvatarURL] = useState(
    getAvatarUri(stateUser.id)
  );

  const texts =
    languageTexts[contextLanguage.Language as LanguageNames].headerTexts;

  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [userName, setUserName] = useState('');
  const contextSocket = useContext(SocketContext);

  const onLogout = () => {
    handleClose();
    Logout();
    if (contextSocket.socket) {
      contextSocket.socket.emit('logout');
    }
  };

  useEffect(() => {
    if (stateUser.user) {
      setUserName(stateUser.user.name);
    } else if (stateUser.id) {
      setUserName(stateUser.id || texts.guest);
    }
    if (stateUser.user && stateUser.user.avatar) {
      setAvatarURL(baseURL + stateUser.user.avatar);
    } else if (stateUser.user) {
      setAvatarURL(
        // `https://avatars.dicebear.com/api/adventurer/${stateUser.user.name}.svg?flip=true`,
        getAvatarUri(stateUser.id)
      );
    } else {
      setAvatarURL(
        // `https://avatars.dicebear.com/api/adventurer/${stateUser.id}.svg?flip=true`,
        getAvatarUri(stateUser.id)
      );
    }
  }, [stateUser.user, stateUser.id]);

  useEffect(() => {
    if (context.socket) {
      context.socket.on('new-name', newName => {
        setUserName(newName);
      });
    }

    return () => {
      context.socket?.off('new-name');
    };
  }, [context]);

  const handleLanguage = (event: any) => {
    context.socket?.emit('set-language', event.value as string);
    setLanguage!(event.value as string);
    setChangeLanguage(event.value as string);
    if (event && event.value) {
      dispatch({
        type: 'changeLanguage',
        payload: {value: event.value as string},
      });
    }
    if (stateUser.user) {
      updateUser(undefined, event.value as string);
    }
  };

  useEffect(() => {
    getLanguages().then(languages => {
      const languageOptions = languages.map(language => {
        return {
          label: (
            <div className="Header__LanguageSelectionItem">
              <p>{LANGUAGES_MAP[language.code]}</p>
            </div>
          ),
          value: language.code,
        };
      });
      setLanguageOptions(languageOptions);
    });
  }, []);

  useEffect(() => {
    if (stateUser.user !== undefined && stateUser.user.language) {
      dispatch({
        type: 'changeLanguage',
        payload: {value: stateUser.user.language},
      });
      setLanguage!(stateUser.user.language);
      context.socket?.emit('set-language', stateUser.user.language);
    }
  }, [stateUser.user]);

  const toggleProfileModal = async () => {
    setProfileModal(!profileModal);
  };

  const [currentWidth, setCurrentWidth] = useState('85vw');
  useEffect(() => {
    if (window.innerWidth > 1400) {
      setCurrentWidth(`${-window.innerWidth / 40 + 133}vw`);
    } else {
      setCurrentWidth(`${-0.052 * window.innerWidth + 38.2}vw`);
    }
  }, [window.innerWidth]);

  return (
    <header style={{userSelect: 'none'}}>
      <div className="Header__Container">
        <div
          className="Header__TextContainer"
          style={isOnLobby ? {} : {width: currentWidth}}>
          <div className="Header__Title">
            <img
              draggable="false"
              src={logo}
              alt="Logo"
              className="Header__Logo"
            />
            {game === 'drawAndGuess' ? (
              <img
                draggable="false"
                src={gameLogo}
                className="Header__GameLogo"
              />
            ) : game === 'brokenPhone' ? (
              <img
                draggable="false"
                src={gameLogoBrokenPhone}
                className="Header__GameLogo"
              />
            ) : (
              <></>
            )}
          </div>
          <div className="Header__ButtonsContainer">
            {!context.isConnected ? (
              <div className="Header__DisconnectedIcon">
                <Tooltip title={texts.disconnectTooltip}>
                  <PortableWifiOffIcon fontSize="large" />
                </Tooltip>
              </div>
            ) : (
              ''
            )}
            <div className="Header__LanguageSelection">
              <Select
                options={languageOptions}
                menuPortalTarget={document.body}
                theme={theme => ({
                  ...theme,
                  borderRadius: 20,
                  colors: {
                    ...theme.colors,
                    primary25: '#F2F2F2',
                  },
                })}
                styles={{
                  control: base => ({
                    ...base,
                    border: 0,
                    boxShadow: 'none',
                    cursor: 'pointer',
                  }),
                  option: (styles, {isFocused, isSelected}) => ({
                    ...styles,
                    borderRadius: 20,
                    color: 'black',
                    background: isFocused
                      ? 'white'
                      : isSelected
                      ? 'white'
                      : undefined,
                  }),
                  menuList: base => ({
                    ...base,
                    padding: 0,
                  }),
                  menuPortal: base => ({
                    ...base,
                    zIndex: 100,
                  }),
                }}
                value={{
                  label: (
                    <div className="Header__LanguageSelectionItem">
                      <p>{LANGUAGES_MAP[language]}</p>
                    </div>
                  ),
                  value: language,
                }}
                onChange={handleLanguage}
              />
            </div>
            <ProfileModal
              visible={profileModal}
              onClose={() => setProfileModal(false)}
            />
            <div
              className="Header__RightContainer"
              style={{borderRadius: anchorEl ? '20px 20px 0px 0px' : '20px'}}>
              <div
                className="Header__UserContainer"
                onClick={event => handleClick(event)}
                style={{
                  cursor: 'pointer',
                }}>
                <div className={'Header__AvatarSubcontainer'}>
                  <img
                    src={avatarURL}
                    alt="Avatar"
                    className={'Players__Avatar'}
                  />
                </div>
                <div className="Header__UserNameContainer">
                  <p>{userName}</p>
                </div>
                <KeyboardArrowDownIcon
                  fontSize="large"
                  style={{color: '#0755CB'}}
                />
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transitionDuration={0}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                  style: {
                    width: 220,
                  },
                }}>
                {!stateUser.user ? (
                  <div>
                    <MenuItem
                      onClick={onChangeName}
                      disabled={game !== undefined}>
                      {texts.changeName}
                    </MenuItem>
                    <MenuItem onClick={onLogin}>{texts.authOpcions}</MenuItem>
                  </div>
                ) : (
                  <div>
                    <MenuItem onClick={toggleProfileModal}>
                      {texts.profile}
                    </MenuItem>
                    <MenuItem onClick={onLogoutF ? onLogoutF : onLogout}>
                      {texts.logout}
                    </MenuItem>
                  </div>
                )}
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
