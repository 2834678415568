import React, {useContext, useEffect, useRef, useState} from 'react';
import './ColorPicker.scss'

interface ColorPickerProps {
    brushColors: string[];
    setColor: (color: string) => void;
    color: string
}

export const ColorPicker = ({brushColors, setColor, color}: ColorPickerProps) => {

    return(
        <div className='Canvas__ColorPicker'>
            {brushColors.map((brushColor) => {
                return(
                    <div
                        key={brushColor}
                        className={'Canvas__ColorPickerOption' + (brushColor === color ? " Canvas__ColorPickerOptionSelected" : "")}
                        style={{backgroundColor: brushColor}}
                        onClick={() => {setColor(brushColor)}}
                    >

                    </div>
                );
            })}
        </div>
    );
}