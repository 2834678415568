const AVATAR_API = 'https://api.dicebear.com/9.x/adventurer/svg';

export const getCircularAvatarUri = (name: string) => {
  return `${AVATAR_API}?seed=${name}&flip=true&background=%23ffffff&radius=50`;
}

export const getAvatarUri = (name: string) => {
  return `${AVATAR_API}?seed=${name}&flip=true`;
}

export const getSignUpAvatarUri = () => {
  return `${AVATAR_API}?seed=signup&flip=true`;
}

export const getDefaulAvatarUri = () => {
  return `${AVATAR_API}?seed=default&flip=true&background=%23ffffff&radius=50`;
}
