import {
  AuthTexts,
  BrokenPhoneRoomTexts,
  ChangeNameTexts,
  CreateRoomTexts,
  DrawGuessRoomTexts,
  EnterPhraseTexts,
  GameEndTexts,
  GameSelectTexts,
  HeaderTexts,
  HomeTexts,
  PlayerDrawingModalTexts,
  ProfileTexts,
  ReportModalTexts,
  SecondTabAlertTexts,
} from '../languageTexts';

export const spanishHomeTexts: HomeTexts = {
  chatTitle: 'CHAT GLOBAL',
  roomsTitle: 'SALAS',
  sendMsg: 'Enviar',
  game1Title: 'DIBUJAR Y ADIVINAR',
  game2Title: 'TELÉFONO DESCOMPUESTO',
  errorTitle: 'Algo salió mal',
  createRoomError:
    'Hubo un error creando la sala, inténtalo de nuevo más tarde',
  createTitle: 'Crear una sala',
  chatPlaceholder: 'Enviar mensaje...',
  chatPlaceholderWhileDrawing: 'No puedes enviar mensajes mientras dibujas',
};

export const spanishGameSelectTexts: GameSelectTexts = {
  drawGuessSubtitle: 'Dibuja y adivina palabras al azar para obtener puntos!',
  brokenPhoneSubtitle:
    '¡Dibuja frases y adivina qué representan esos dibujos locos!',
  playText: 'Jugar',
};

export const spanishDrawGuessTexts: DrawGuessRoomTexts = {
  currentRound: 'Ronda',
  guess: 'Adivinar',
  skip: 'SALTAR',
  voteForKick: 'Votar para expulsar',
  report: 'Reportar',
  reported: 'Reportado',
  waitingForPlayers: 'ESPERANDO JUGADORES...',
  gameStarting: 'JUEGO EMPEZANDO...',
  sureToLeave: '¿Seguro que quieres salir?',
  leaveRoom: 'Salir de la sala',
  cancel: 'Cancelar',
  guessed: 'Adivinado',
  playersTitle: 'JUGADORES',
  chatTitle: 'SALA DE CHAT',
  kick: 'Expulsar',
  clue: 'PISTA',
  waitingForNextRound: 'ESPERANDO A LA SIGUIENTE RONDA...',
  confirmationModalAcceptText: 'Ir a Inicio de sesión',
  confirmationModalAcceptText2: 'Cerrar sesión',
  confirmationModalCancelText: 'Cancelar',
  confirmationModalTitle: '¿Estás seguro?',
  confrmationModalText: 'Si inicia sesión ahora, saldrá del juego actual',
  confrmationModalText2: 'Si cierra sesión ahora, saldrá del juego actual',
  gameStartingDescription: 'El juego comenzará una vez que se una un jugador',
  seeProfile: 'Ver perfil',
  guest: 'Invitado',
  closeConfirmation: '¿Estás seguro que quieres salir del juego?',
};

export const spanishChangeNameTexts: ChangeNameTexts = {
  titleChangeName: 'CAMBIAR NOMBRE DE INVITADO',
  titleEnterName: 'INTRODUCE TU NOMBRE',
  doneButtonText: 'HECHO',
  placeHolder: 'Nuevo nombre',
  cancelButtonText: 'CANCELAR',
};

export const spanishAuthTexts: AuthTexts = {
  titleRegister: 'REGISTRO',
  titleLogin: 'INICIAR SESIÓN',
  name: 'Nombre',
  confirmPassword: 'Confirmar contraseña',
  language: 'Idioma',
  email: 'Email',
  password: 'Contraseña',
  continueAs: 'o continuar como ',
  guest: 'Invitado',
  buttonRegister: 'Registrarse',
  buttonLogin: 'Iniciar sesión',
  avatar: 'Subir Avatar',
  passwordDescription:
    'La contraseña debe tener al menos 6 caracteres, una letra y un número',
  credentialsError: 'Usuario o contraseña incorrectos',
  passwordNotMatch: 'Las contraseñas no coinciden',
  usernameRequired: 'Nombre de usuario requerido',
  usernameLength: 'El nombre de usuario es demasiado largo',
  passwordRequired: 'Contraseña requerida',
  passwordFormat:
    'La contraseña debe tener al menos 6 caracteres, una letra y un número',
  emailRequired: 'Email requerido',
  emailFormat: 'Email no válido',
  emailLength: 'El email es demasiado largo',
  userInUse: 'El usuario y/o email ya existe',
  invalidToken: 'Sesión expirada',
};

export const spanishCreateRoomTexts: CreateRoomTexts = {
  newRoom: 'Nombre de la Sala',
  room: 'Sala de ',
  roomNameError: 'El nombre de sala ya esta en uso',
  title: 'Creación de sala',
  roomName: 'Nombre de la sala',
  numberOfRounds: 'Número de rondas',
  secondsPerRound: 'Segundos por ronda',
  secondsBetweenRounds: 'Segundos entre rondas',
  maxPlayers: 'Número máximo de jugadores',
  secondsAfterFirstGuess: 'Segundos después de la primera adivinanza',
  language: 'Idioma',
  wordList: 'Lista de palabras',
  cancelButtonText: 'CANCELAR',
  createButtonText: 'CREAR',
  secondsForInicialPhrase: 'Segundos para la frase inicial',
  secondsForDrawing: 'Segundos para dibujar',
  secondsForGuessing: 'Segundos para adivinar',
  gameLength: 'Duración del juego',
};

export const spanishProfileTexts: ProfileTexts = {
  titleFriend: 'PERFIL',
  title: 'MI PERFIL',
  gamesWon: 'Juegos ganados',
  playedGames: 'Juegos jugados',
  guessedWords: 'Palabras adivinadas',
  language: 'Idioma',
  brokenPhoneGamesPlayed: 'Juegos de Broken Phone jugados'
};

export const spanishHeaderTexts: HeaderTexts = {
  guest: 'Invitado',
  authOpcions: 'Iniciar sesión / Registrarse',
  changeName: 'Cambiar nombre',
  profile: 'Perfil',
  logout: 'Cerrar sesión',
  disconnectTooltip:
    'Se perdió la conexión con el servidor, vuelva a intentarlo más tarde',
};

export const spanishGameEndTexts: GameEndTexts = {
  firstPlace: '1er Lugar',
  secondPlace: '2do Lugar',
  thirdPlace: '3er Lugar',
};

export const spanishReportModalTexts: ReportModalTexts = {
  reportReasons: [
    'Abuso escrito o uso de lenguaje inapropiado',
    'Dibujos obscenos o inapropiados',
    'Imagen o nombre de perfil inapropiados',
    'Uso de trampas o hackeo',
    'Jugador molesto o malintencionado',
    'Racismo, sexismo, homophobia, discriminación, etc',
    'Otros',
  ],
  otherReason: 'Otra razón',
  cancel: 'Cancelar',
  report: 'Reportar',
  reportPlayer: 'REPORTAR JUGADOR',
  selectReason: 'Elige una razón',
};

export const spanishPlayerDrawingModalTexts: PlayerDrawingModalTexts = {
  description: 'esta dibujando ...',
  yourTurnToDraw: 'Es tu turno de dibujar!',
};

export const spanishSecondTabAlertTexts: SecondTabAlertTexts = {
  title: '¡Ups!',
  description: 'Solo puedes abrir el juego una vez al mismo tiempo.',
};

export const spanishBrokenPhoneRoomTexts: BrokenPhoneRoomTexts = {
  cancel: 'CANCELAR ',
  edit: 'EDITAR ',
  leaveRoom: 'SALIR',
  ready: 'LISTO ',
  send: 'ENVIAR ',
  sendMessage: 'Enviar mensaje',
  startGame: 'INICIAR JUEGO ',
  waitingForPlayers: 'Esperar por mas jugadores',
  previous: 'ANTERIOR ',
  next: 'SIGUIENTE ',
  returnToLobby: 'Volver al Lobby',
  newGame: "Nuevo juego",
  round: "Ronda",
  lineEnded: "Termino el hilo, presione aqui para descargar",
  history: "PARTIDA ANTERIOR",
}

export const spanishEnterPhraseTexts: EnterPhraseTexts = {
  writeYourPhrase: 'Escribe una Frase',
  waitingForPlayers: 'Esperando jugadores...',
  edit: 'Editar',
  done: 'Hecho',
  random: 'Aleatorio',
  round: 'Ronda'
}
