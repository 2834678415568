export type Option = {
  label: string;
  value: string;
};

export const secondsForInicialPhraseOpcions: Option[] = [
  {label: '45', value: '45'},
  {label: '60', value: '60'},
  {label: '90', value: '90'},
];

export const secondsForDrawingOpcions: Option[] = [
  {label: '45', value: '45'},
  {label: '60', value: '60'},
  {label: '90', value: '90'},
];

export const secondsForGuessingOpcions: Option[] = [
  {label: '30', value: '30'},
  {label: '45', value: '45'},
  {label: '60', value: '60'},
];

export const maxNumbersOfPlayersOptions: Option[] = [
  {label: '5', value: '5'},
  {label: '10', value: '10'},
  {label: '15', value: '15'},
];

export enum GameLength {
  FullLength = '100%',
  HalfLength = '50%',
  QuarterLength = '25%',
}

export const gameLengths: Option[] = [
  {label: GameLength.FullLength, value: GameLength.FullLength},
  {label: GameLength.HalfLength, value: GameLength.HalfLength},
  {label: GameLength.QuarterLength, value: GameLength.QuarterLength},
];
