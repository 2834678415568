import {Box, Modal} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import {Player} from '../../interfaces/Player';
import './GameEndModal.scss';
import win from '../../assets/game_win.png';
import win2 from '../../assets/game_win_2.png';
import gameEndModal from '../../assets/endModal.png';
import {text} from 'stream/consumers';
import {Textsms} from '@mui/icons-material';
import {LanguageNames, languageTexts} from '../../utils/languageTexts';
import {useSelector} from 'react-redux';
import {getCircularAvatarUri, getDefaulAvatarUri} from "../../services/Avatars";

interface GameEndModalProps {
  visible: boolean;
  players: Player[];
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  bgcolor: '#000000d1',
  borderRadius: 5,
  p: 0,
  minHeight: 300,
  border: 0,
  zIndex: 5,
  overflow: 'visible',
  borderColor: 'transparent',
  outline: 'none',
};

export const GameEndModal = ({visible, players}: GameEndModalProps) => {
  const [sortedPlayers, setSortedPlayers] = useState<Player[]>([]);
  const contextLanguage = useSelector((state: any) => state.language);
  const texts =
    languageTexts[contextLanguage.Language as LanguageNames].gameEndTexts;
  const baseURL = process.env.REACT_APP_SERVER_URL + 'v1/api/resource/';

  const [imgPlayer1, setImgPlayer1] = useState(
    getDefaulAvatarUri()
  );
  const [imgPlayer2, setImgPlayer2] = useState(
    getDefaulAvatarUri()
  );
  const [imgPlayer3, setImgPlayer3] = useState(
    getDefaulAvatarUri()
  );

  const [avatar1, setAvatar1] = useState(false)
  const [avatar2, setAvatar2] = useState(false)
  const [avatar3, setAvatar3] = useState(false)

  useEffect(() => {
    const toSort = [...players];
    toSort.sort((a, b) => b.Points - a.Points);
    setSortedPlayers(toSort);
    setImgPlayer1(
      toSort[0].Avatar
        ? baseURL + toSort[0].Avatar
        : getCircularAvatarUri(toSort[0].Name)
    );
    setAvatar1(toSort[0].Avatar ? true : false)
    setImgPlayer2(
      toSort[1].Avatar
        ? baseURL + toSort[1].Avatar
        : getCircularAvatarUri(toSort[1].Name),
    );

    setAvatar2(toSort[1].Avatar ? true : false)

    if (toSort.length > 2) {
      setImgPlayer3(
        toSort[2].Avatar
          ? baseURL + toSort[2].Avatar
          : getCircularAvatarUri(toSort[2].Name),
      );
      setAvatar3(toSort[2].Avatar ? true : false)
    }
  }, [players]);

  return (
    <Box sx={modalStyle}>
      <div className="GameEnd__ModalImageContainer">
        <img
          draggable={false}
          src={gameEndModal}
          alt="endModal"
          className="GameEnd__EndModal"
        />
        {sortedPlayers.length > 0 && (
          <div className="GameEnd__ModalPlayerPodium">
            <div
              className="GameEnd__PlayerPodium"
              style={{
                alignSelf: 'center',
              }}>
              <img
                draggable={false}
                src={imgPlayer2}
                alt="avatar"
                className={'GameEnd__Avatar'}
                style={avatar2 ? {
                  borderColor: 'white',
                  borderWidth: '2px',
                  borderStyle: 'solid',
                } : {}}
              />
              <div className="GameEnd__Text">
                <p className="GameEnd__PodiumPlace">{texts.secondPlace}</p>
                <p className="GameEnd__Name">{sortedPlayers[1].Name}</p>
              </div>
            </div>
            <div
              className="GameEnd__PlayerPodium"
              style={{alignSelf: 'flex-start'}}>
              <img
                draggable={false}
                src={imgPlayer1}
                alt="avatar"
                className={'GameEnd__Avatar'}
                style={avatar1 ? {
                  borderColor: 'white',
                  borderWidth: '2px',
                  borderStyle: 'solid',
                } : {}}
              />
              <div className="GameEnd__Text">
                <p className="GameEnd__PodiumPlace">{texts.firstPlace}</p>
                <p className="GameEnd__Name">{sortedPlayers[0].Name}</p>
              </div>
            </div>
            {sortedPlayers.length > 2 && (
              <div
                className="GameEnd__PlayerPodium"
                style={{alignSelf: 'center', marginTop: "8%"}}>
                <img
                  draggable={false}
                  src={imgPlayer3}
                  alt="avatar"
                  className={'GameEnd__Avatar'}
                  style={avatar3 ? {
                    borderColor: 'white',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                  } : {}}
                />
                <div className="GameEnd__Text">
                  <p className="GameEnd__PodiumPlace">{texts.thirdPlace}</p>
                  <p className="GameEnd__Name">{sortedPlayers[2].Name}</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Box>
  );
};
