import React, { useContext, useEffect, useMemo, useRef } from "react";
import "./SocialMedia.scss"
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter'; 

export const SocialMedia = () => {
    return(
        <div className="SocialMedia__Container">
            <FacebookIcon fontSize="large" style={{color: "white", cursor: "pointer"}}/>
            <InstagramIcon fontSize="large" style={{color: "white", cursor: "pointer"}}/>
            <TwitterIcon fontSize="large" style={{color: "white", cursor: "pointer"}}/>
        </div>
    );
}