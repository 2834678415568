import {useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {Header} from '../../components/Header/Header';
import {AuthContext} from '../../context/AuthContext';
import {User} from '../../interfaces/User';
import {LanguageNames, languageTexts} from '../../utils/languageTexts';
import './ProfilePage.scss';
import {getAvatarUri} from "../../services/Avatars";

export const ProfilePage = () => {
  const {loggedIn, getUser} = useContext(AuthContext);
  const [user, setUser] = useState<User | undefined>(undefined);
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BASE_URL + '/v1/api/resource/';

  const contextLanguage = useSelector((state: any) => state.language);
  const texts =
    languageTexts[contextLanguage.Language as LanguageNames].profileTexts;

  useEffect(() => {
    if (!loggedIn) {
      navigate('/');
    }
  }, [loggedIn, navigate]);

  useEffect(() => {
    setUser(getUser());
  }, [setUser, getUser]);

  const renderUserProfile = () => {
    return (
      <>
        <h1>{texts.title}</h1>
        <div className="Profile__AvatarContainer">
          <img
            draggable={false}
            src={
              user!.avatar
                ? baseURL + user!.avatar
                : getAvatarUri(user!.name)
            }
            alt="profile"
            className="Profile__Avatar"
          />
        </div>
        <div className="Profile__UserStatsContainer">
          <div className="Profile__UserStatsColumn">
            <h3 className="Profile__ItemTitle">{texts.gamesWon}</h3>
            <p>{user!.gamesWon}</p>
          </div>
          <div className="Profile__UserStatsColumn">
            <h3 className="Profile__ItemTitle">{texts.playedGames}</h3>
            <p>{user!.gamesPlayed}</p>
          </div>
          <div className="Profile__UserStatsColumn">
            <h3 className="Profile__ItemTitle">{texts.guessedWords}</h3>
            <p>{user!.wordsGuessed}</p>
          </div>
        </div>
        <div className="Profile__UserLanguage">
          <p>{`Language: ${
            user!.language === 'ES' ? 'Español' : 'English'
          }`}</p>
        </div>
      </>
    );
  };

  return (
    <div className="Profile__Layout">
      <Header />
      <div className="Profile__Container">
        <div className="Profile__Card">
          {user ? (
            renderUserProfile()
          ) : (
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
