import {Box, Modal, TextField} from '@mui/material';
import React, {useContext} from 'react';
import {AuthContext} from '../../context/AuthContext';
import {User} from '../../interfaces/User';
import {LanguageNames, languageTexts} from '../../utils/languageTexts';
import './ProfileModal.scss';
import {LANGUAGES_MAP} from '../../interfaces/Language';
import './ProfileModal.scss';
import {useSelector} from 'react-redux';
import {getCircularAvatarUri} from "../../services/Avatars";

interface ProfileModalProps {
  visible: boolean;
  onClose: () => void;
}

export const ProfileModal = ({visible, onClose}: ProfileModalProps) => {
  const baseURL = process.env.REACT_APP_SERVER_URL + 'v1/api/resource/';

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 470,
    height: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 5,
    p: 0,
    overflow: 'hidden',
  };

  const contextLanguage = useSelector((state: any) => state.language);
  const texts =
    languageTexts[contextLanguage.Language as LanguageNames].profileTexts;
  const {id, user} = useSelector((state: any) => state.user);
  return (
    <Modal open={visible} onClose={onClose}>
      <Box sx={modalStyle}>
        <div className="ProfileModal__Container">
          <div className="ProfileModal__Title"> {texts.title} </div>
          <div className="ProfileModal__Content">
            <div className={'ProfileModal__AvatarContainer'}>
              <img
                draggable={false}
                src={
                  user?.avatar
                    ? baseURL + user.avatar
                    : /*`https://avatars.dicebear.com/api/adventurer/${user ? user.name : id}.svg?flip=true`*/ getCircularAvatarUri(user ? user.name : id)
                }
                alt="Avatar"
                className={'Players__Avatar'}
              />
            </div>
            <div className="ProfileModal__Item">
              <h3>{texts.gamesWon}</h3>
              <p>{user ? user.gamesWon : '-'}</p>
            </div>
            <div className="ProfileModal__Item">
              <h3>{texts.playedGames}</h3>
              <p>{user ? user.gamesPlayed : '-'}</p>
            </div>
            <div className="ProfileModal__Item">
              <h3>{texts.guessedWords}</h3>
              <p>{user ? user.wordsGuessed : '-'}</p>
            </div>
            <div className="ProfileModal__Item">
              <h3>{texts.language}</h3>
              <p>{LANGUAGES_MAP[contextLanguage.Language]}</p>
            </div>
            <div className="ProfileModal__Item">
              <h3>{texts.brokenPhoneGamesPlayed}</h3>
              <p>{user ? user.brokenPhoneGamesPlayed : '-'}</p>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
