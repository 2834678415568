import {
  englishAuthTexts,
  englishBrokenPhoneRoomTexts,
  englishChangeNameTexts,
  englishCreateRoomTexts,
  englishDrawGuessTexts,
  englishEnterPhraseTexts,
  englishGameEndTexts,
  englishGameSelectTexts,
  englishHeaderTexts,
  englishHomeTexts,
  englishPlayerDrawingModalTexts,
  englishProfileTexts,
  englishReportModalTexts,
  englishSecondTabAlertTexts,
} from './languages/englishTexts';
import {
  spanishAuthTexts,
  spanishBrokenPhoneRoomTexts,
  spanishChangeNameTexts,
  spanishCreateRoomTexts,
  spanishDrawGuessTexts,
  spanishEnterPhraseTexts,
  spanishGameEndTexts,
  spanishGameSelectTexts,
  spanishHeaderTexts,
  spanishHomeTexts,
  spanishPlayerDrawingModalTexts,
  spanishProfileTexts,
  spanishReportModalTexts,
  spanishSecondTabAlertTexts,
} from './languages/spanishTexts';

export enum LanguageNames {
  spanish = 'ES',
  english = 'EN',
}

export interface ScreensTexts {
  gameSelectTexts: GameSelectTexts;
  drawGuessRoomTexts: DrawGuessRoomTexts;
  brokenPhoneRoomTexts: BrokenPhoneRoomTexts;
  changeNameTexts: ChangeNameTexts;
  authTexts: AuthTexts;
  homeTexts: HomeTexts;
  createRoomTexts: CreateRoomTexts;
  profileTexts: ProfileTexts;
  headerTexts: HeaderTexts;
  gameEndTexts: GameEndTexts;
  reportModalTexts: ReportModalTexts;
  playerDrawingModalTexts: PlayerDrawingModalTexts;
  secondTabAlertText: SecondTabAlertTexts;
  enterPhraseTexts: EnterPhraseTexts;
}

export interface EnterPhraseTexts {
  writeYourPhrase: string;
  waitingForPlayers: string;
  edit: string;
  done: string;
  random: string;
  round: string;
}

export interface SecondTabAlertTexts {
  title: string;
  description: string;
}

export interface ReportModalTexts {
  reportReasons: string[];
  otherReason: string;
  cancel: string;
  report: string;
  selectReason: string;
  reportPlayer: string;
}

export interface GameEndTexts {
  firstPlace: string;
  secondPlace: string;
  thirdPlace: string;
}

export interface CreateRoomTexts {
  newRoom: string;
  room: string;
  roomNameError: string;
  title: string;
  roomName: string;
  numberOfRounds: string;
  secondsPerRound: string;
  secondsBetweenRounds: string;
  maxPlayers: string;
  secondsAfterFirstGuess: string;
  language: string;
  wordList: string;
  cancelButtonText: string;
  createButtonText: string;
  secondsForInicialPhrase: string;
  secondsForDrawing: string;
  secondsForGuessing: string;
  gameLength: string;
}

export interface ChangeNameTexts {
  titleChangeName: string;
  titleEnterName: string;
  doneButtonText: string;
  placeHolder: string;
  cancelButtonText: string;
}

export interface HeaderTexts {
  guest: string;
  authOpcions: string;
  changeName: string;
  profile: string;
  logout: string;
  disconnectTooltip: string;
}

export interface HomeTexts {
  chatTitle: string;
  roomsTitle: string;
  createTitle: string;
  sendMsg: string;
  game1Title: string;
  game2Title: string;
  errorTitle: string;
  createRoomError: string;
  chatPlaceholder: string;
  chatPlaceholderWhileDrawing: string;
}

export interface AuthTexts {
  titleRegister: string;
  titleLogin: string;
  name: string;
  confirmPassword: string;
  language: string;
  email: string;
  password: string;
  continueAs: string;
  guest: string;
  buttonRegister: string;
  buttonLogin: string;
  avatar: string;
  passwordDescription: string;
  credentialsError: string;
  passwordNotMatch: string;
  usernameRequired: string;
  usernameLength: string;
  passwordRequired: string;
  passwordFormat: string;
  emailRequired: string;
  emailFormat: string;
  emailLength: string;
  userInUse: string;
  invalidToken: string;
}

export interface GameSelectTexts {
  drawGuessSubtitle: string;
  brokenPhoneSubtitle: string;
  playText: string;
}

export interface ProfileTexts {
  titleFriend: string;
  title: string;
  gamesWon: string;
  playedGames: string;
  guessedWords: string;
  language: string;
  brokenPhoneGamesPlayed: string;
}

export interface DrawGuessRoomTexts {
  currentRound: string;
  guess: string;
  skip: string;
  voteForKick: string;
  report: string;
  reported: string;
  waitingForPlayers: string;
  gameStarting: string;
  sureToLeave: string;
  leaveRoom: string;
  cancel: string;
  guessed: string;
  playersTitle: string;
  chatTitle: string;
  kick: string;
  clue: string;
  waitingForNextRound: string;
  confirmationModalTitle: string;
  confirmationModalAcceptText: string;
  confirmationModalAcceptText2: string;
  confirmationModalCancelText: string;
  confrmationModalText: string;
  confrmationModalText2: string;
  gameStartingDescription: string;
  seeProfile: string;
  guest: string;
  closeConfirmation: string;
}

export interface BrokenPhoneRoomTexts {
  waitingForPlayers: string;
  sendMessage: string;
  send: string;
  startGame: string;
  leaveRoom: string;
  cancel: string;
  ready: string;
  edit: string;
  previous: string;
  next: string;
  returnToLobby: string;
  newGame: string;
  round: string;
  lineEnded:string;
  history: string;
}

export interface PlayerDrawingModalTexts {
  description: string;
  yourTurnToDraw: string;
}

export const languageTexts: Record<LanguageNames, ScreensTexts> = {
  [LanguageNames.english]: {
    gameSelectTexts: englishGameSelectTexts,
    drawGuessRoomTexts: englishDrawGuessTexts,
    changeNameTexts: englishChangeNameTexts,
    authTexts: englishAuthTexts,
    homeTexts: englishHomeTexts,
    createRoomTexts: englishCreateRoomTexts,
    profileTexts: englishProfileTexts,
    headerTexts: englishHeaderTexts,
    gameEndTexts: englishGameEndTexts,
    reportModalTexts: englishReportModalTexts,
    playerDrawingModalTexts: englishPlayerDrawingModalTexts,
    secondTabAlertText: englishSecondTabAlertTexts,
    brokenPhoneRoomTexts: englishBrokenPhoneRoomTexts,
    enterPhraseTexts: englishEnterPhraseTexts
  },
  [LanguageNames.spanish]: {
    gameSelectTexts: spanishGameSelectTexts,
    drawGuessRoomTexts: spanishDrawGuessTexts,
    changeNameTexts: spanishChangeNameTexts,
    authTexts: spanishAuthTexts,
    homeTexts: spanishHomeTexts,
    createRoomTexts: spanishCreateRoomTexts,
    profileTexts: spanishProfileTexts,
    headerTexts: spanishHeaderTexts,
    gameEndTexts: spanishGameEndTexts,
    reportModalTexts: spanishReportModalTexts,
    playerDrawingModalTexts: spanishPlayerDrawingModalTexts,
    secondTabAlertText: spanishSecondTabAlertTexts,
    brokenPhoneRoomTexts: spanishBrokenPhoneRoomTexts,
    enterPhraseTexts: spanishEnterPhraseTexts
  },
};
