import {Box, Slide, Zoom} from '@mui/material';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {CirclePicker, ColorResult} from 'react-color';
import {Canvas, Point, CanvasPath, CanvasRef} from 'react-sketch-canvas';
import {SocketContext} from '../../../context/SocketContext';
import AutoFixOffIcon from '@mui/icons-material/AutoFixOff';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import './MyCanvas.scss';
import {BrushPicker} from '../../BrushPicker/BrushPicker';
import {useInterval} from 'react-use';
import {useResize} from '../../../hooks/useResize';
import {MyCanvasRef} from '../Canvas';
interface CanvasProps {
  disabled: boolean;
  canvasColor: string;
  strokeWidth: number;
  strokeColor: string;
  isDrawing: boolean;
  gameEnd: boolean;
  intermission: boolean;
  started: boolean;
  setFadeToolbar?: (b:boolean) => void;
  setFadeButtonBar?: (b:boolean) => void;
  fadeToolbar?: boolean;
  children?: any;
}

export interface CustomCanvasRef {
  getLastPath: () => MyCanvasPath | undefined;
  resetCanvas: () => void;
  getUpdateToSend: () => CanvasPath[];
  loadPaths: (paths: MyCanvasPath[]) => void;
  exportPaths: () => MyCanvasPath[];
  loadLastPath: (path: MyCanvasPath) => void;
  exportCanvasSize: () => {width: number; height: number};
  resetTurn: () => void;
  drewThisTurn: () => boolean;
}

export interface MyCanvasPath extends CanvasPath {
  id: number;
}

export const CustomCanvas = React.forwardRef<CustomCanvasRef, CanvasProps>(
  (props, ref) => {
    const {
      disabled,
      canvasColor,
      strokeWidth,
      strokeColor,
      isDrawing,
      gameEnd,
      intermission,
      started,
      fadeToolbar,
      setFadeToolbar,
      setFadeButtonBar,
    } = props;
    const [paths, setPaths] = React.useState<MyCanvasPath[]>([]);
    const pathsRef = React.useRef<MyCanvasPath[]>([]);
    const [drawing, setDrawing] = React.useState<boolean>(false);
    const updateToSend = React.useRef<CanvasPath[]>([]);
    const [brushPos, setBrushPos] = React.useState<Point>({x: 0, y: 0});
    const [isErasing, setIsErasing] = React.useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [pencilVisible, setPencilVisible] = React.useState<boolean>(false);
    const visibleTimeout = React.useRef<NodeJS.Timeout | null>(null);
    const context = useContext(SocketContext);
    const {resize} = useResize();
    const [oldCanvasSize, setOldCanvasSize] = React.useState({
      width: 0,
      height: 0,
    });
    const [drewThisTurn, setdrewThisTurn] = useState(false);

    useEffect(() => {
      if (paths.length > 0) {
        setdrewThisTurn(true);
      }
    }, [paths]);

    useEffect(() => {
      if (context.socket) {
        context.socket.on('start-path', path => {
          if (!isDrawing) {
            // console.log("start-path", path);
            ['#fefffe', 'white'].includes(path.strokeColor)
              ? setIsErasing(true)
              : setIsErasing(false);
            path.paths.at(0)!.x =
              path.paths.at(0)!.x * (containerRef.current?.clientWidth || 1);
            path.paths.at(0)!.y =
              path.paths.at(0)!.y * (containerRef.current?.clientHeight || 1);
            setBrushPos(path.paths.at(0)!);
            // setDrawing(true);
            setPaths(paths => [...paths, path]);
            pathsRef.current = [...pathsRef.current, path];
            clearTimeout(visibleTimeout.current || undefined);
            visibleTimeout.current = null;
            setPencilVisible(true);
          }
        });

        context.socket.on('add-point', point => {
          if (!isDrawing) {
            point.x = point.x * (containerRef.current?.clientWidth || 1);
            point.y = point.y * (containerRef.current?.clientHeight || 1);
            setBrushPos(point);
            // const newPaths = [...paths];
            const newPaths = [...pathsRef.current];
            const lastPath = newPaths.at(-1);
            lastPath?.paths.push(point);
            clearTimeout(visibleTimeout.current || undefined);
            visibleTimeout.current = null;
            setPaths(newPaths);
            pathsRef.current = newPaths;
          }
        });

        context.socket.on('end-path', () => {
          // setDrawing(false);
          if (!visibleTimeout.current) {
            visibleTimeout.current = setTimeout(() => {
              // console.log("Executing timeout");
              setPencilVisible(false);
            }, 1000);
          }
        });

      }

      return () => {
        context.socket?.off('start-path');
        context.socket?.off('add-point');
        context.socket?.off('end-path');
      };
    }, [context.socket, isDrawing]);

    React.useEffect(() => {
      if (containerRef.current) {
        const scaledPaths: MyCanvasPath[] = paths.map((each: MyCanvasPath) => ({
          ...each,
          paths: each.paths.map(path => ({
            x:
              path.x *
              (containerRef.current!.clientWidth / oldCanvasSize.width || 1),
            y:
              path.y *
              (containerRef.current!.clientHeight / oldCanvasSize.height || 1),
          })),
        }));
        setPaths(scaledPaths);
        pathsRef.current = scaledPaths;
        const newSize = {
          width: containerRef.current!.offsetWidth,
          height: containerRef.current!.offsetHeight,
        };

        setOldCanvasSize(newSize);
      }
    }, [resize]);

    React.useEffect(() => {
      if (containerRef) {
        const size = {
          width: containerRef.current!.offsetWidth,
          height: containerRef.current!.offsetHeight,
        };
        setOldCanvasSize(size);
      }
    }, []);

    const previousPointTimeMs = useRef<number>();
    const previousPoint = useRef<Point>();

    const onPointerDown = (point: Point) => {
      previousPointTimeMs.current = Date.now();
      previousPoint.current = point;
      if (disabled) {
        return;
      }
      // console.log("Pointer down");
      setDrawing(true);
      const newPath: MyCanvasPath = {
        id: paths.length,
        paths: [point],
        strokeColor: strokeColor,
        strokeWidth: strokeWidth,
        drawMode: true,
      };
      setPaths(paths => [...paths, newPath]);
      pathsRef.current = [...pathsRef.current, newPath];
      const emitPath = {
        ...newPath,
        paths: [
          {
            x: point.x / (containerRef.current?.offsetWidth || 1),
            y: point.y / (containerRef.current?.offsetHeight || 1),
          },
        ],
      };
      context.socket?.emit('start-path', emitPath);
    };

    const onPointerDownErase = (point: Point) => {
      previousPointTimeMs.current = Date.now();
      previousPoint.current = point;
      if (disabled) {
        return;
      }
      // console.log("Pointer down");
      setDrawing(true);
      const newPath: MyCanvasPath = {
        id: paths.length,
        paths: [point],
        strokeColor: 'white',
        strokeWidth: 40,
        drawMode: true,
      };
      setPaths(paths => [...paths, newPath]);
      pathsRef.current = [...pathsRef.current, newPath];
      const emitPath = {
        ...newPath,
        paths: [
          {
            x: point.x / (containerRef.current?.offsetWidth || 1),
            y: point.y / (containerRef.current?.offsetHeight || 1),
          },
        ],
      };
      context.socket?.emit('start-path', emitPath);
    };

    const distanceBetweenPoints = (pointA: Point, pointB: Point) => {
      return Math.sqrt((pointB.x - pointA.x) ** 2 + (pointB.y - pointA.y) ** 2);
    };

    const onPointerMove = (point: Point) => {
      if (previousPointTimeMs.current && previousPoint.current) {
        const distance = distanceBetweenPoints(previousPoint.current, point);
        const timePassedMs = Date.now() - previousPointTimeMs.current;
        if (timePassedMs > 100 && distance > 5) {
          // console.log("Time passed", timePassedMs);
          onPointerUp({stopDrawing: false});
          onPointerDown(point);
          return;
        }
      }
      previousPoint.current = point;
      previousPointTimeMs.current = Date.now();
      if (disabled) {
        return;
      }
      if (drawing) {
        const newPaths = [...paths];
        // const newPaths = [...paths.current];
        const lastPath = newPaths.at(-1);
        lastPath?.paths.push(point);
        setPaths(newPaths);
        pathsRef.current = newPaths;
        context.socket?.emit('add-point', {
          x: point.x / (containerRef.current?.offsetWidth || 1),
          y: point.y / (containerRef.current?.offsetHeight || 1),
        });

        if (paths[paths.length - 1].paths.at(-1)!.y < 55) {
          setFadeToolbar!(false);
        } else {
          setFadeToolbar!(true);
        }
        const fadeParameter = containerRef.current?.offsetHeight
          ? containerRef.current?.offsetHeight! - 90
          : 510;
        if (paths[paths.length - 1].paths.at(-1)!.y > fadeParameter) {
          setFadeButtonBar!(false);
        } else {
          setFadeButtonBar!(true);
        }
      }
    };

    const onPointerMoveErase = (point: Point) => {
      if (previousPointTimeMs.current && previousPoint.current) {
        const distance = distanceBetweenPoints(previousPoint.current, point);
        const timePassedMs = Date.now() - previousPointTimeMs.current;
        if (timePassedMs > 100 && distance > 5) {
          // console.log("Time passed", timePassedMs);
          onPointerUp({stopDrawing: false});
          onPointerDownErase(point);
          return;
        }
      }
      previousPoint.current = point;
      previousPointTimeMs.current = Date.now();
      if (disabled) {
        return;
      }
      if (drawing) {
        const newPaths = [...paths];
        const lastPath = newPaths.at(-1);
        lastPath?.paths.push(point);
        setPaths(newPaths);
        pathsRef.current = newPaths;
        context.socket?.emit('add-point', {
          x: point.x / (containerRef.current?.offsetWidth || 1),
          y: point.y / (containerRef.current?.offsetHeight || 1),
        });

        if (paths[paths.length - 1].paths.at(-1)!.y < 55) {
          setFadeToolbar!(false);
        } else {
          setFadeToolbar!(true);
        }
        const fadeParameter = containerRef.current?.offsetHeight
          ? containerRef.current?.offsetHeight! - 90
          : 510;
        if (paths[paths.length - 1].paths.at(-1)!.y > fadeParameter) {
          setFadeButtonBar!(false);
        } else {
          setFadeButtonBar!(true);
        }
      }
    };

    const onPointerUp = (params?: {stopDrawing?: boolean}) => {
      // console.log("Pointer up", params);
      if (params?.stopDrawing || params?.stopDrawing === undefined) {
        setDrawing(false);
        setFadeToolbar!(true);
        setFadeButtonBar!(true);
      }
      context.socket?.emit('end-path');
      // console.log("FADEIN")
    };

    React.useImperativeHandle(ref, () => ({
      getLastPath: () => {
        return pathsRef.current.at(-1);
      },
      resetCanvas: () => {
        pathsRef.current = [];
        setPaths([]);
      },
      getUpdateToSend: () => {
        const toSend = updateToSend.current;
        updateToSend.current = [];
        return toSend;
      },
      loadPaths: (newPaths: MyCanvasPath[]) => {
        pathsRef.current = newPaths;
        setPaths(newPaths);
        onPointerUp();
      },
      exportPaths: () => {
        return pathsRef.current.map(path => {
          return {
            ...path,
            paths: path.paths.map(point => ({
              x: point.x / (containerRef.current?.offsetWidth || 1),
              y: point.y / (containerRef.current?.offsetHeight || 1),
            })),
          };
        });
      },
      loadLastPath: (path: MyCanvasPath) => {
        const lastPath = pathsRef.current.at(-1);
        if (lastPath && lastPath.id === path.id) {
          // setPaths(paths => [
          //   ...paths.filter((_, index) => index !== paths.length - 1),
          //   path,
          // ]);
          pathsRef.current = [
            ...pathsRef.current.filter(
              (_, index) => index !== pathsRef.current.length - 1,
              path,
            ),
          ];
        } else {
          // setPaths([...paths, path]);
          pathsRef.current = [...pathsRef.current, path];
        }
      },
      exportCanvasSize: () => {
        return {
          width: containerRef.current?.offsetWidth!,
          height: containerRef.current?.offsetHeight!,
        };
      },
      resetTurn() {
        setdrewThisTurn(false);
      },
      drewThisTurn() {
        return drewThisTurn;
      },
    }));

    const getRotation = (): string => {
      const percent =
        (brushPos.y / (containerRef.current?.clientHeight || 1)) * 100;
      const amountRotation = (percent * 80) / 100;
      return `${amountRotation + 45 + (isErasing ? 180 : 0)}deg`;
    };

    const eraseRightButton = useRef(false);

    const checkRightButton = (e: any) => {
      if (e.nativeEvent.button === 2) {
        eraseRightButton.current = true;
        setIsErasing(true);
        onPointerDownErase({
          x: e.nativeEvent.offsetX,
          y: e.nativeEvent.offsetY,
        });
      }
    };

    const handleMove = (e: any) => {
      if (eraseRightButton.current) {
        setIsErasing(true);
        onPointerMoveErase({
          x: e.nativeEvent.offsetX,
          y: e.nativeEvent.offsetY,
        });
      }
    };

    const handleUp = () => {
      if (eraseRightButton.current) {
        onPointerUp();
        eraseRightButton.current = false;
        setIsErasing(false);
      }
    };

    return (
      <div
        onPointerDown={checkRightButton}
        onPointerMove={handleMove}
        onPointerUp={handleUp}
        style={{
          width: '100%',
          aspectRatio: '4 / 3',
          // minWidth: '552px',
          // minHeight: '414px',
        }}
        ref={ref => (containerRef.current = ref)}>
        {props.children}
        <Canvas
          paths={paths}
          onPointerDown={p => {
            if (!eraseRightButton.current) {
              onPointerDown(p);
            }
          }}
          onPointerMove={p => {
            if (!eraseRightButton.current) {
              onPointerMove(p);
            }
          }}
          onPointerUp={() => {
            if (!eraseRightButton.current) {
              onPointerUp();
            }
          }}
          isDrawing={drawing}
          width="100%"
          height="100%"
          canvasColor={canvasColor}
          backgroundImage=""
          exportWithBackgroundImage={false}
          preserveBackgroundImageAspectRatio="none"
          allowOnlyPointerType="all"
          style={{width: '100%', maxHeight: '100%'}}
          svgStyle={{height: '100%'}}
        />
        <img
          draggable={false}
          style={{
            display:
              isDrawing || gameEnd || intermission || !started
                ? 'none'
                : 'block',
            position: 'absolute',
            left: `${brushPos.x}px`,
            top: `${brushPos.y}px`,
            height: '200px',
            width: '20px',
            transformOrigin: !isErasing ? '10px 200px' : '10px 0px',
            transform: `translate(-10px, ${
              isErasing ? '65px' : '-130px'
            }) rotate(${getRotation()})`,
          }}
          className={pencilVisible ? 'fadeIn' : 'fadeOut'}
          src="/images/pencil.png"
          alt="pencil"></img>
      </div>
    );
  },
);
