import drawApi from "../api/drawApi";
import { Language } from "../interfaces/Language";

export const getLanguages = async () => {
    const response = await drawApi.get("v1/api/language");
    if (response.data.status !== "success") {
        return [];
    }
    return response.data.data.languages as Language[];
};
