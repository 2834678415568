import {Box, Modal, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import './DownloadAppModal.scss';

export const DownloadAppModal = ({show}: {show: boolean}) => {
  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={show}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Download the app
        </Typography>
        <Typography id="modal-modal-description" sx={{mt: 2}}>
          Download Drawbotic from the Google Play Store or the iOS App Store
        </Typography>
        <div className="DownloadAppModal__Badges">
          <a href="https://play.google.com/store/apps/details?id=io.gartic.Gartic&hl=es_419&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              draggable={false}
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            />
          </a>
          <a href="https://apps.apple.com/us/app/gartic-io/id1270393677?itsct=apps_box_badge&amp;itscg=30200">
            <img
              draggable={false}
              id="iOs"
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1502928000&h=d1875f098f0981caa488ed64001c8985"
              alt="Download on the App Store"
            />
          </a>
        </div>
      </Box>
    </Modal>
  );
};
