import TextField from '@mui/material/TextField';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Message} from '../../interfaces/Message';
import DOMPurify from 'dompurify';
import './Chat.scss';
import {LanguageNames, languageTexts} from '../../utils/languageTexts';
import {useSelector} from 'react-redux';
import {ChatMessage} from './ChatMessage';

interface ChatProps {
  messages: Message[];
  sendMessage: (message: String) => void;
  disabled: boolean;
  style?: string;
}

const moveCursorToEnd = (el: any) => {
  window.setTimeout(function () {
    if (typeof el.selectionStart == 'number') {
      el.selectionStart = el.selectionEnd = el.value.length;
    } else if (typeof el.createTextRange != 'undefined') {
      var range = el.createTextRange();
      range.collapse(false);
      range.select();
    }
  }, 1);
};

export const Chat = ({messages, sendMessage, disabled, style = ''}: ChatProps) => {
  const [text, setText] = React.useState<String>('');
  const [lastMessages, setLastMessages] = React.useState<String[]>([]);
  const lastMessageIndex = useRef<number>(-1);
  const textRef = useRef<HTMLInputElement>();
  const refChatContainer = useRef<any>(null);
  const refMessages = useRef<any>(null);
  const [disabledAutoScroll, setDisabledAutoScroll] = useState(false)

  const contextLanguage = useSelector((state: any) => state.language);
  const texts =
    languageTexts[contextLanguage.Language as LanguageNames].homeTexts;

  return (
    <div className={`App__ChatContainer${style}`}>
      <div className={ style === 'Lobby' ? "App__ChatHeaderWelcomePage" : "App__ChatHeader"}>
        <p>{texts.chatTitle}</p>
      </div>
      <div className="App_ChatMessages" ref={refChatContainer} onScroll={(ev: any) => {
        const topFirstDiv: number = refChatContainer?.current.clientHeight || 0;
        const topSecondDiv: number = refMessages?.current.clientHeight || 0;
        const scroll: number = ev.target.scrollTop;
        setDisabledAutoScroll(topSecondDiv - topFirstDiv >= scroll + 20);

      }}>
        <div className="Chat__MessagesContainer" ref={refMessages}>
          {messages.map((msg, index) => <ChatMessage key={msg + index.toString()} message={msg} i={index} />)}
        </div>
        { !disabledAutoScroll && <ScrollToBottom/> }
      </div>
      <div className="App_SendMessage">
        <TextField
          style={{
            backgroundColor: disabled ? '#f8f8ff' : '#ffffff',
            borderRadius: '30px',
          }}
          variant="outlined"
          fullWidth
          multiline={true}
          maxRows={2}
          placeholder={
            disabled ? texts.chatPlaceholderWhileDrawing : texts.chatPlaceholder
          }
          margin="dense"
          onChange={event => setText(event.target.value)}
          value={text.replace(/(\r\n|\n|\r)/gm, '')}
          autoFocus
          disabled={disabled}
          inputProps={style === 'Lobby' ? {maxLength: 200} : {maxLength: 100}}
          inputRef={textRef}
          autoComplete={'off'}
          InputProps={{
            style: {
              borderRadius: '30px',
              paddingLeft: 20,
            },
          }}
          onKeyDown={ev => {
            if (ev.key === 'Enter') {
              sendMessage(DOMPurify.sanitize(text.toString()));
              const newLastMessage = [...lastMessages];
              newLastMessage.reverse();
              newLastMessage.push(text);
              const last5 = newLastMessage.slice(-5);
              setLastMessages(last5.reverse());
              lastMessageIndex.current = -1;
              setText('');
            } else if (ev.key === 'ArrowUp') {
              lastMessageIndex.current++;
              if (lastMessageIndex.current > lastMessages.length - 1) {
                lastMessageIndex.current = 0;
              }

              const messageToAdd = lastMessages.at(lastMessageIndex.current);

              if (messageToAdd) {
                setText(messageToAdd);
              }

              if (textRef.current) {
                moveCursorToEnd(textRef.current);
              }
            } else if (ev.key === 'ArrowDown') {
              lastMessageIndex.current--;
              if (lastMessageIndex.current < -1) {
                lastMessageIndex.current = 4;
                console.log('message: ', lastMessages);
              }
              console.log('index ', lastMessageIndex.current);

              if (lastMessageIndex.current === -1 && textRef.current) {
                setText('');
                return;
              }
              const messageToAdd = lastMessages.at(
                lastMessageIndex.current % lastMessages.length,
              );
              console.log(
                'message in index ',
                lastMessages.at(lastMessageIndex.current % lastMessages.length),
              );
              if (messageToAdd) {
                setText(messageToAdd);
              }

              if (textRef.current) {
                moveCursorToEnd(textRef.current);
              }
            }
          }}
        />
      </div>
    </div>
  );
}

const ScrollToBottom = () => {
  const elementRef = useRef<any>();
  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView();
    }
  });
  return <div ref={elementRef}></div>;
};
