import {AxiosError} from 'axios';
import React, {useCallback, useContext, useEffect, useReducer} from 'react';
import {User} from '../interfaces/User';
import {login, signup, logout} from '../services/AuthService';
import {getUserById, updateUser} from '../services/UserService';
import {SocketContext} from './SocketContext';
import {ConfirmationModal} from '../components/ConfirmationModal/ConfirmationModal';
import {Language, LANGUAGES_MAP} from '../interfaces/Language';
import StateManagedSelect from 'react-select/dist/declarations/src/stateManager';
import {initialState, userReducer, userState} from '../store/userReducer';
import {useSearchParam} from 'react-use';
import {useDispatch, useSelector} from 'react-redux';
import { LanguageNames, languageTexts } from '../utils/languageTexts';

interface AuthContextProps {
  state: userState;
  loggedIn: boolean;
  login: (username: string, password: string) => Promise<boolean>;
  signup: (
    email: string,
    username: string,
    password: string,
    language: string,
    avatar?: File,
  ) => Promise<string>;
  Logout: () => void;
  getUser: () => User | undefined;
  updateUser: (userName?: string, language?: string, audio?: boolean) => void;
  refreshUser: () => Promise<User>;
  getBrowserLanguage: () => string;
  showLoginModal: boolean;
  setShowLoginModal: (show: boolean) => void;
  sendNewName: (newName: string) => void;
  changeId: (newId: string) => void;
}

export const AuthContext = React.createContext({} as AuthContextProps);

export const AuthProvider = ({children}: any) => {
  const [loggedIn, setLoggedIn] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = React.useState<boolean>(false);
  const {authenticate, onConnected, socket} = useContext(SocketContext);
  const authState = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const contextLanguage = useSelector((state: any) => state.language);
  const texts = languageTexts[contextLanguage.Language as LanguageNames].authTexts;

  const Login = async (username: string, password: string) => {
    return await login(username, password)
      .then((response: any) => {
        if (response.status === 200) {
          setLoggedIn(true);
          const token = response.data.data.session.token;
          const success = authenticate(token);
          if (!success) {
            return false;
          }
          localStorage.setItem('token', token);
          const user = response.data.data.user;
          dispatch({
            type: 'changeUser',
            payload: {user: user},
          });
          // window.location.reload();
          return true;
        } else {
          console.log('Not good login: ' + response.status);
          return false;
        }
      })
      .catch((error: AxiosError) => {
        console.log('Not good login: ' + error.message);
        return false;
      });
  };

  const Signup = async (
    email: string,
    username: string,
    password: string,
    language: string,
    avatar?: File,
  ) => {
    return await signup(email, username, password, language, avatar)
      .then((response: any) => {
        if (response.status === 200) {
          setLoggedIn(true);
          const token = response.data.data.session.token;
          const success = authenticate(token);
          if (!success) {
            return texts.invalidToken;
          }
          localStorage.setItem('token', token);
          const user = response.data.data.user;
          dispatch({
            type: 'changeUser',
            payload: {user: user},
          });
          return "Ok";
        } else {
          return "Error"
        }
      })
      .catch((error: AxiosError) => {
        console.log('Not good signup: ' + error.message);
        return error.message;
      });
  };

  const UpdateUser = async (
    userName?: string,
    language?: string,
    audio?: boolean,
  ) => {
    const token = localStorage.getItem('token') || '';
    const response = await updateUser(token, userName, language, audio);
    if (response.status === 200) {
      const user = response.data.data.user;
      dispatch({
        type: 'changeUser',
        payload: {user: user},
      });
      return true;
    } else {
      return false;
    }
  };

  const refreshUser = async (): Promise<User> => {
    const token = localStorage.getItem('token') || '';
    const user = await getUser();
    if (user?.id) {
      const response = await getUserById(token, user.id);
      dispatch({
        type: 'changeUser',
        payload: {user: response},
      });
      return response;
    }
    throw new Error('User not found');
  };

  const Logout = async () => {
    setLoggedIn(false);
    const token = localStorage.getItem('token') || '';
    await logout(token);
    localStorage.removeItem('token');
    dispatch({type: 'deleteUser'});
    sendSavedIDOrDelete();
    // window.location.reload();
  };

  const getUser = () => {
    const user = authState.user;
    if (user) {
      const userStr = JSON.stringify(user);
      return JSON.parse(userStr);
    }
    return undefined;
  };

  const getBrowserLanguage = () => {
    const browserLanguage = navigator.language.slice(0, 2).toUpperCase();
    if (!Object.keys(LANGUAGES_MAP).includes(browserLanguage)) return 'EN';
    return browserLanguage;
  };

  const authenticateToSocket = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const authSuccessful = await authenticate(token);
        if (authSuccessful) {
          setLoggedIn(true);
        } else {
          console.log('Auth not successful: ' + authSuccessful);
          setLoggedIn(false);
          localStorage.removeItem('token');
          dispatch({type: 'deleteUser'});
          sendSavedIDOrDelete();
        }
      } catch (error) {
        console.log('Not good login: ' + error);
        setShowModal(true);
        setLoggedIn(false);
        localStorage.removeItem('token');
        dispatch({type: 'deleteUser'});
        sendSavedIDOrDelete();
      }
    } else if (authState.id) {
      socket?.emit('change-name', authState.id);
    }
  }, [authenticate]);

  useEffect(() => {
    return onConnected(authenticateToSocket);
  }, [onConnected, authenticateToSocket]);

  const sendNewName = (newName: string) => {
    if (socket) {
      socket.emit('change-name', newName);
    }
  };

  const changeId = (newId: string) => {
    dispatch({type: 'changeId', payload: {id: newId}});
  };

  const sendSavedIDOrDelete = () => {
    const savedID = authState.id;
    if (savedID) {
      sendNewName(savedID);
    } else {
      dispatch({type: 'removeId'});
    }
  };

  return (
    <AuthContext.Provider
      value={{
        state: authState,
        loggedIn: loggedIn,
        login: Login,
        signup: Signup,
        Logout: Logout,
        getUser,
        updateUser: UpdateUser,
        refreshUser,
        getBrowserLanguage,
        showLoginModal,
        setShowLoginModal,
        sendNewName,
        changeId,
      }}>
      {children}
      <ConfirmationModal
        onAccept={() => {
          setShowModal(false);
          setShowLoginModal(true);
        }}
        onClose={() => {
          setShowModal(false);
        }}
        visible={showModal}
        title={'Session expired'}
        message={'Your session has expired. Please login again.'}
        acceptText={'Go to Login'}
        showFirstButton={false}
      />
    </AuthContext.Provider>
  );
};
