import React, { useContext, useEffect, useRef } from 'react';
import { useLocalStorage } from 'react-use';
import { SocketContext } from '../../../context/SocketContext';
import { useResize } from '../../../hooks/useResize';
import { Player } from '../../../interfaces/Player';
import { GameEndModal } from '../../GameEndModal/GameEndModal';
import { Canvas, MyCanvasRef } from '../Canvas';
import { MyCanvasPath } from '../CustomCanvas/MyCanvas';

interface BrokenPhoneCanvasProps {
    players: Player[];
    roundNumberRef: number;
    wordToGuess: string;
    timeRemaining: number;
    historyId: number;
    lineId: number;
    containerRef: HTMLDivElement | null;
    paths: MyCanvasPath[];
    setWordToGuess: (p: any) => void;
    setLineId: (p: any) => void;
    setHistoryId: (p: any) => void;
    setPaths: (p: any) => void;
}

export const BrokenPhoneCanvas = (
    { players,
        roundNumberRef,
        wordToGuess,
        timeRemaining,
        historyId,
        lineId,
        containerRef,
        paths,
        setWordToGuess,
        setLineId,
        setHistoryId,
        setPaths
    }: BrokenPhoneCanvasProps) => {
    const context = useContext(SocketContext);
    const canvasElementRef = useRef<MyCanvasRef | null>(null);
    const [playersReady, setPlayersReady] = React.useState<number>(0);
    const [drawingReady, setDrawingReady] = React.useState<boolean>(false);
    const [intermission, setIntermission] = React.useState<boolean>(false);
    const [gameAudio, setGameAudio] = useLocalStorage('gameAudio', true);
    const timer = useRef<NodeJS.Timer | undefined>();
    const [isDrawing, setIsDrawing] = React.useState<boolean>(true);
    const [oldCanvasSize, setOldCanvasSize] = React.useState({
        width: 0,
        height: 0,
      });
      const { resize } = useResize();

    useEffect(() => {
        if (context.socket) {

            context.socket.on('round-status', status => {
                console.log("Status: ", status);
                setPlayersReady(status.playersReady);
                // setMaxPlayers(status.playersTotal);
            });

            context.socket.on('drawing-info', args => {
                setWordToGuess(args.wordToDraw);
                setLineId(args.lineId);
                setHistoryId(args.historyId);
                timer.current = setTimeout(() => {
                    const paths = canvasElementRef.current?.getPaths();
                    // console.log('PATHS');
                    // console.log(paths);
                    if (paths !== undefined) {
                        // console.log('Calling callback');
                        context.socket?.emit('add-draw-data', {
                            drawData: paths,
                            lineId: args.lineId,
                            historyId: args.historyId,
                        });
                    }
                }, args.duration * 1000);
            });

            context.socket.on('clear-drawing', () => {
                setPaths([]);
            });

            return () => {
                context.socket?.off('round-status');
                context.socket?.off('drawing-info');
                context.socket?.off('clear-drawing');
            };
        }
    }, [context, drawingReady]);

    useEffect(() => {
        if (containerRef) {
          console.log("OLD CANVAS SIZE: ", oldCanvasSize)
          console.log("CURRENT SIZE: ", containerRef!.clientWidth, containerRef!.clientHeight);
          console.log("operation value width: ", ((oldCanvasSize.width && oldCanvasSize.width !== 0 ? containerRef!.clientWidth / oldCanvasSize.width : 1)));
          const scaledPaths: MyCanvasPath[] = paths.map(
            (each: MyCanvasPath) => ({
              ...each,
              paths: each.paths.map(path => ({
                x: path.x * ((oldCanvasSize.width && oldCanvasSize.width !== 0 ? containerRef!.clientWidth / oldCanvasSize.width : 1)),
                y: path.y * ((oldCanvasSize.height && oldCanvasSize.height !== 0 ? containerRef!.clientHeight / oldCanvasSize.height : 1)),
              })),
            }),
          );
          setPaths(scaledPaths);
            const newSize = {
              width: containerRef!.clientWidth,
              height: containerRef!.clientHeight,
            };
    
            setOldCanvasSize(newSize);
        }
      }, [resize]);

    const onClick = () => {
        if (context.socket) {
            if (!drawingReady) {
                const paths = canvasElementRef.current?.getPaths();
                const args: any = { drawData: paths, lineId: lineId, historyId: historyId }
                context.socket.emit('add-draw-data', args);
                context.socket.emit("player-ready");
                setDrawingReady(true);
                setIsDrawing(false);
            } else {
                setDrawingReady(false);
                setIsDrawing(true);
                context.socket.emit('drawing-not-ready');
            }
        }
    };

    const onClear = () => {
        if (context.socket) {
            context.socket.emit('clear-drawing');
        }
    };

    return (
        <Canvas
            ref={ref => (canvasElementRef.current = ref)}
            roundNumber={roundNumberRef}
            drawData={""}
            onDrawChange={() => { }}
            isDrawing={isDrawing}
            wordToGuess={wordToGuess}
            timeRemaining={timeRemaining}
            newTime={timeRemaining}
            maxTime={timeRemaining}
            totalRounds={players.length}
            onGiveTurn={() => null}
            onGiveClue={() => null}
            intermission={intermission}
            clueAmount={0}
            gameEnd={false}
            playerGuessed={false}
            gameEndModalComponent={<></>}
            onClear={onClear}
            someoneGuessed={false}
            showExtraButtons={true}
            gameAudio={gameAudio || false}
            setGameAudio={setGameAudio}
            onReport={() => null}
            players={players.length}
            brokenPhone={true}
            playersReady={playersReady}
            drawingReady={drawingReady}
            onSubmit={onClick}
        />
    );
}