import { Button } from '@mui/material';
import React, {useCallback, useEffect, useRef} from 'react';
import { SocketContext } from '../../context/SocketContext';
import { DrawHistory, HistoryItem } from '../../interfaces/DrawHistory';
import DownloadIcon from '@mui/icons-material/Download';
import './GameEndRound.scss';
import { CanvasPreview } from '../Canvas/CanvasPreview/CanvasPreview';
import { LanguageNames, languageTexts } from '../../utils/languageTexts';
import { useSelector } from 'react-redux';
import { DrawElement, useCreateGif } from '../../hooks/useCreateGif';
import { CanvasRef, ExportImageType } from 'react-sketch-canvas';
import {getAvatarUri, getCircularAvatarUri} from "../../services/Avatars";

interface GameEndRoundProps {
  drawHistory: DrawHistory[];
  owner: boolean;
  setGameEnd: (p:boolean) => void;
}

export const GameEndRound = ({ drawHistory, owner, setGameEnd }: GameEndRoundProps) => {
  const [lineId, setLineId] = React.useState(0);
  const [historyId, setHistoryId] = React.useState(0);
  const [showGuess, setShowGuess] = React.useState(false);
  const [showDrawing, setShowDrawing] = React.useState(false);
  const [paused, setPaused] = React.useState(false);
  const [drawElements, setDrawElements] = React.useState<DrawElement[]>([]);
  const context = React.useContext(SocketContext);
  const contextLanguage = useSelector((state: any) => state.language);
  const timeOutRef = React.useRef<NodeJS.Timer | undefined>(undefined);
  const textsBroken = languageTexts[contextLanguage.Language as LanguageNames].brokenPhoneRoomTexts;
  const [myIndex, setMyIndex] = React.useState(0);
  const [showNextButton, setShowNextButton] = React.useState<boolean>(false);

  const addDrawElement = useCallback((drawElement: DrawElement) => {
    // delete drawElement from drawElements with same index
    setDrawElements(prev => prev.filter((el) => el.index !== drawElement.index));
    setDrawElements(prev => [...prev, drawElement]);
  }, [setDrawElements]);

  const onShowNextManual = () => {
    if (context.socket) {
      context.socket.emit('show-next', { manual: true });
      context.socket.emit('load-draw-elements', drawElements);
      setPaused(false);
      setShowNextButton(false);
      setDrawElements([]);
    }
  };

  const onShowNext = () => {
    if (context.socket) {
      context.socket.emit('show-next');
    }
  };

  React.useEffect(() => {
    console.log("DRAW HISTORY: ", drawHistory);
  }, [drawHistory]);

  React.useEffect(() => {
    if (context.socket) {
      context.socket.on('show-next', (args) => {
        const currentLine = drawHistory.at(lineId);
        if (!currentLine) {
          return;
        }

        const currentHistoryItem = currentLine.history[historyId];

        if (currentHistoryItem && !showDrawing) {
          console.log("Mostrar DIBUJO")
          setMyIndex(prev => prev + 2);
          setShowDrawing(true);
          return;
        }

        if (currentHistoryItem && currentHistoryItem.guess && !showGuess) {
          console.log("Mostrar FRASE")
          setShowGuess(true);
          return;
        }

        const nextHistory = currentLine.history[historyId + 1];

        //Pauses the time interval when the history ends forcing a manual button press to continue to the next line
        if (!nextHistory && !args.manual) {
          clearInterval(timeOutRef.current);
          setShowNextButton(true);
          setPaused(true);
          console.log("Timer con continuar automatico fue pausado")
          return;
        }

        if (!nextHistory) {
          const nextLine = drawHistory.at(lineId + 1);

          if(!nextLine){
            setGameEnd(true);
          }
          //loadDrawWlements
          if (nextLine && args.manual) {
            setLineId(lineId => lineId + 1);
            setShowGuess(false);
            setShowDrawing(false);
            setMyIndex(0);
            setHistoryId(0);
          }

        } else {
          setShowGuess(false);
          setShowDrawing(false);
          setHistoryId(historyId => historyId + 1);
        }
      });
    }

    return () => {
      context.socket?.off('show-next');
    };
  }, [context.socket, lineId, historyId, showGuess, drawHistory, showDrawing]);

  React.useEffect(() => {
    if (owner && !paused) {
      timeOutRef.current = setInterval(() => {
        onShowNext();
      }, 3000);
    }

    return () => {
      clearInterval(timeOutRef.current);
    };

  }, [paused])

  React.useEffect(() => {
    console.log("DRAWELEMENT: ", drawElements);
  }, [drawElements]);

  return (
    <div className="GameEnd__Container">
      {drawHistory[lineId] && <GuessItem
        guess={drawHistory[lineId].initialPhrase}
        guesser={drawHistory.at(lineId)!.owner}
        initialPhrase
        end={false}
        addDrawElement={addDrawElement}
        drawElements={drawElements}
        index={0}
      />}
      {drawHistory.at(lineId)?.history.map((history, index) => {
        if (index > historyId) {
          return null;
        }
        return (
          <>
            {(showDrawing || index !== historyId) && (

              <DrawingItem
                drawHistory={history}
                end={
                  index === drawHistory.at(lineId)!.history.length - 1 &&
                  !history.guess
                }
                addDrawElement={addDrawElement}
                drawElements={drawElements}
                index={2 * (index + 1)}
              />
            )}
            {history.guess && (showGuess || index !== historyId) && (
              <GuessItem
                guess={history.guess}
                guesser={history.guesser}
                end={
                  index === drawHistory.at(lineId)!.history.length - 1 &&
                  showGuess
                }
                addDrawElement={addDrawElement}
                drawElements={drawElements}
                index={2 * (index + 1) + 1}
              />
            )}
          </>
        )
      })}
      {owner && showNextButton &&
        <div
          className="GameEnd__NextButton"
          onClick={onShowNextManual}>
          {textsBroken.next}
        </div>
      }
    </div>
  );
};

interface DrawingItemProps {
  drawHistory: HistoryItem;
  end: boolean;
  addDrawElement: (d: any) => void;
  drawElements: DrawElement[];
  index: number;
}

export interface CustomCanvasPreviewRef {
  exportImage: (type: ExportImageType) => Promise<string>;
  isFinishedDrawing: () => boolean
}

const DrawingItem = ({ drawHistory, end, addDrawElement, drawElements, index }: DrawingItemProps) => {
  const canvasRef = React.useRef<CustomCanvasPreviewRef | null>(null);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [scaledPaths, setScaledPaths] = React.useState([]);

  React.useEffect(() => {
    const historyItem = drawHistory;
    if (historyItem) {
      const newPaths = historyItem.drawData.map((each: any) => ({
        ...each,
        paths: each.paths.map((path: any) => ({
          x: path.x * (containerRef.current?.clientWidth || 1),
          y: path.y * (containerRef.current?.clientHeight || 1),
        })),
      }));
      setScaledPaths(newPaths);
    }
  }, []);

  const onFinishedDrawing = (base64Drawing: string) => {
      if (canvasRef.current) {
        const newDrawElement: DrawElement = {
          type: "draw",
          content: base64Drawing,
          name: drawHistory.drawer,
          // avatar: `https://avatars.dicebear.com/api/adventurer/${drawHistory.drawer}.svg?flip=true`,
          avatar: getAvatarUri(drawHistory.drawer),
          index: index
        };
        addDrawElement(newDrawElement);
      }
  }

  return (
    <div className="GameEnd__HistoryItemContainer">
      <div className="GameEnd__DrawingContainer">
        <div className='GameEnd__CanvasContainerWrapper'>
          <div
            className="GameEnd__CanvasContainer"
            ref={ref => (containerRef.current = ref)}>
            <CanvasPreview
              onFinishedDrawing={onFinishedDrawing}
              ref={ref => (canvasRef.current = ref)}
              canvasColor='white'
              paths={scaledPaths}
              disabled={false}
              startPreview={true}
              containerRef={containerRef.current}
              instantPreview={false}
            />
            <div className='GameEnd__DrawingOwner'>
              {`${drawHistory.drawer}`}
            </div>
          </div>
        </div>

        <div className="GameEnd__AvatarContainerDrawingItem">
          <img
            draggable={false}
            src={getAvatarUri(drawHistory.drawer)}
            alt="Avatar"
            className={'Players__Avatar'}
          />
          <div className="GameEnd__MessageTriangleDrawingItem"></div>
        </div>
      </div>
      {end && <EndItem drawElements={drawElements} />}
    </div>
  );
};

interface GuessItemProps {
  guess: string;
  guesser?: string;
  initialPhrase?: boolean;
  end: boolean;
  addDrawElement: (d: any) => void;
  drawElements: DrawElement[];
  index: number
}
const GuessItem = ({ guess, guesser, end, addDrawElement, initialPhrase = false, drawElements, index }: GuessItemProps) => {
  const possibleWords = [
    ` is guessing.`,
    ` is guessing..`,
    ` is guessing...`,
  ];
  const possibleWords2 = [
    ` is typing.`,
    ` is typing..`,
    ` is typing...`,
  ];
  const timerRef = React.useRef<NodeJS.Timer | undefined>(undefined);
  const timeOut = React.useRef<NodeJS.Timer | undefined>(undefined);
  const [wordIndex, setWordIndex] = React.useState(0);
  const [showInitialAnimation, setShowInitialAnimation] = React.useState<boolean>(true);


  React.useEffect(() => {
    if (showInitialAnimation) {
      timerRef.current = setInterval(() => {
        setWordIndex(wordIndex => (wordIndex + 1) % possibleWords.length);
      }, 500);
    }
    return () => {
      clearInterval(timerRef.current);
    };
  }, [showInitialAnimation]);

  React.useEffect(() => {
    setShowInitialAnimation(true);
  }, [guess]);

  React.useEffect(() => {
    const newDrawElement: DrawElement = {
      type: "phrase",
      content: guess,
      name: guesser || "",
      avatar: getAvatarUri(guesser || "default"),
      index: index
    };
    addDrawElement(newDrawElement);

  }, [addDrawElement, guess, guesser, index]);

  React.useEffect(() => {
    timeOut.current = setTimeout(() => {
      setShowInitialAnimation(false);
    }, 3000);
    return () => {
      clearTimeout(timeOut.current)
    }
  }, [guess]);

  return (
    <div className="GameEnd__HistoryItemContainer">
      <div className="GameEnd__GuessContainer">
        <div className="GameEnd__AvatarContainer">
          <img
            draggable={false}
            src={getAvatarUri(guesser || "default")}
            alt="Avatar"
            className={'Players__Avatar'}
          />
        </div>
        <div className="GameEnd__PhraseContainer">
          <div className='GameEnd__PhraseOwner'>
            {`${guesser}`}
          </div>
          {!showInitialAnimation ? <h4>{guess}</h4> : <h4>{initialPhrase ? possibleWords2[wordIndex] : possibleWords[wordIndex]}</h4>}
        </div>
        <div className="GameEnd__MessageTriangle"></div>
      </div>
      {end && <EndItem drawElements={drawElements} />}
    </div>
  );
};

interface EndItemProps {
  drawElements?: DrawElement[];
}

const EndItem = ({ drawElements }: EndItemProps) => {
  const createGif = useCreateGif();
  const contextLanguage = useSelector((state: any) => state.language);
  const textsBroken = languageTexts[contextLanguage.Language as LanguageNames].brokenPhoneRoomTexts;

  const onDownloadButton = () => {
    if ( drawElements !== undefined && drawElements.length > 0){
      const sortedDrawElements = drawElements.sort((a: DrawElement,b: DrawElement) => {
        if ( a.index < b.index ){
          return -1;
        }
        if ( a.index > b.index ){
          return 1;
        }
        return 0;
      })
      console.log("DOWNLOADING GIF...")
      console.log("SORTED DRAWELEMENTS: ", sortedDrawElements)
      createGif.generateGif(sortedDrawElements)
    }
  }

  return (
    <div className="EndItem__Container" >
      <div className="EndItem__Line" />
      <div className='EndItem__DownloadContainer' onClick={() => onDownloadButton()}>
        <h3 className="EndItem__Text">{textsBroken.lineEnded}</h3>
        <DownloadIcon className="EndItem__Download" />
      </div>
      <div className="EndItem__Line" />
    </div>
  );
};
