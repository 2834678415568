export interface Language {
  id: string;
  name: string;
  code: string;
}

interface Map {
  [key: string]: string | undefined;
}

export const LANGUAGES_MAP: Map = {
  EN: 'English',
  ES: 'Español',
};
