import React, {useContext, useEffect, useRef} from 'react';
import {Message} from "../../interfaces/Message";
import {SocketContext} from "../../context/SocketContext";
import {MessageReceiver} from "../../pages/RoomPage/MessageReceiver";
import {Chat} from "./Chat";

interface ChatProps {
    sendMessage: (message: String) => void;
    disabled: boolean;
    style?: string;
}

export const ChatDirectMessage = ({sendMessage, disabled, style = ''}: ChatProps) => {
    const [internalMessages, setMessages] = React.useState<Message[]>([]);
    const {socket} = useContext(SocketContext);
    const messageReceiver = useRef(new MessageReceiver());

    useEffect(() => {
        return messageReceiver.current.onMessagesUpdated((newMessages) => {
            setMessages(messages => [...messages, ...newMessages].slice(-100));
        });
    }, []);

    useEffect(() => {
        if (socket ) {
            socket.on('room-message', message => {
                message.ServerMessage = false;
                messageReceiver.current.receiveMessage(message);
            });
            socket.on('server-message', message => {
                message.ServerMessage = true;
                messageReceiver.current.receiveMessage(message);
            });
            return () => {
                socket.off('room-message');
                socket.off('server-message');
            }
        }
    }, [socket]);

    return <Chat
        messages={internalMessages}
        sendMessage={sendMessage}
        disabled={disabled}
        style={style}
    />
}
