import {Message} from '../../interfaces/Message';
import React from 'react';

const availableColors = [
  '#e6194B',
  '#3cb44b',
  '#4363d8',
  '#911eb4',
  '#31a0b8',
  '#f032e6',
  '#718f24',
  '#469990',
  '#9e7bc7',
  '#9A6324',
  '#800000',
  '#00a430',
  '#808000',
  '#000075',
];
const getColorByName = (name: string): string => {
  let totalValue = 0;
  for (let character of name) {
    totalValue += character.charCodeAt(0);
  }
  const position =
    (totalValue * name.length * (totalValue % name.length)) %
    availableColors.length;
  if (name === 'GAME') {
    return '#192bff';
  } else {
    return availableColors[position];
  }
};

interface MessageProps {
  message: Message;
  i: number;
}

export const ChatMessage = React.memo(({message, i}: MessageProps) => {
  if (!message.Word) {
    return (
      <p
        key={message.Message + i}
        className="Chat__From"
        style={{
          color: getColorByName(message.From),
          fontWeight: message.From === 'GAME' ? 'bold' : 'normal',
        }}>
        {message.From}:{'  '}
        <span
          className="Chat__Message"
          style={message.Highlight ? {fontWeight: 'bold'} : {fontWeight: 'normal'}}>
          {message.Message}
        </span>
      </p>
    );
  } else {
    return (
      <p
        key={message.Message + i}
        className="Chat__From"
        style={{
          color: getColorByName(message.From),
          fontWeight: message.From === 'GAME' ? 'bold' : 'normal',
        }}>
        {message.From}:{'  '}
        <span
          className="Chat__Message"
          style={message.Highlight ? {fontWeight: 'bold'} : {fontWeight: 'normal'}}>
          {message.Message}
        </span>
        <span className="Chat__Message" style={{fontWeight: 'bold'}}>
          {message.Word}
        </span>
      </p>
    );
  }
});
