import { useEffect, useState, useRef } from 'react';
import { Chat } from '../../components/Chat/Chat';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Message } from '../../interfaces/Message';
import { LanguageNames, languageTexts } from '../../utils/languageTexts';
import { useSelector } from 'react-redux';
import './BrokenPhoneLobby.scss';
import { DrawElement, useCreateGif } from '../../hooks/useCreateGif';
import DownloadIcon from '@mui/icons-material/Download';

interface Props {
  messages: Message[];
  sendMessage: (message: String) => void;
  onPlayerLeave: () => void;
  drawHistory: DrawElement[][];
}

export const PregameLobby = ({ messages, sendMessage, onPlayerLeave, drawHistory }: Props) => {
  const [historyIndex, setHistoryIndex] = useState(0);
  const [drawElements, setDrawElements] = useState<DrawElement[]>([]);
  const contextLanguage = useSelector((state: any) => state.language);
  const textsBroken = languageTexts[contextLanguage.Language as LanguageNames].brokenPhoneRoomTexts;

  const onShowNext = () => {
    if (historyIndex < drawHistory.length - 1) {
      setHistoryIndex(lineId => lineId + 1);
    }
  };

  const onShowPrevious = () => {
    if (historyIndex > 0) {
      setHistoryIndex(lineId => lineId - 1);
    }
  };

  useEffect(() => {
    const historyItem = drawHistory[historyIndex] ?? [];
    const sortedDrawElements = historyItem.sort((a: DrawElement, b: DrawElement) => {
      if ( a.index < b.index ){
        return -1;
      }
      if ( a.index > b.index ){
        return 1;
      }
      return 0;
    });
    setDrawElements(sortedDrawElements);
  }, [historyIndex, drawHistory]);

  return (
    <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}>
      {(drawHistory && drawHistory.length > 0) ? (
        <>
          <div className="BrokenPhoneLobby__HistoryContainer" style={{ flex: 2 }}>
            <div className="BrokenPhone__HistoryHeader">
              <p>{textsBroken.history}</p>
            </div>
            <div className="BrokenPhoneLobby__LineContainer">
              <GameResult result={drawElements} />
            </div>
            <div className="BrokenPhoneLobby__ButtonContainer">
              <div
                className={historyIndex <= 0 ? "BrokenPhone__ButtonDisabled" : "BrokenPhone__Button"}
                onClick={onShowPrevious}>
                {textsBroken.previous}
              </div>
              <div
                className={(historyIndex >= drawHistory.length - 1) ? "BrokenPhone__ButtonDisabled" : "BrokenPhone__Button"}
                onClick={onShowNext}>
                {textsBroken.next}
              </div>
            </div>
          </div>
          <div className="App__ChatRoomContainerBrokenPhone" style={{ flex: 1, minWidth: '250px' }}>
            <Chat
              messages={messages}
              sendMessage={sendMessage}
              disabled={false}
              style={'BrokenPhone'}
            />
          </div>
        </>
      ) : (
        <div className="App__ChatRoomContainerBrokenPhone">
          <Chat
            messages={messages}
            sendMessage={sendMessage}
            disabled={false}
            style={'BrokenPhone'}
          />
        </div>
      )}
      <div
        className="Canvas__ButtonContainerLeave"
        onClick={onPlayerLeave}
      >
        <ExitToAppIcon fontSize='small'/>
        {textsBroken.leaveRoom}
      </div>
    </div>
  );
};

const GameResult = ({ result }: { result: DrawElement[] }) => {
  return (
    <div className="BrokenPhoneLobby__LineContainer">
      {result.map((element, index) => {
        return (
          <div className="GameEnd__HistoryItemContainer">
            { element.type === 'draw' ? (
              <div className="GameEnd__DrawingContainer">
                <div className='GameEnd__CanvasContainerWrapper'>
                  <div className="GameEnd__CanvasContainer">
                    <img
                      draggable={false}
                      src={element.content}
                      alt="Drawing"
                      style={{ width: '100%', height: '100%' }}
                    />
                    <div className='GameEnd__DrawingOwner'>
                      {`${element.name}`}
                    </div>
                  </div>
                </div>
                <div className="GameEnd__AvatarContainerDrawingItem">
                  <img
                    draggable={false}
                    src={element.avatar}
                    alt="Avatar"
                    className={'Players__Avatar'}
                  />
                  <div className="GameEnd__MessageTriangleDrawingItem"></div>
                </div>
              </div>
            ) : (
              <div className="GameEnd__GuessContainer">
                <div className="GameEnd__AvatarContainer">
                  <img
                    draggable={false}
                    src={element.avatar}
                    alt="Avatar"
                    className={'Players__Avatar'}
                    />
                </div>
                <div className="GameEnd__PhraseContainer">
                  <div className='GameEnd__PhraseOwner'>
                    {element.name}
                  </div>
                  <h4>{element.content}</h4>
                </div>
                <div className="GameEnd__MessageTriangle"></div>
              </div>
            )}
            {(index === result.length - 1) && <EndItem drawElements={result} />}
          </div>
        )
      })}
    </div>
  );
};

interface EndItemProps {
  drawElements?: DrawElement[];
}

const EndItem = ({ drawElements }: EndItemProps) => {
  const createGif = useCreateGif();
  const contextLanguage = useSelector((state: any) => state.language);
  const textsBroken = languageTexts[contextLanguage.Language as LanguageNames].brokenPhoneRoomTexts;

  const onDownloadButton = () => {
    if ( drawElements !== undefined && drawElements.length > 0){
      console.log("DOWNLOADING GIF...")
      createGif.generateGif(drawElements)
    }
  }

  return (
    <div className="EndItem__Container" >
      <div className="EndItem__Line" />
      <div className='EndItem__DownloadContainer' onClick={() => onDownloadButton()}>
        <h3 className="EndItem__Text">{textsBroken.lineEnded}</h3>
        <DownloadIcon className="EndItem__Download" />
      </div>
      <div className="EndItem__Line" />
    </div>
  );
};

