import React from 'react';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {SvgIcon, Typography} from '@mui/material';

import {ReactComponent as KickIcon} from '../../assets/V2K.svg';
import {ReactComponent as DisabledKickIcon} from '../../assets/DisabledV2K.svg';

interface Props {
  disabled: boolean;
  text: string;
  onClick: () => void;
}

export const KickButton = ({disabled, text, onClick}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        padding: 10,
        cursor: disabled ? 'default' : 'pointer',
      }}
      onClick={disabled ? () => {} : onClick}>
      <div style={{marginRight: 10}}>
        <SvgIcon
          component={disabled ? DisabledKickIcon : KickIcon}
          inheritViewBox
        />
      </div>
      <Typography>{text}</Typography>
    </div>
  );
};
