import {
  Box,
  Modal,
  TextField,
  Typography,
  ButtonBaseTypeMap,
  Button,
  MenuItem,
} from '@mui/material';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {AuthContext} from '../../context/AuthContext';
import './AuthModal.scss';
import {LanguageNames, languageTexts} from '../../utils/languageTexts';
import {useSelector} from 'react-redux';
import {getSignUpAvatarUri} from "../../services/Avatars";

interface AuthModalProps {
  visible: boolean;
  onClose: () => void;
  auxFunction?: () => void;
  obligatory: boolean;
}

export const AuthModal = ({
  visible,
  onClose,
  auxFunction = () => {},
  obligatory
}: AuthModalProps) => {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const {login, signup} = useContext(AuthContext);
  const [error, setError] = React.useState('');
  const [signupError, setSignupError] = React.useState<any>({});
  const [language, setLanguage] = React.useState<string>('ES');
  const [usernameSignup, setUsernameSignup] = React.useState('');
  const [passwordSignup, setPasswordSignup] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [activeTab, setActiveTab] = React.useState('login');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [avatar, setAvatar] = React.useState('');
  const [avatarFile, setAvatarFile] = React.useState<File | undefined>(
    undefined,
  );
  const filePickerRef = useRef<HTMLInputElement | null>(null);

  const {getBrowserLanguage} = useContext(AuthContext);

  const contextLanguage = useSelector((state: any) => state.language);
  const texts =
    languageTexts[contextLanguage.Language as LanguageNames].authTexts;

  const modalStyle = {
    position: 'absolute' as 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 5,
    p: 0,
    overflow: 'hidden',
    borderColor: 'border.paper',
  };

  const renderLoginTab = () => {
    return (
      <>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="user@name.com"
          autoComplete="off"
          label={texts.email}
          margin="dense"
          onChange={event => {
            setError('');
            setUsername(event.target.value);
          }}
          value={username}
          InputProps={{
            style: {borderRadius: '20px', height: '50px'},
          }}
          style={{marginBottom: '10px', marginTop: '30px'}}
        />
        <TextField
          variant="outlined"
          fullWidth
          autoComplete="off"
          type="password"
          label={texts.password}
          margin="dense"
          onChange={event => {
            setError('');
            setPassword(event.target.value);
          }}
          value={password}
          InputProps={{
            style: {borderRadius: '20px', height: '50px'},
          }}
          style={{marginBottom: error ? '0px' : '25px'}}
        />

        {error && (
          <div className="AuthModal__ContinueContainer">
            <p className="AuthModal__ContinueText">{error}</p>
          </div>
        )}

        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div
            className="Canvas__ButtonContainerWarning"
            style={{width: '140px', height: '40px'}}
            onClick={onLogin}>
            {texts.titleLogin}
          </div>
        </div>

        <div className="AuthModal__ContinueContainer">
          <p className="AuthModal__ContinueText">
            {texts.continueAs}
            <span className="AuthModal__GuestText" onClick={closeModal}>
              {texts.guest}
            </span>
          </p>
        </div>
      </>
    );
  };

  const onChangeAvatar = () => {
    if (filePickerRef.current) {
      filePickerRef.current.click();
    }
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        const dataUrl = reader.result as string;
        setAvatar(dataUrl);
        setAvatarFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const onLogin = async () => {
    const authenticated = await login(username, password);
    if (authenticated) {
      setError('');
      closeModal();
      auxFunction();
    } else {
      setError(texts.credentialsError);
    }
  };

  const validateSignupFields = () => {
    let errors: any = {};
    if (passwordSignup !== confirmPassword) {
      errors.password = texts.passwordNotMatch;
    }

    if (!usernameSignup) {
      errors.username = texts.usernameRequired;
    } else if (usernameSignup.length > 25) {
      errors.username = texts.usernameLength;
    }

    if (!passwordSignup) {
      errors.password = texts.passwordRequired;
    } else if (
      !new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{6,})').test(passwordSignup)
    ) {
      errors.password = texts.passwordFormat;
    }

    if (!email) {
      errors.email = texts.emailRequired;
    } else if (!email.includes('@')) {
      errors.email = texts.emailFormat;
    } else if (email.length > 80) {
      errors.email = texts.emailLength;
    }

    setSignupError(errors);

    return Object.keys(errors).length === 0;
  };

  const onSignup = async () => {
    const fieldsValid = validateSignupFields();
    if (!fieldsValid) {
      return;
    }
    const authenticated = await signup(
      email,
      usernameSignup,
      passwordSignup,
      language,
      avatarFile,
    );
    if (authenticated === "Ok") {
      setError('');
      setSignupError({});
      closeModal();
    } else {
      if (authenticated.includes("400")){
        setError(texts.userInUse);
      }else{
        setError(authenticated)
      }
    }
  };

  const renderSignupTab = () => {
    return (
      <>
        <div className="AuthModal__SelectAvatarContainer">
          <div className="AuthModal__AvatarContainer">
            <img
              draggable={false}
              src={
                avatar === ''
                  ? getSignUpAvatarUri()
                  : avatar
              }
              alt="Avatar"
              className="AuthModal__Avatar"
            />
          </div>
          <p className="AuthModal__UploadAvatarText" onClick={onChangeAvatar}>
            {texts.avatar}
          </p>
        </div>
        <div className="AuthModal__TextFieldContainer">
          <TextField
            variant="outlined"
            fullWidth
            autoComplete="off"
            label={texts.name}
            margin="dense"
            onChange={event => {
              setError('');
              setSignupError({...signupError, username: ''});
              setUsernameSignup(event.target.value);
            }}
            value={usernameSignup}
            error={signupError.username ? true : false}
            helperText={signupError.username}
            InputProps={{
              style: {borderRadius: '20px', height: '50px'},
            }}
            style={{marginBottom: '10px'}}
          />
          <TextField
            variant="outlined"
            fullWidth
            placeholder="user@name.com"
            autoComplete="off"
            label={texts.email}
            margin="dense"
            onChange={event => {
              setError('');
              setSignupError({...signupError, email: ''});
              setEmail(event.target.value);
            }}
            value={email}
            error={signupError.email ? true : false}
            helperText={signupError.email}
            InputProps={{
              style: {borderRadius: '20px', height: '50px'},
            }}
            style={{marginBottom: '10px'}}
          />
          <TextField
            variant="outlined"
            fullWidth
            autoComplete="off"
            type="password"
            label={texts.password}
            margin="dense"
            onChange={event => {
              setSignupError({...signupError, password: ''});
              setPasswordSignup(event.target.value);
            }}
            value={passwordSignup}
            error={signupError.password ? true : false}
            helperText={signupError.password || texts.passwordDescription}
            InputProps={{
              style: {borderRadius: '20px', height: '50px'},
            }}
            style={{marginBottom: '10px'}}
          />

          <TextField
            variant="outlined"
            fullWidth
            autoComplete="off"
            type="password"
            label={texts.confirmPassword}
            margin="dense"
            onChange={event => {
              setSignupError({...signupError, password: ''});
              setConfirmPassword(event.target.value);
            }}
            value={confirmPassword}
            error={signupError.password ? true : false}
            InputProps={{
              style: {borderRadius: '20px', height: '50px'},
            }}
            style={{marginBottom: '25px'}}
          />

          <TextField
            value={language}
            variant="outlined"
            label="Language"
            fullWidth
            select
            margin="dense"
            style={{marginBottom: '25px'}}
            onChange={event => {
              setLanguage(event.target.value);
            }}
            InputProps={{
              style: {
                borderRadius: '20px',
                height: '50px',
                textAlign: 'left',
              },
            }}>
            <MenuItem value={'ES'}>Español</MenuItem>
            <MenuItem value={'EN'}>English</MenuItem>
          </TextField>
        </div>

        {error && (
          <div className="AuthModal__ContinueContainer">
            <p className="AuthModal__ContinueText">{error}</p>
          </div>
        )}

        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div
            className="Canvas__ButtonContainerWarning"
            style={{width: '140px', height: '40px'}}
            onClick={onSignup}>
            {texts.titleRegister}
          </div>
        </div>

        <div className="AuthModal__ContinueContainer">
          <p className="AuthModal__ContinueText">
            {texts.continueAs}
            <span className="AuthModal__GuestText" onClick={closeModal}>
              {texts.guest}
            </span>
          </p>
        </div>
      </>
    );
  };

  const closeModal = () => {
    setSignupError({});
    setError('');
    onClose();

    setAvatar('');
    setUsernameSignup('');
    setEmail('');
    setPasswordSignup('');
    setConfirmPassword('');
  };

  const renderTabContainer = () => {
    return (
      <Box sx={modalStyle}>
        <div className="AuthModal__TabsContainer">
          <input
            type="file"
            style={{display: 'none'}}
            ref={ref => (filePickerRef.current = ref)}
            onChange={event => {
              onFileChange(event);
            }}
          />
          <div
            className={
              activeTab === 'login' ? 'AuthModal__Tab' : 'AuthModal__TabOff'
            }
            style={{borderRadius: '25px 0px 0px 25px'}}
            onClick={() => {
              setError('');
              setActiveTab('login');
            }}>
            {texts.titleLogin}
          </div>

          <div
            className={
              activeTab === 'signup' ? 'AuthModal__Tab' : 'AuthModal__TabOff'
            }
            style={{borderRadius: '0px 25px 25px 0px'}}
            onClick={() => {
              setError('');
              setActiveTab('signup');
            }}>
            {texts.titleRegister}
          </div>
        </div>
        <div className="AuthModal__Body">
          {activeTab === 'login' ? renderLoginTab() : renderSignupTab()}
        </div>
      </Box>
    );
  };

  return (
    <Modal open={visible} onClose={obligatory ? () => {} : closeModal}>
      {renderTabContainer()}
    </Modal>
  );
};
