import { useState, useEffect, useMemo } from "react";

export const useResize = () => {
  const [resize, setResize] = useState(false);

  const setResizeValue = (b : boolean) => {
    setResize(b);
  }

  // useEffect (() => {
  //   console.log("RESIZED! with value: ",resize )
  // }, [resize])
 
  
  useEffect(() => {
    const onResize = (event: any) => setResize(event)

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  return { resize, setResizeValue };
};