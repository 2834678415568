import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/confused_pencil.png";
import "./ConfirmationModal.scss";

interface ConfirmationProps {
  visible: boolean;
  onClose: () => void;
  onAccept: () => void;
  title?: string;
  message?: string;
  acceptText?: string;
  cancelText?: string;
  showFirstButton?: boolean;
  showSecondButton?: boolean;
}

export const ConfirmationModal = ({
  visible,
  onClose,
  onAccept,
  title = "Are you sure?",
  acceptText = "Go to Login",
  cancelText = "Cancel",
  message = "If you log in now, you will exit the current game",
  showFirstButton = true,
  showSecondButton = true,
}: ConfirmationProps) => {
  const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 5,
    p: 0,
    overflow: "hidden",
    padding: "1.5rem"
  };

  return (
    <Modal open={visible} onClose={onClose}>
      <Box sx={modalStyle}>
        <div className="ChangeName__Header" >
          <p style={{margin: 0}}>{title}</p>
        </div>
        <div className="ChangeName_BodyContainer">
          <div className="ChangeName__Body">
            <p>{message}</p>
          </div>
        </div>
        <div className={"ChangeName__ButtonContainer"}>
          {showFirstButton && <div
            className="Canvas__ButtonContainerWarning"
            onClick={onClose}
            style={{width: '20%'}}
          >
            {cancelText}
          </div>}

          {showSecondButton && <div
            className={'Canvas__ButtonContainerWarning'}
            onClick={() => {
              onAccept();
              onClose();
            }}
            style={{width: '20%'}}
          >
            {acceptText}
          </div>}
        </div>
      </Box>
    </Modal>
  );
};
