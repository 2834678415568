import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Canvas, MyCanvasRef } from '../../components/Canvas/Canvas';
import { Chat } from '../../components/Chat/Chat';
import { Players } from '../../components/Players/Players';
import { SocketContext } from '../../context/SocketContext';
import { Message } from '../../interfaces/Message';
import { Player } from '../../interfaces/Player';
import './BrokenPhoneLobby.scss';
import { GameEndModal } from '../../components/GameEndModal/GameEndModal';
import { Header } from '../../components/Header/Header';
import { EnterPhrase } from '../../components/EnterPhrase/EnterPhrase';
import { GuessDrawing } from '../../components/GuessDrawing/GuessDrawing';
import { GameEndRound } from '../../components/GameEndRound/GameEndRound';
import { SocialMedia } from '../../components/SocialMedia/SocialMedia';
import { Room } from '../../interfaces/Room';
import { useSelector } from 'react-redux';
import { userState } from '../../store/userReducer';
import { BrokenPhoneCanvas } from '../../components/Canvas/BrokenPhoneCanvas/BrokenPhoneCanvas';
import { LanguageNames, languageTexts } from '../../utils/languageTexts';
import { MyCanvasPath } from '../../components/Canvas/CustomCanvas/MyCanvas';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { DrawElement } from '../../hooks/useCreateGif';
import { PregameLobby } from './PregameLobby';

export function BrokenPhoneLobby() {
  const [messages, setMessages] = React.useState<Message[]>([]);
  const context = useContext(SocketContext);
  const [players, setPlayers] = React.useState<Player[]>([]);
  const [isDrawing, setIsDrawing] = React.useState<boolean>();
  const [wordToGuess, setWordToGuess] = React.useState<string | undefined>();
  const [timeRemaining, setTimeRemaining] = React.useState<number>(0);
  const [gameState, setGameState] = React.useState<string>('pre-game');
  const [lineId, setLineId] = React.useState(0);
  const [historyId, setHistoryId] = React.useState(0);
  const [paths, setPaths] = React.useState<MyCanvasPath[]>([]);
  const [rooms, setRooms] = React.useState<Room[]>();
  // const canvasElementRef = useRef<MyCanvasRef | null>(null);
  const [drawHistory, setDrawHistory] = React.useState<any>([]);
  const [roundDuration, setRoundDuration] = React.useState(0);
  const [maxPlayers, setMaxPlayers] = React.useState(0);
  const [owner, setOwner] = React.useState(false);
  // const [gameAudio, setGameAudio] = useLocalStorage('gameAudio', true);
  const stateUser: userState = useSelector((state: any) => state.user);
  const [playerDrawing, setPlayerDrawing] = React.useState<Player>();
  const navigate = useNavigate();
  // const timer = useRef<NodeJS.Timer | undefined>();
  const intermissionTimer = useRef<NodeJS.Timer | undefined>();
  const roundNumberRef = useRef(1);
  const contextLanguage = useSelector((state: any) => state.language);
  const textsBroken = languageTexts[contextLanguage.Language as LanguageNames].brokenPhoneRoomTexts;
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [placeHolder, setPlaceHolder] = React.useState<string>("")
  const [drawElementsArray, setDrawElementsArray] = React.useState<DrawElement[][]>([]);
  const [gameEnd, setGameEnd] = React.useState<boolean>(false);

  useEffect(() => {
    if (context.socket) {
      context.socket.emit('request-game-status');
      context.socket.on('room-message', message => {
        setMessages(messages => [
          ...messages,
          { ...message, ServerMessage: false },
        ]);
      });
      context.socket.on('server-message', message => {
        setMessages(messages => [
          ...messages,
          { ...message, ServerMessage: true },
        ]);
      });

      context.socket.on('get-draw-elements', drawElementsArray => {
        console.log("DRAW ELEMENTS ARRAY: ", drawElementsArray)
        setDrawElementsArray(drawElementsArray);
      })

      context.socket.on('random-phrase', arg => {
        console.log("random phrase: ", arg.text, "for: ", stateUser.user?.name)
        setPlaceHolder(arg.text);
      })

      context.socket.on('players-update', players => {
        setPlayers(players);
        console.log('PLAYERS FRONT', players);
        const owner = players.find((player: Player) => player.owner);
        if (
          (stateUser.user && owner?.Name === stateUser.user.name) ||
          owner?.Name === stateUser.id
        ) {
          setOwner(true);
        }
      });

      context.socket.on('round-start', args => {
        console.log("ROUND START")
        // roundNumberRef.current = 1;
        if (context.socket) {
          context.socket.emit("player-not-ready");
        }
        setGameState(args.roundType);
        setTimeRemaining(args.duration);
        setRoundDuration(args.duration);
      });

      context.socket.on('change-round-type', roundType => {
        console.log(roundType);
        if (context.socket) {
          context.socket.emit("player-not-ready");
        }
        roundNumberRef.current += 1;
        console.log("Round number: ", roundNumberRef.current)
        setGameState(roundType);
        clearInterval(intermissionTimer.current);
      });

      context.socket.on('guess-info', args => {
        setLineId(args.lineId);
        setHistoryId(args.historyId);
        setPaths(args.drawingToGuess);
      });

      context.socket.on('game-end-info', drawData => {
        console.log('drawData: ', drawData);
        setDrawHistory(drawData.drawHistory);
      });

      context.socket.on('room-info', (rooms: Room[]) => {
        console.log('Rooms: ', rooms);
        setRooms(rooms);
      });

      context.socket.on('kicked', () => {
        navigate('/');
      });

      return () => {
        context.socket?.off('players-update');
        context.socket?.off('room-message');
        context.socket?.off('server-message');
        context.socket?.off('round-start');
        context.socket?.off('change-round-type');
        context.socket?.off('request-draw-data');
        context.socket?.off('guess-info');
        context.socket?.off('game-end-info');
        // context.socket?.off('game-end');
        context.socket?.off('room-info');
        //clearTimeout(timer.current);
        context.socket?.off('kicked');
        context.socket?.off('random-phrase');
      };
    }
  }, [
    context,
    isDrawing,
    lineId,
    historyId,
    roundDuration,
  ]);

  useEffect(() => {
    const roomId = localStorage.getItem('roomId');
    if (rooms) {
      const currentRoom = rooms!.find((r: Room) => r.Id === roomId);
      setMaxPlayers(currentRoom?.MaxPlayers!);
    }
  }, [rooms]);

  useEffect(() => {
    setPlayerDrawing(players.find(player => player.Drawing));
  }, [players]);

  useEffect(() => {
    console.log("Paths: ", paths);
  },[paths])

  

  const sendMessage = (message: String) => {
    if (context.socket) {
      context.socket.emit('room-message', message);
    }
  };

  const onPlayerLeave = () => {
    if (context.socket) {
      context.socket.emit('leave-game');
      context.socket.emit("player-not-ready");
      navigate('/');
    }
  };

  const onReport = () => {
    //Player.name
    if (context.socket) {
      context.socket.emit('report', playerDrawing?.Name);
    }
  };

  // const onShowNext = () => {
  //   if (context.socket) {
  //     context.socket.emit('show-next');
  //   }
  // };

  // const onReturnToLobby = () => {
  //   if (context.socket) {
  //     context.socket.emit('restart-game');
  //     setGameState("pre-game")
  //   }
  // }

  useEffect(() => {
    console.log('gameState: ', gameState);
  }, [gameState]);

  const renderRoundTab = () => {
    switch (gameState) {
      case 'pre-game':
        return <div></div>;
      case 'EnterPhrase':
        return (
          <EnterPhrase
            roundDuration={roundDuration}
            timeRemaining={timeRemaining}
            playersTotal={players.length || 0}
            placeHolder={placeHolder}
            roundNumber={roundNumberRef.current}
          />
        );
      case 'DrawingRound':
        return (
          <BrokenPhoneCanvas
            roundNumberRef={roundNumberRef.current}
            players={players}
            wordToGuess={wordToGuess!}
            timeRemaining={timeRemaining}
            setHistoryId={setHistoryId}
            setLineId={setLineId}
            setWordToGuess={setWordToGuess}
            setPaths={setPaths}
            lineId={lineId}
            historyId={historyId}
            containerRef={containerRef.current}
            paths={paths}
          />);
      case 'GuessRound':
        return (
          <GuessDrawing
            roundNumber={roundNumberRef.current}
            paths={paths}
            lineId={lineId}
            historyId={historyId}
            roundDuration={roundDuration}
          />
        );
      case 'GameEndRound':
        return <GameEndRound drawHistory={drawHistory} owner={owner} setGameEnd={setGameEnd}/>;
    }
  };

  return (
    <>
      <Header game="brokenPhone" onPlayerLeave={onPlayerLeave} />
      <div className="App_WelcomeContainerBrokenPhone" style={{ userSelect: 'none' }}>
        <div className='BrokenPhone__Container'>
          <Players
            players={players}
            onPlayerLeave={onPlayerLeave}
            maxPlayers={maxPlayers || 0}
            isBrokenPhoneGame={true}
            owner={owner}
            gameState={gameState}
            setGameState={setGameState}
            gameEnd={gameEnd}
            setGameEnd={setGameEnd}
          />
          {gameState === 'pre-game' && (
            <PregameLobby
              drawHistory={drawElementsArray}
              messages={messages}
              sendMessage={sendMessage}
              onPlayerLeave={onPlayerLeave}
            />
          )}
          {gameState !== 'pre-game' && (
            <div className='BrokenPhone__RenderRoundTab'
              ref={ref => (containerRef.current = ref)}>
              {renderRoundTab()}
            </div>
          )}
        </div>
      </div>
      <SocialMedia />
    </>
  );
}
