import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import './RoomPage.scss';
import {useNavigate} from 'react-router';
import {Canvas, MyCanvasRef} from '../../components/Canvas/Canvas';
import {Chat} from '../../components/Chat/Chat';
import {Players} from '../../components/Players/Players';
import {SocketContext} from '../../context/SocketContext';
import {Message} from '../../interfaces/Message';
import {Player} from '../../interfaces/Player';
import './RoomPage.scss';
import {GameEndModal} from '../../components/GameEndModal/GameEndModal';
import {useAudio} from '../../hooks/useAudio';
import {Header} from '../../components/Header/Header';
import {SocialMedia} from '../../components/SocialMedia/SocialMedia';
import Collapse from '@mui/material/Collapse';
import {useLocalStorage, useRafState} from 'react-use';
import {AuthContext} from '../../context/AuthContext';
import {AuthModal} from '../../components/AuthModal/AuthModal';
import {ChangeNameModal} from '../../components/ChangeNameModal/ChangeNameModal';
import {ConfirmationModal} from '../../components/ConfirmationModal/ConfirmationModal';
import {useResize} from '../../hooks/useResize';
import {LanguageNames, languageTexts} from '../../utils/languageTexts';
import Fireworks from '@fireworks-js/react';
import {logout} from '../../services/AuthService';
import {useSelector} from 'react-redux';
import {MyCanvasPath} from '../../components/Canvas/CustomCanvas/MyCanvas';
import smile from '../../assets/happyEmoji.png';
import happy from '../../assets/laughingEmoji.png';
import love from '../../assets/heartEmoji.png';
import surprise from '../../assets/surprisedEmoji.png';
import sad from '../../assets/sadEmoji.png';
import angry from '../../assets/angryEmoji.png';
import {userState} from '../../store/userReducer';
import {Room} from '../../interfaces/Room';
import {MessageReceiver} from './MessageReceiver';
import {ChatDirectMessage} from "../../components/Chat/ChatDirectMessage";

// const emojiMap = {
//   smile: '🙂',
//   happy: '😆',
//   love: '🧡',
//   surprise: '😮',
//   sad: '😢',
//   angry: '😡',
// };

const emojiMap = {
  smile: smile,
  happy: happy,
  love: love,
  surprise: surprise,
  sad: sad,
  angry: angry,
};
export interface EmojiReactionRef {
  animate: () => any;
  getEmojiName: () => string;
}

interface EmojiReactionProps {
  emoji: string[];
  index?: number;
  setCollapseEmojis?: Function;
  isDrawing: boolean;
  animateFunction?: Function;
  globalAnimation: boolean;
  setGlobalAnimation: Function;
}

interface EmojiAnimationProps {
  emoji: string[];
  removeFirstElement: () => void;
  setAnimating: (p: boolean) => void;
}

interface GameRoomState {
  paths: MyCanvasPath[];
  clueAmount: number;
  roundNumber: number;
  timeRemaining: number;
  totalRounds: number;
  drawWord: string;
  podium: Player[];
  playersForEndGame: Player[];
  maxTime: number;
  isEndGame: boolean;
  intermission: boolean;
}

const EmojiAnimation = ({
  emoji,
  removeFirstElement,
  setAnimating,
}: EmojiAnimationProps) => {
  const [animation, setAnimation] = React.useState<string>('');
  const emojiContainer = document.getElementById('emojiContainer' + emoji[0]);
  const [endAnimation, setEndAnimation] = React.useState<boolean>(false);

  useEffect(() => {
    const animations = [' Emoji__AnimLeftRight', ' Emoji__AnimLeftRight2'];
    const randomAnimation =
      animations[Math.floor(Math.random() * animations.length)];
    setAnimation(randomAnimation);
  }, []);

  const removeLastChildElement = () => {
    if (emojiContainer && emojiContainer.lastElementChild) {
      emojiContainer.removeChild(emojiContainer.lastElementChild);
    }
  };

  return (
    <div
      className={'Canvas__EmojiAnim' + ' Emoji__Anim '}
      onAnimationStart={() => {setTimeout(() => {
        removeFirstElement();
      }, 2500);}}
      onAnimationEnd={() => {
        //removeLastChildElement()
        setAnimation('');

        setEndAnimation(true);
        setAnimating(false);
        // console.log("Animation End")
      }}
      style={endAnimation ? {display: "none"} : {}}>
      <div className={'Canvas__EmojiAnim' + animation}>
        {/* <span role="img" className='Canvas__Emoji'>{emoji[1]}</span> */}
        <img
          src={emoji[1]}
          alt={emoji[0]}
          className="Canvas__Emoji"
          draggable="false"
        />
      </div>
    </div>
  );
};

const EmojiReaction = forwardRef<EmojiReactionRef, EmojiReactionProps>(
  (
    {
      emoji,
      animateFunction,
      index,
      setCollapseEmojis,
      isDrawing,
      globalAnimation,
      setGlobalAnimation,
    },
    ref?,
  ) => {
    const [emojisToRender, setEmojiRender] = React.useState<JSX.Element[]>([]);
    const [animating, setAnimating] = React.useState<boolean>(false);
    const [emojiNumber, setEmojiNumber] = React.useState<number>(0);

    const removeFirstElement = () => {
      setEmojiRender(prev => [...prev.slice(0, -1)]);
    };

    useEffect(() => {
      console.log("lista de emogis", emojisToRender)
    }, [emojisToRender]);


    useImperativeHandle(ref, () => ({
      animate: () => {
        setEmojiNumber(emojiNumber + 1);
        const newEmojiAnimation = (
          <EmojiAnimation
            key={emoji[0] + emojiNumber}
            emoji={emoji}
            removeFirstElement={removeFirstElement}
            setAnimating={setAnimating}
          />
        );
        setEmojiRender([...emojisToRender, newEmojiAnimation]);
      },
      getEmojiName: () => {
        return emoji[0];
      },
    }));

    useEffect(() => {
      const interval = setInterval(() => {
        setGlobalAnimation(false);
      }, 600);

      return () => clearInterval(interval);
    }, []);

    return (
      <div
        key={emoji[0]}
        id={'emojiContainer' + emoji[0]}
        className="Canvas__EmojiReactionContainer">
        <div
          className={
            'Canvas__EmojiContainer ' +
            (index === 0 ? 'Canvas__FirstEmoji' : '')
          }
          onClick={() => {
            if (!globalAnimation) {
              animateFunction!(emoji[0]);
              setGlobalAnimation(true);
              setAnimating(true);
            }
          }}
          onMouseOver={() => setCollapseEmojis!(true)}
          onMouseOut={() => setCollapseEmojis!(false)}
          style={isDrawing ? {filter: 'grayScale(100%)'} : undefined}>
          <img
            src={emoji[1]}
            alt={emoji[0]}
            className="Canvas__Emoji"
            draggable="false"
          />
        </div>
        {/* {isAnimating && <EmojiAnimation isAnimating={isAnimating} setIsAnimating={setIsAnimating} emoji={emoji} />} */}
        {emojisToRender.map(emojiToRender => {
          return emojiToRender;
        })}
      </div>
    );
  },
);

export function RoomPage() {
  const context = useContext(SocketContext);
  const {sendNewName} = useContext(AuthContext);
  const [players, setPlayers] = React.useState<Player[]>([]);
  const [drawData, setDrawData] = React.useState<string>('');
  const [isDrawing, setIsDrawing] = React.useState<boolean>();
  const [wordToGuess, setWordToGuess] = React.useState<string | undefined>();
  const roundNumberRef = useRef(0);
  const timeRemainingRef = useRef(0);
  const [intermission, setIntermission] = React.useState<boolean>(false);
  const gameEnd = React.useRef<boolean>(false);
  const [totalRounds, setTotalRounds] = React.useState<number>(0);
  const [clueAmount, setClueAmount] = React.useState(0);
  const [someoneGuessed, setSomeoneGuessed] = React.useState<boolean>(false);
  const [podium, setPodium] = React.useState<Player[]>([]);
  const [firstGuess, setFirstGuess] = React.useState<boolean>(false);
  const {play: playFirstGuessedAudio} = useAudio('/audio/snap.mp3');
  const {play: playRoundStartAudio} = useAudio('/audio/bell_ring.mp3', 0.4);
  const {play: playRoundTickAudio} = useAudio('/audio/glass.mp3', 0.4);
  const [gameAudio, setGameAudio] = useLocalStorage('gameAudio', false);
  const [playersForEndGame, setPlayersForEndgame] = React.useState<Player[]>(
    [],
  );
  const canvasRef = useRef<MyCanvasRef | null>(null);
  const {resize, setResizeValue} = useResize();
  const canvasContainer: HTMLElement | null = document.querySelector(
    '.Room__CanvasContainer',
  );
  const emojiReactionContainer: HTMLElement | null = document.querySelector(
    '.Canvas__EmojiReactionContainer',
  );
  const [collapseEmojis, setCollapseEmojis] = React.useState(false);
  const emojiReactionRef = useRef<Array<EmojiReactionRef | null>>([]);
  const [globalAnimation, setGlobalAnimation] = React.useState<boolean>(false);
  const [newTimeRemaining, setNewTimeRemaining] = React.useState(0);
  const [authModalVisible, setAuthModalVisible] =
    React.useState<boolean>(false);
  const [changeNameVisible, setChangeNameVisible] =
    React.useState<boolean>(false);
  const [confirmationModalVisible, setConfirmationModalVisible] =
    React.useState<boolean>(false);
  const [confirmationModalVisible2, setConfirmationModalVisible2] =
    React.useState<boolean>(false);
  const navigate = useNavigate();
  const [secondsBeetweenRounds, setSecondsBeetweenRounds] = useState(5);
  // const [maxPlayers, setMaxPlayers] = React.useState<number>(0);
  const [room, setRoom] = React.useState<Room>();
  const [rooms, setRooms] = React.useState<Room[]>();
  const contextLanguage = useSelector((state: any) => state.language);
  const texts =
    languageTexts[contextLanguage.Language as LanguageNames].drawGuessRoomTexts;
  const {Logout} = useContext(AuthContext);
  const stateUser = useSelector((state: any) => state.user);

  const [currentWidth, setCurrentWidth] = useState('85vw');
  const [playerGuessed, setPlayerGuessed] = React.useState<boolean>(false);
  // const playerGuessed = useRef<boolean>(false)
  const {updateUser} = useContext(AuthContext);

  const updatePlayers = useCallback(
    (newPlayers: Player[]) => {
      if (roundNumberRef.current !== 0) {
        setPlayersForEndgame(players);
      }

      setPlayers(newPlayers);

      const guessed = newPlayers.some((each: Player) => {
        return each.Guessed;
      });
      const myPlayer = newPlayers.find((player: Player) => {
        if (stateUser.user && stateUser.user.name === player.Name) return true;
        else return stateUser.id === player.Name;
      });
      if (myPlayer?.Guessed) {
        setPlayerGuessed(true);
      }

      if (guessed && !firstGuess) {
        setFirstGuess(true);
      }

      setSomeoneGuessed(guessed);
      // updateUser();
    },
    [firstGuess, players, stateUser.id, stateUser.user],
  );

  const messageReceiver = useRef(new MessageReceiver()).current;

  useEffect(() => {
    return messageReceiver.onPlayersUpdated(updatePlayers);
  }, [updatePlayers]);

  useEffect(() => {
    context.socket?.emit('request-game-status');
  }, [context.socket]);

  useEffect(() => {
    if (context.socket) {
      // Updates player data (points, gamesPlayed, gamesWon, name, etc)
      context.socket.on('players-update', newPlayers => {
        messageReceiver.receivePlayersUpdate(newPlayers);
      });

      context.socket.on('reset-game', () => {
        setDrawData('');
        setIsDrawing(false);
        timeRemainingRef.current = 0;
        roundNumberRef.current = 0;
        setClueAmount(0);
        setWordToGuess('');
        setTotalRounds(0);
        setFirstGuess(false);
        canvasRef.current?.resetRound();
      });

      context.socket.on('game-starting', update => {
        roundNumberRef.current = 1;
        setIntermission(true);
        setFirstGuess(false);
        setSecondsBeetweenRounds(update.SecondsBeetweenRounds);
        startingGame();
      });

      context.socket.on('game-update', update => {
        if (update.timeRemaining <= 5 && gameAudio) {
          playRoundTickAudio();
        }
        roundNumberRef.current = update.roundNumber;
        if (
          (canvasRef.current && canvasRef.current.getCanvasTime() === 0) ||
          update.timeRemaining > timeRemainingRef.current
        ) {
          timeRemainingRef.current = update.timeRemaining;
        }
        gameEnd.current = false;
        setIntermission(prev => false);
        setIsDrawing(prev => update.isDrawing);
        setWordToGuess(prev => update.drawWord);
        setTotalRounds(prev => update.totalRounds);
        setClueAmount(prev => update.clueAmount);
      });

      context.socket.on('word-guessed', word => {
        setWordToGuess(word);
      });

      // context.socket.emit('request-game-status');
      context.socket.on('unauthorized', () => {
        navigate('/');
      });

      context.socket.on('first-guess', data => {
        updateTimer(data.timeRemaining);
      });

      context.socket.on('clear-drawing', () => {
        setDrawData('');
        canvasRef.current?.clearDrawing();
      });

      context.socket.on('round-end', data => {
        setFirstGuess(false);
        setIsDrawing(false);
        setNewTimeRemaining(0);
        finishingRound();
        setIntermission(true);
        setWordToGuess(data.Word);
        canvasRef.current?.resetRound();
      });

      context.socket.on('round-start', () => {
        setPlayerGuessed(false);
        if (gameAudio) {
          playRoundStartAudio();
        }
        //startingRound();
      });

      context.socket.on('game-end', podium => {
        setIntermission(false);
        gameEnd.current = true;
        setPodium(podium);
        setPlayersForEndgame(podium);
        timeRemainingRef.current = 15;
        updateUser();
      });

      context.socket.on('reaction', reaction => {
        onReaction(reaction as keyof typeof emojiMap);
        const roomId = window.location.hash.split('/').at(-1);
      });

      context.socket.on('kicked', () => {
        navigate('/');
      });

      context.socket.on('current-state', (state: GameRoomState) => {
        // console.log('current-state', state);
        setClueAmount(state.clueAmount);
        roundNumberRef.current = state.roundNumber;
        setTotalRounds(state.totalRounds);
        setWordToGuess(state.drawWord);
        timeRemainingRef.current = state.maxTime;
        setIntermission(state.intermission);
        if (state.paths !== undefined && state.paths.length > 0) {
          const canvasSize = canvasRef.current?.getCanvasSize();
          const scaledPaths = state.paths.map((each: any) => ({
            ...each,
            paths: each.paths.map((path: any) => ({
              x: path.x * (canvasSize?.width || 1),
              y: path.y * (canvasSize?.height || 1),
            })),
          }));
          canvasRef.current?.loadPaths(scaledPaths);
        }
        if (state.isEndGame) {
          setPodium(state.playersForEndGame);
          setPlayersForEndgame(state.playersForEndGame);
          gameEnd.current = true;
        }
        setNewTimeRemaining(state.timeRemaining);
      });

      context.socket.on('new-player-joins', () => {
        if (context.socket) {
          context.socket.emit('export-room-state', {
            paths: canvasRef.current?.getPaths(),
            roundNumber: roundNumberRef.current,
            timeRemaining: canvasRef.current?.getCanvasTime(),
            maxTime: timeRemainingRef.current,
            totalRounds: totalRounds,
            clueAmount: clueAmount,
            podium: podium,
            playersForEndGame: playersForEndGame,
            isEndGame: gameEnd.current,
          });
        }
      });

      context.socket.on('room-info', (rooms: Room[]) => {
        // console.log('Rooms: ', rooms);
        setRooms(rooms);
      });

      return () => {
        context.socket?.off('new-name');
        context.socket?.off('draw-update');
        context.socket?.off('game-update');
        context.socket?.off('players-update');
        context.socket?.off('unauthorized');
        context.socket?.off('round-end');
        context.socket?.off('game-end');
        context.socket?.off('game-starting');
        context.socket?.off('round-start');
        context.socket?.off('kicked');
        context.socket?.off('new-player-joins');
        context.socket?.off('current-state');
        context.socket?.off('word-guessed');
        context.socket?.off('room-info');
        context.socket?.off('clear-drawing');
      };
    }
  }, [context, isDrawing, navigate, firstGuess]);

  useEffect(() => {
    const roomId = localStorage.getItem('roomId');
    if (rooms) {
      setRoom(rooms!.find((r: Room) => r.Id === roomId));
    }
  }, [rooms]);

  useEffect(() => {
    if (!context.isConnected) {
      console.log('You have been disconnected!');
      navigate(-1);
    }
  }, [context.isConnected]);

  useEffect(() => {
    if (window.innerWidth > 1400) {
      setCurrentWidth(`${-window.innerWidth / 45 + 133}vw`);
    } else {
      setCurrentWidth(`${-0.052 * window.innerWidth + 38.2}vw`);
    }
  }, [window.innerWidth]);

  const startingGame = () => {
    timeRemainingRef.current = 3;
  };

  const finishingRound = () => {
    timeRemainingRef.current = secondsBeetweenRounds;
  };

  const updateTimer = (time: number) => {
    setNewTimeRemaining(time);
  };

  useEffect(() => {
    const callback = () => {
      context.socket?.emit('leave-game');
    };
    setGameAudio(stateUser.user?.gameAudio);

    window.onpopstate = callback;
  }, []);

  const changeName = (name: string) => {
    sendNewName(name);
    setChangeNameVisible(false);
  };

  useEffect(() => {
    if (canvasContainer) {
      // playerListContainer!.style.height = String(
      //   canvasContainer.offsetHeight + 'px',
      // );
      // chatContainer!.style.height = String(canvasContainer.offsetHeight + 'px');
      emojiReactionContainer!.style.height = isDrawing
        ? String(canvasContainer.offsetHeight - 150 + 'px')
        : String(canvasContainer.offsetHeight - 65 + 'px');
    }
  }, [resize, isDrawing]);

  useEffect(() => {
    if (canvasContainer) {
      // playerListContainer!.style.height = String(
      //   canvasContainer.offsetHeight + 'px',
      // );
      // chatContainer!.style.height = String(canvasContainer.offsetHeight + 'px');
      emojiReactionContainer!.style.height = isDrawing
        ? String(canvasContainer.offsetHeight - 150 + 'px')
        : String(canvasContainer.offsetHeight - 65 + 'px');
    }
  }, [canvasContainer]);

  useEffect(() => {
    if (firstGuess && gameAudio) {
      playFirstGuessedAudio();
    }
  }, [firstGuess]);

  const sendMessage = (message: String) => {
    const messageTrimmedIsNotEmpty = message.trim() !== '';
    if (context.socket && messageTrimmedIsNotEmpty) {
      context.socket.emit('room-message', message);
    }
  };

  const onDrawingChange = (drawingData: string) => {
    if (!isDrawing) {
      return;
    }

    if (context.socket) {
      context.socket.emit('draw-update', drawingData);
    }
  };

  const onPlayerLeave = () => {
    if (context.socket) {
      context.socket.emit('leave-game');
      navigate('/');
    }
  };

  const onGiveTurn = () => {
    if (context.socket) {
      context.socket.emit('give-up-turn');
    }
  };

  const onGiveClue = () => {
    if (context.socket) {
      context.socket.emit('add-clue');
    }
  };

  const onClear = () => {
    if (context.socket) {
      context.socket.emit('clear-drawing');
    }
  };

  const onReaction = (reaction: keyof typeof emojiMap) => {
    emojiReactionRef.current
      .find(emoji => {
        return reaction === emoji?.getEmojiName();
      })
      ?.animate();
  };

  const onReactionSelect = (reaction: keyof typeof emojiMap) => {
    if (context.socket) {
      context.socket.emit('reaction', reaction);
    }
  };

  const onReportDraw = () => {
    if (context.socket) {
      context.socket.emit('report-draw');
    }
  };

  const rejoinRoom = () => {
    const roomId = localStorage.getItem('roomId');
    if (context.socket) {
      context.socket.emit(
        'update-name',
        stateUser.user?.name || stateUser.id || texts.guest,
      );
      context.socket.emit('join-room', roomId);
    }
  };

  useEffect(() => {
    if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
      rejoinRoom();
    }
  }, [performance.navigation.type]);

  const onBeforeUnload = (e: any) => {
    if (e) {
      e.preventDefault();
      e.cancelBubble = true;
      e.returnValue = texts.closeConfirmation;
    }
    return texts.closeConfirmation;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  return (
    <>
      {gameEnd.current && (
        <Fireworks
          options={{
            rocketsPoint: {
              min: 0,
              max: 100,
            },
          }}
          style={{
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            position: 'fixed',
            background: 'transparent',
            pointerEvents: 'none',
            zIndex: 10,
          }}
        />
      )}
      <Header
        game="drawAndGuess"
        onPlayerLeave={onPlayerLeave}
        onChangeName={() => setChangeNameVisible(true)}
        onLogin={() => setConfirmationModalVisible(true)}
        onLogoutF={() => setConfirmationModalVisible2(true)}
      />
      <div
        className="App_WelcomeContainer"
        style={{
          userSelect: 'none',
          width: 'calc(100vw - 100px)',
          height: window.innerHeight > 900 ? '85vh' : '80vh',
        }}>
        <div className="App__DrawAndGuessContainer">
          <Players
            players={
              gameEnd.current && playersForEndGame.length > 0
                ? playersForEndGame
                : players
            }
            onPlayerLeave={onPlayerLeave}
            maxPlayers={room?.MaxPlayers}
            isBrokenPhoneGame={false}
          />
          <div className="Room__CanvasContainer">
            <Canvas
              room={room}
              playerDrawingName={players.find(player => player.Drawing)?.Name}
              ref={canvasRef}
              roundNumber={roundNumberRef.current}
              drawData={drawData}
              onDrawChange={onDrawingChange}
              isDrawing={isDrawing || false}
              wordToGuess={wordToGuess}
              timeRemaining={timeRemainingRef.current}
              totalRounds={totalRounds}
              onGiveTurn={onGiveTurn}
              onGiveClue={onGiveClue}
              intermission={intermission}
              clueAmount={clueAmount}
              gameEnd={gameEnd.current}
              playerGuessed={playerGuessed}
              gameEndModalComponent={
                <GameEndModal visible={gameEnd.current} players={podium} />
              }
              onClear={onClear}
              someoneGuessed={someoneGuessed}
              maxTime={timeRemainingRef.current}
              newTime={newTimeRemaining}
              gameAudio={gameAudio!}
              setGameAudio={setGameAudio}
              onReport={onReportDraw}
              players={players.length}
              ></Canvas>
            <div
              className={
                'Canvas__ReactionContainer ' +
                (isDrawing ? 'Canvas__Disable' : '')
              }>
              <Collapse
                in={collapseEmojis}
                orientation={'horizontal'}
                collapsedSize={'2rem'}
                style={
                  roundNumberRef.current === 0 && totalRounds === 0
                    ? {display: 'none'}
                    : {}
                }>
                {Object.entries(emojiMap).map((emoji, i) => (
                  <EmojiReaction
                    ref={el => (emojiReactionRef.current[i] = el)}
                    index={i}
                    key={emoji[0]}
                    emoji={emoji}
                    animateFunction={onReactionSelect}
                    setCollapseEmojis={setCollapseEmojis}
                    isDrawing={isDrawing || false}
                    globalAnimation={globalAnimation}
                    setGlobalAnimation={setGlobalAnimation}
                  />
                ))}
              </Collapse>
            </div>
            {/* <GameEndModal visible={true} players={mockedPlayers} /> */}
          </div>
          <ChatDirectMessage
            sendMessage={sendMessage}
            disabled={isDrawing === true}
            style={'DrawAndGuess'}
          />
        </div>
        <AuthModal
          visible={authModalVisible}
          onClose={() => {
            setAuthModalVisible(false);
          }}
          auxFunction={() => {
            onPlayerLeave();
          }}
          obligatory={false}
        />

        {/* {playerDrawing &&
        <ReportModal
          player={playerDrawing}
          ref={reportModalRef}
        />} */}

        <ChangeNameModal
          isChangeName={true}
          visible={changeNameVisible}
          onClose={() => {
            setChangeNameVisible(false);
          }}
          onSubmit={changeName}
          obligatory={false}
        />
        <ConfirmationModal
          visible={confirmationModalVisible}
          onAccept={() => {
            setAuthModalVisible(true);
            setConfirmationModalVisible(false);
          }}
          onClose={() => {
            setConfirmationModalVisible(false);
          }}
          acceptText={texts.confirmationModalAcceptText}
          cancelText={texts.confirmationModalCancelText}
          title={texts.confirmationModalTitle}
          message={texts.confrmationModalText}
        />
        <ConfirmationModal
          visible={confirmationModalVisible2}
          onAccept={() => {
            setConfirmationModalVisible2(false);
            onPlayerLeave();
            Logout();
          }}
          onClose={() => {
            setConfirmationModalVisible2(false);
          }}
          acceptText={texts.confirmationModalAcceptText2}
          cancelText={texts.confirmationModalCancelText}
          title={texts.confirmationModalTitle}
          message={texts.confrmationModalText2}
        />
      </div>

      <SocialMedia />
    </>
  );
}
