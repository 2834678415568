import {
  AuthTexts,
  BrokenPhoneRoomTexts,
  ChangeNameTexts,
  CreateRoomTexts,
  DrawGuessRoomTexts,
  EnterPhraseTexts,
  GameEndTexts,
  GameSelectTexts,
  HeaderTexts,
  HomeTexts,
  PlayerDrawingModalTexts,
  ProfileTexts,
  ReportModalTexts,
  SecondTabAlertTexts,
} from '../languageTexts';

export const englishHomeTexts: HomeTexts = {
  chatTitle: 'LOBBY',
  roomsTitle: 'ROOMS',
  sendMsg: 'Send',
  game1Title: 'DRAW AND GUESS',
  game2Title: 'BROKEN PHONE',
  errorTitle: 'Something went wrong',
  createRoomError:
    'There was an error creating the room, please try again later',
  createTitle: 'Create a room',
  chatPlaceholder: 'Send Message...',
  chatPlaceholderWhileDrawing: "Can't send messages while drawing",
};

export const englishGameSelectTexts: GameSelectTexts = {
  drawGuessSubtitle: 'Draw and guess random words to score points!',
  brokenPhoneSubtitle:
    'Draw phrases and guess what those crazy drawings represent!',
  playText: 'Play',
};

export const englishDrawGuessTexts: DrawGuessRoomTexts = {
  currentRound: 'Round',
  guess: 'Guess',
  skip: 'SKIP',
  voteForKick: 'Vote for kick',
  report: 'Report',
  reported: 'Reported',
  waitingForPlayers: 'WAITING FOR PLAYERS...',
  gameStarting: 'GAME STARTING...',
  sureToLeave: 'Are you sure you want to leave the room?',
  leaveRoom: 'Leave room',
  cancel: 'Cancel',
  guessed: 'Guessed',
  playersTitle: 'PLAYERS',
  chatTitle: 'CHAT ROOM',
  kick: 'Kick',
  clue: 'CLUE',
  waitingForNextRound: 'WAITING FOR NEXT ROUND...',
  confirmationModalAcceptText: 'Go to Login',
  confirmationModalAcceptText2: 'Logout',
  confirmationModalCancelText: 'Cancel',
  confirmationModalTitle: 'Are you sure?',
  confrmationModalText: 'If you log in now, you will exit the current game',
  confrmationModalText2: 'If you logout now, you will exit the current game',
  gameStartingDescription: 'The game will start once a player joins',
  seeProfile: 'See profile',
  guest: 'Guest',
  closeConfirmation: 'Are you sure you want to close the game?',
};

export const englishChangeNameTexts: ChangeNameTexts = {
  titleChangeName: 'CHANGE GUEST NAME',
  titleEnterName: 'ENTER GUEST NAME',
  doneButtonText: 'FINISH',
  placeHolder: 'New name',
  cancelButtonText: 'CANCEL',
};

export const englishAuthTexts: AuthTexts = {
  titleRegister: 'REGISTER',
  titleLogin: 'LOGIN',
  name: 'Name',
  confirmPassword: 'Confirm password',
  language: 'Language',
  email: 'Email',
  password: 'Password',
  continueAs: 'or continue as ',
  guest: 'Guest',
  buttonRegister: 'Register',
  buttonLogin: 'Login',
  avatar: 'Upload avatar',
  passwordDescription:
    'Password must be at least 6 characters long, contain at least a letter, and a number',
  credentialsError: 'The username or password is incorrect',
  passwordNotMatch: 'Passwords do not match',
  usernameRequired: 'Username is required',
  usernameLength: 'Username is too long',
  passwordRequired: 'Password is required',
  passwordFormat:
    'Password must be at least 6 characters long, contain at least a letter, and a number',
  emailRequired: 'Email is required',
  emailFormat: 'Email is invalid',
  emailLength: 'Email is too long',
  userInUse: 'Username and/or email is already in use',
  invalidToken: 'Session has expired',
};

export const englishCreateRoomTexts: CreateRoomTexts = {
  newRoom: 'Room name',
  room: "'s room",
  roomNameError: 'The name of the room is already in use',
  title: 'CREATE ROOM',
  roomName: 'Room name',
  numberOfRounds: 'Number of Rounds',
  secondsPerRound: 'Seconds per Round',
  secondsBetweenRounds: 'Seconds between Rounds',
  maxPlayers: 'Max Players',
  secondsAfterFirstGuess: 'Seconds after first guess',
  language: 'Language',
  wordList: 'Word List',
  cancelButtonText: 'CANCEL',
  createButtonText: 'CREATE',
  secondsForInicialPhrase: 'Seconds for inicial phrase',
  secondsForDrawing: 'Seconds for drawing',
  secondsForGuessing: 'Seconds for guessing',
  gameLength: 'Game Length',
};

export const englishEnterPhraseTexts: EnterPhraseTexts = {
  writeYourPhrase: 'Write a sentence',
  waitingForPlayers: 'Waiting for players...',
  edit: 'Edit',
  done: 'Done',
  random: 'Random',
  round: 'Round'
}

export const englishProfileTexts: ProfileTexts = {
  titleFriend: 'PROFILE',
  title: 'MY PROFILE',
  gamesWon: 'Games won',
  playedGames: 'Games played',
  guessedWords: 'Words guessed',
  language: 'Language',
  brokenPhoneGamesPlayed: 'Broken phone games played'
};

export const englishHeaderTexts: HeaderTexts = {
  guest: 'Guest User',
  authOpcions: 'Log in / Sign Up',
  changeName: 'Change Name',
  profile: 'Profile',
  logout: 'Logout',
  disconnectTooltip: 'Lost connection to server, try again later...',
};

export const englishGameEndTexts: GameEndTexts = {
  firstPlace: '1st Place',
  secondPlace: '2nd Place',
  thirdPlace: '3rd Place',
};

export const englishReportModalTexts: ReportModalTexts = {
  reportReasons: [
    'Written abuse or use of inappropriate language',
    'Lewd or inappropriate drawings',
    'Inappropriate profile avatar or name',
    'Cheating or abusing glitches',
    'Annoying or malicious player',
    'Racism, sexism, homophobia, discrimination, etc',
    'Other',
  ],
  otherReason: 'Other reason',
  cancel: 'Cancel',
  report: 'Report',
  reportPlayer: 'REPORT PLAYER',
  selectReason: 'Select reason',
};

export const englishPlayerDrawingModalTexts: PlayerDrawingModalTexts = {
  description: 'is Drawing ...',
  yourTurnToDraw: 'Your turn to draw!',
};

export const englishSecondTabAlertTexts: SecondTabAlertTexts = {
  title: 'Oops!',
  description: 'You can only open the game once at the same time.',
};

export const englishBrokenPhoneRoomTexts: BrokenPhoneRoomTexts = {
  cancel: 'CANCEL ',
  edit: 'EDIT ',
  leaveRoom: 'EXIT',
  ready: 'READY ',
  send: 'SEND ',
  sendMessage: 'Send message',
  startGame: 'START GAME ',
  waitingForPlayers: 'Waiting for more players',
  previous: 'PREVIOUS ',
  next: 'NEXT ',
  returnToLobby: 'Return to Lobby',
  newGame: "New game",
  round: "Round",
  lineEnded: "This line has ended, click to download",
  history: "LAST GAME",
}
