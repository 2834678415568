import {Box, Button, Modal, TextField, Typography} from '@mui/material';
import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import logo from '../../assets/confused_pencil.png';
import {AuthContext} from '../../context/AuthContext';
import {LanguageNames, languageTexts} from '../../utils/languageTexts';
import './ChangeNameModal.scss';

interface ChangeNameModalProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (name: string) => void;
  obligatory: boolean;
  isChangeName: boolean;
}

export const ChangeNameModal = ({
  visible,
  onClose,
  onSubmit,
  obligatory,
  isChangeName,
}: ChangeNameModalProps) => {
  const [name, setName] = React.useState('');

  const contextLanguage = useSelector((state: any) => state.language);
  const texts =
    languageTexts[contextLanguage.Language as LanguageNames].changeNameTexts;

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    height: 200,
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 5,
    p: 0,
    overflow: 'hidden',
  };

  return (
    <Modal open={visible} onClose={isChangeName ? onClose : () => {}}>
      <Box sx={modalStyle}>
        <div className="ChangeName__Title">
          <p>{!isChangeName ? texts.titleEnterName : texts.titleChangeName}</p>
        </div>
        <div className="ChangeName_BodyContainer">
          <TextField
            inputRef={input => input && input.focus()  }
            onKeyDownCapture={(e) => {if(e.code === "Enter" && name.length > 0){onSubmit(name)}}}
            variant="outlined"
            fullWidth
            label={texts.placeHolder}
            margin="dense"
            onChange={event => {
              if (event.target.value.length <= 13) {
                setName(event.target.value);
              }
            }}
            value={name}
            style={{width: '350px'}}
            InputProps={{
              style: {borderRadius: '20px', height: '50px'},
            }}
            multiline={false}
          />
        </div>
        <div className={'ChangeName__ButtonContainer'}>
          {isChangeName && (
            <div
              className="Canvas__ButtonContainerWarning"
              onClick={onClose}
              style={{width: '25%'}}>
              {texts.cancelButtonText}
            </div>
          )}
          <div
            className={
              name === ''
                ? 'Canvas__ButtonContainerWarningDisabled'
                : 'Canvas__ButtonContainerWarning'
            }
            onClick={() => onSubmit(name)}
            style={{width: '25%'}}>
            {texts.doneButtonText}
          </div>
        </div>
      </Box>
    </Modal>
  );
};
