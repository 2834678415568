import {createGIF} from "gifshot";
import {useState} from "react";

export interface DrawElement {
    type: "phrase" | "draw";
    //if phrase, content: string, else draw, content: 
    content: any;
    name: string;
    avatar: string;
    index: number
}

export const useCreateGif = () => {

    const phraseContainer = require("../assets/frase.png");
    const drawContainer = require("../assets/dibujo.png");
    const [isLoading, setIsLoading] = useState(false);

    const toDataURL = (url: string, callback: (_: string) => void) => {
        let xhr = new XMLHttpRequest();
        xhr.onload = function() {
            let reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result as string);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    const getLines = (ctx: any, text: string, maxWidth: number) => {
        const words = text.split(" ");
        const lines = [];
        let currentLine = "";

        words.forEach(word => {
            const width = ctx.measureText(currentLine + " " + word).width;
            if (width < maxWidth) {
                currentLine += " " + word;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        });
        lines.push(currentLine);
        return lines;
    }

    const generateStateCanvas = (ctx: any, actual: number, total: number) => {
        const backgroundWidth = 750;
        const stateWidth = backgroundWidth / 4;
        let stateXPosition = 10;
        const padding = 10;
        for ( let i = 0 ; i < total ; i++ ) {
            ctx.fillStyle = i === actual ? "#FFFFFFBF" : "#FFFFFF50";
            ctx.fillRect(stateXPosition + padding, 505, stateWidth - 2 * padding, 10);
            stateXPosition += stateWidth;
        }
    }

    const generateDrawCanvas = async (element: DrawElement, actual: number, total: number) => {
        const widthBox = 720;

        const canvas = document.createElement('canvas');
        canvas.width = 770;
        canvas.height = 525;
        const ctx = canvas.getContext("2d");
        if (!ctx) {
            return;
        }
        const containerImg = document.createElement('img');
        containerImg.src = drawContainer;

        await new Promise(resolve => {
            containerImg.onload = async () => {
                ctx.drawImage(containerImg, 0, 0);
                resolve("");
            };
        })

        const draw = document.createElement('img');

        draw.src = element.content;
        await new Promise( resolve => {
            draw.onload = function() {
                ctx.drawImage(draw, 45, 75, 675, 360);
                return resolve("");
            }
        })

        const avatar = document.createElement('img');
        avatar.src = await new Promise(resolve => {
            toDataURL(element.avatar, (dataUrl) => {
                resolve(dataUrl)
            })
        });
        await new Promise( resolve => {
            avatar.onload = function() {
                ctx.drawImage(avatar, 760 - 120, 485 - 120, 120, 120);
                return resolve("");
            }
        })
        const startWidthTextNick = 25;
        const limitName = containerImg.naturalHeight - 35
        ctx.font = "bold 25px comic-sans, sans-serif";
        const endTextBox = 102;
        const nick = element.name;
        const xTextBox = startWidthTextNick + widthBox - ctx.measureText(nick).width - endTextBox
        ctx.lineWidth = 2;
        ctx.fillStyle = "#0FF077";
        ctx.strokeStyle = 'black';

        ctx.strokeText(nick, xTextBox, limitName);
        ctx.fillText(nick , xTextBox, limitName);

        generateStateCanvas(ctx, actual, total);

        return canvas;
    }

    const generatePhraseCanvas = async (element: DrawElement, actual: number, total: number) => {

        const heightBox = 250;
        const startTextBox = 135;
        const heightText = 45;
        const widthBox = 530;
        const startWidthTextBox = 110;

        const canvas = document.createElement('canvas');
        canvas.width = 770;
        canvas.height = 525;
        const ctx = canvas.getContext("2d");

        if (!ctx) {
            return ;
        }

        const backgroundImage = document.createElement('img');
        backgroundImage.src = phraseContainer;
        await new Promise(resolve => {
            backgroundImage.onload = async () => {
                ctx.drawImage(backgroundImage, 0, 0);
                resolve("");
            };
        });

        const avatar = document.createElement('img');
        avatar.src = await new Promise(resolve => {
            toDataURL(element.avatar, (dataUrl) => {
                resolve(dataUrl)
            })
        });
        await new Promise( resolve => {
            avatar.onload = function() {
                ctx.drawImage(avatar, 760 - 120, 485 - 120, 120, 120);
                return resolve("");
            }
        })

        ctx.font = "bold 32px comic-sans, sans-serif";
        ctx.fillStyle = "#061077"
        const text = element.content;
        const lines = getLines(ctx, text, 530);
        const y = (heightBox - lines.length * heightText) / 2;
        lines.forEach((line, index) => {
            const w = ctx.measureText(line).width;
            const wp = startWidthTextBox +(widthBox - w) / 2;
            ctx.fillText(line , wp, startTextBox + y + heightText * (index + 1));
        });

        const startWidthTextNick = 25;
        const limitName = backgroundImage.naturalHeight - 35
        ctx.font = "bold 25px comic-sans, sans-serif";
        const nick = element.name;
        const endTextBox = 102;
        const xTextBox = startWidthTextNick + 720 - ctx.measureText(nick).width - endTextBox
        ctx.lineWidth = 3;
        ctx.fillStyle = "#0FF077";
        ctx.strokeStyle = 'black';

        ctx.strokeText(nick, xTextBox, limitName);
        ctx.fillText(nick , xTextBox, limitName);
        generateStateCanvas(ctx, actual, total);

        return canvas;
    }

    const generateGif = async (elements: DrawElement[]) => {
        const imgs = [];
        setIsLoading(true);
        for ( let i = 0; i < elements.length; i++ ) {
            const element = elements[i];
            if (element.type === "phrase") {
                try {
                    const canvas = await generatePhraseCanvas(element, i, elements.length);
                    if (canvas) {
                        imgs.push(canvas.toDataURL("image/png;base64"));
                    }
                }catch (e) {
                    console.log(e)
                }
            } else {
                try {
                    const canvas = await generateDrawCanvas(element, i, elements.length);
                    if (canvas) {
                        imgs.push(canvas.toDataURL("image/png;base64"));
                    }
                }catch (e) {
                    console.log(e)
                }
            }
        }
        downloadGif(imgs);
    }

    const downloadGif = (images: any) => {
        const options = {
            images: images,
            gifWidth: 770,
            gifHeight: 525,
            numWorkers: 5,
            frameDuration: 1,
            sampleInterval: 10,
            interval: 2.5,
            fontWeight: 'normal',
            fontSize: '16px',
            minFontSize: '10px',
            resizeFont: false,
            fontFamily: 'sans-serif',
            fontColor: '#ffffff',
            textAlign: 'center',
            textBaseline: 'bottom'
        };

        createGIF(options, (obj: any) => {
            if (!obj.error) {
                const link = document.createElement('a');
                link.download = 'sample.gif';
                link.href = obj.image;
                link.click();
                setIsLoading(false);
            }
        });
    }

    return {
        generateGif,
        isLoading
    }

}
